/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { take, words } from 'lodash'
import React from 'react'

import { colors } from '../pages/home/components/tile-options'

function AbbreviationIcon ({ className, name = '', variant = 'default' }) {
  const shape =
    variant === 'rounded'
      ? 'rounded-full h-8 w-8 mr-4 text-xs opacity-80'
      : 'aspect-square w-6 rounded text-white'
  return (
    <span
      className={cx(
        'flex items-center justify-center font-medium',
        shape,
        className
      )}
      style={{ backgroundColor: getColorFromName(name) }}
      name={name}
      aria-hidden='true'
    >
      {getInitials(name)}
    </span>
  )
}

function getInitials (name) {
  const parts = words(name)
  if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase()
  }
  const initialsArr = parts.map(part => part.charAt(0))
  return take(initialsArr, 2).join('').toUpperCase()
}

function getColorFromName (name) {
  if (!name) return 'transparent'
  // credit for this idea goes to https://stackoverflow.com/a/7493982
  const charCodeSum = name.split('').reduce((sum, char) => {
    return sum + char.charCodeAt(0)
  }, 0)
  return colors[charCodeSum % colors.length]
}

export default React.memo(AbbreviationIcon)
