/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { cloneDeep } from 'lodash'
import React from 'react'
import { useOutletContext } from 'react-router'

import { ModalPage } from '../../components/modal-page'
import { useTenantFeaturesContext } from '../../components/tenant-features-context'
import * as ReminderSettings from '../../flowbot/components/reminder-settings'
import { useAlerts } from '../../ui/alerts'
import ConfigBox from '../../ui/config-box'

export default () => {
  const {
    saveWorkflowSettings,
    updateViewerSetting,
    updateWorkflowAppSettings,
    workflow,
    viewerSetting,
    workflowAppSettings
  } = useOutletContext()
  return (
    <ModalPage
      title={i18n._('pagesbuilder.workflow.settings')}
      width='450px'
      side
    >
      <WorkflowSettings
        saveWorkflowSettings={saveWorkflowSettings}
        updateViewerSetting={updateViewerSetting}
        updateWorkflowAppSettings={updateWorkflowAppSettings}
        workflow={workflow}
        viewerSetting={viewerSetting}
        workflowAppSettings={workflowAppSettings}
      />
    </ModalPage>
  )
}

const WorkflowSettings = ({
  saveWorkflowSettings,
  updateViewerSetting,
  updateWorkflowAppSettings,
  workflow,
  viewerSetting,
  workflowAppSettings
}) => {
  const tenantFeatures = useTenantFeaturesContext()
  const tenantVersionSetting = tenantFeatures?.versions ?? false
  const alerts = useAlerts()
  const workflowSettings = {
    defaultFormToViewOnly: workflow.defaultFormToViewOnly,
    reminderSettings: workflow.reminderSettings,
    disableSendback: workflow.disableSendback,
    disableDeny: workflow.disableDeny,
    disableDocumentHistory: workflow.disableDocumentHistory,
    saveEmailsInDocumentHistory: workflow.saveEmailsInDocumentHistory
  }

  return (
    <div className='mx-auto my-7 w-96 text-sm min-[501px]:max-w-[90%]'>
      <ConfigBox
        borderless
        configKey='viewOnlySetting'
        description={
          <div style={{ width: 206 }}>
            <Trans id='pagesbuilder.workflow.setting.define' />
          </div>
        }
        enabled={!workflow.defaultFormToViewOnly}
        label={i18n._('pagesbuilder.workflow.allow.reviewers')}
        save={() => () => {
          return saveWorkflowSettings({
            ...workflowSettings,
            defaultFormToViewOnly: !workflow.defaultFormToViewOnly
          })
            .then(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.saved'),
                'success'
              )
            )
            .catch(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.error'),
                'error'
              )
            )
        }}
      />
      <ConfigBox
        borderless
        configKey='statusOnSubmission'
        enabled={viewerSetting}
        label={i18n._('pagesbuilder.workflow.show.status')}
        save={() => () => {
          updateViewerSetting(!viewerSetting)
            .then(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.saved'),
                'success'
              )
            )
            .catch(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.error'),
                'error'
              )
            )
        }}
      />
      <ReminderSettings.Config
        borderless
        description={
          <div>
            <Trans id='pagesbuilder.workflow.individual.steps' />
          </div>
        }
        updateValue={updater => {
          const myWorkflow = cloneDeep(workflow)
          updater(myWorkflow)
          return saveWorkflowSettings({
            ...workflowSettings,
            reminderSettings: myWorkflow.reminderSettings
          })
            .then(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.saved'),
                'success'
              )
            )
            .catch(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.error'),
                'error'
              )
            )
        }}
        value={workflow}
      />
      {tenantVersionSetting && (
        <ConfigBox
          borderless
          configKey='allowNewVersions'
          enabled={workflowAppSettings.allowNewVersions}
          label={i18n._('pagesbuilder.workflow.settings.allow.resubmission')}
          save={() => () => {
            updateWorkflowAppSettings({
              ...workflowAppSettings,
              allowNewVersions: !workflowAppSettings.allowNewVersions
            })
              .then(() =>
                alerts.type3(
                  i18n._('pagesbuilder.workflow.settings.saved'),
                  'success'
                )
              )
              .catch(() =>
                alerts.type3(
                  i18n._('pagesbuilder.workflow.settings.error'),
                  'error'
                )
              )
          }}
        />
      )}
      <ConfigBox
        borderless
        configKey='disableDenySetting'
        description={
          <div style={{ width: 206 }}>
            <Trans id='pagesbuilder.workflow.setting.disable.deny.help' />
          </div>
        }
        enabled={workflow.disableDeny}
        label={i18n._('pagesbuilder.workflow.allow.disable.deny.description')}
        save={() => () => {
          return saveWorkflowSettings({
            ...workflowSettings,
            disableDeny: !workflow.disableDeny
          })
            .then(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.saved'),
                'success'
              )
            )
            .catch(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.error'),
                'error'
              )
            )
        }}
      />
      <ConfigBox
        borderless
        configKey='disableSendbackSetting'
        description={
          <div style={{ width: 206 }}>
            <Trans id='pagesbuilder.workflow.setting.disable.sendback.help' />
          </div>
        }
        label={i18n._(
          'pagesbuilder.workflow.allow.disable.sendback.description'
        )}
        enabled={workflow.disableSendback}
        save={() => () => {
          return saveWorkflowSettings({
            ...workflowSettings,
            disableSendback: !workflow.disableSendback
          })
            .then(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.saved'),
                'success'
              )
            )
            .catch(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.error'),
                'error'
              )
            )
        }}
      />
      <ConfigBox
        borderless
        configKey='disableDocumentHistory'
        description={
          <div style={{ width: 206 }}>
            <Trans id='pagesbuilder.workflow.setting.disable.history.help' />
          </div>
        }
        label={i18n._(
          'pagesbuilder.workflow.setting.disable.history.description'
        )}
        enabled={workflow.disableDocumentHistory}
        save={() => () => {
          return saveWorkflowSettings({
            ...workflowSettings,
            disableDocumentHistory: !workflow.disableDocumentHistory
          })
            .then(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.saved'),
                'success'
              )
            )
            .catch(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.error'),
                'error'
              )
            )
        }}
      />
      <ConfigBox
        borderless
        configKey='disableDocumentHistoryNonAdmins'
        description={
          <div style={{ width: 206 }}>
            <Trans id='pagesbuilder.workflow.setting.disable.history.nonadmins.help' />
          </div>
        }
        label={i18n._(
          'pagesbuilder.workflow.setting.disable.history.nonadmins.description'
        )}
        enabled={workflowAppSettings.disableDocumentHistoryNonAdmins}
        save={() => () => {
          updateWorkflowAppSettings({
            ...workflowAppSettings,
            disableDocumentHistoryNonAdmins:
              !workflowAppSettings.disableDocumentHistoryNonAdmins
          })
            .then(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.saved'),
                'success'
              )
            )
            .catch(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.error'),
                'error'
              )
            )
        }}
      />
      <ConfigBox
        borderless
        configKey='saveEmailsInDocumentHistory'
        description={
          <div style={{ width: 206 }}>
            <Trans id='pagesbuilder.workflow.setting.save.emails.in.history.help' />
          </div>
        }
        label={i18n._(
          'pagesbuilder.workflow.setting.save.emails.in.history.description'
        )}
        enabled={workflow.saveEmailsInDocumentHistory}
        save={() => () => {
          return saveWorkflowSettings({
            ...workflowSettings,
            saveEmailsInDocumentHistory: !workflow.saveEmailsInDocumentHistory
          })
            .then(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.saved'),
                'success'
              )
            )
            .catch(() =>
              alerts.type3(
                i18n._('pagesbuilder.workflow.settings.error'),
                'error'
              )
            )
        }}
      />
    </div>
  )
}
