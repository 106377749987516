/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { get, isEqual, uniqWith } from 'lodash'
import React from 'react'
import { useParams } from 'react-router'

import CenterModal from '../../../components/modal-centered'
import Spinner from '../../../components/spinner'
import { gadgets } from '../../../formbot'
import * as Icons from '../../../icons'
import { Img, getIcon, useOptions } from '../../data-chooser'
import {
  collectGadgets,
  getRepeatableChildren
} from '../../engine/formbot/utils'
import { ReactComponent as ImgExternal } from '../../source-generic.svg.jsx'
import MillerColumns from './miller-columns'

const REPEATABLE_TYPES = ['Repeater', 'Table', 'Rules']
const INTEGRATION_TYPES = ['DataLookup', 'FormTypeahead', 'FormMultiselect']
const MAX_VISIBLE_COLUMNS = 3

export default function AssociationsChooser ({
  value,
  options,
  id,
  label,
  tileOptions,
  isProduct,
  onSelect,
  indexed,
  indexType,
  hasLinked
}) {
  const [showChooser, setShowChooser] = React.useState(false)
  const icon = getIcon(id, tileOptions, isProduct)
  return (
    <>
      <div className='flex items-center justify-between pb-2'>
        {id ? (
          <>
            <div className='flex items-center justify-between pr-4'>
              {icon}
              <div className='flex flex-col'>
                <span className='text-sm text-dark-gray-500'>
                  {value.product?.label && `${value.product?.label} - `}
                  {value.dataset?.title || value.dataset?.label}
                </span>
                <span className='text-sm'>{value?.gadget?.label}</span>
                <span className='text-sm'>{label}</span>
              </div>
            </div>
            <button
              className='kp-button-outline'
              disabled={indexed}
              onClick={() => {
                if (indexed) return
                if (!showChooser && hasLinked) {
                  const shouldShow = window.confirm(
                    i18n._('changing.data.will.remove.all.linked.gadgets')
                  )
                  if (!shouldShow) return
                }
                setShowChooser(a => !a)
              }}
            >
              <Trans id='change' />
            </button>
          </>
        ) : (
          <button
            className='kp-button-solid'
            onClick={() => setShowChooser(a => !a)}
          >
            <Trans id='choose' />
          </button>
        )}
      </div>
      <CenterModal
        dismiss={() => setShowChooser(false)}
        showing={showChooser}
        className='transform-none px-0 pb-0 pt-0'
      >
        <div className='w-full'>
          <MillerChooser
            value={value}
            hide={() => setShowChooser(false)}
            onSelect={(...args) => {
              setShowChooser(false)
              onSelect(...args)
            }}
            options={options}
            indexType={indexType}
          />
        </div>
      </CenterModal>
    </>
  )
}

const MillerChooser = ({ value, hide, onSelect, options, indexType }) => {
  const [filteredOptions, { loading }] = useOptions(options, indexType, null)
  const [selection, setSelection] = React.useState({
    product: null,
    dataset: null,
    gadget: null
  })
  const [activeColumns, setActiveColumns] = React.useState([0])
  const sources = filteredOptions?.[0]?.options

  React.useEffect(() => {
    if (!value?.source?.dataset) return

    const initialState = {
      product: null,
      dataset: null,
      gadget: null
    }

    if (value?.source?.product?.id) {
      const app = sources?.find(o => o.id === value.source.product.id)
      initialState.product = app
      initialState.dataset = app?.datasets?.find(
        o => o.id === value.source.dataset.id
      )
    } else {
      initialState.dataset = sources?.find(
        o => o.id === value.source.dataset.id
      )
    }

    if (value?.source?.gadget) {
      initialState.gadget = value.source.gadget
    }

    setSelection(initialState)

    const columnsToShow = [0]
    if (initialState.product) columnsToShow.push(1)
    if (initialState.dataset) columnsToShow.push(2)
    if (
      initialState.gadget?.subGadget ||
      ['Repeater', 'Table'].includes(initialState.gadget?.type)
    ) {
      columnsToShow.push(3)
    }
    setActiveColumns(columnsToShow)
  }, [value?.source, sources])

  const handleColumnChange = {
    product: val => {
      if (val?.type === 'app') {
        setSelection(prev => ({
          product: null,
          dataset: val,
          gadget: null
        }))
        setActiveColumns([0, 2])
      } else if (val?.type === 'product') {
        setSelection(prev => ({
          product: val,
          dataset: null,
          gadget: null
        }))
        setActiveColumns([0, 1])
      }
    },
    dataset: val => {
      setSelection(prev => ({
        ...prev,
        dataset: val,
        gadget: null
      }))
      setActiveColumns([0, 1, 2])
    },
    gadget: gadget => {
      setSelection(prev => ({
        ...prev,
        gadget: {
          ...gadget,
          subGadget: null
        }
      }))
      if (['Repeater', 'Table'].includes(gadget?.type)) {
        setActiveColumns(activeColumns => {
          return !activeColumns.includes(3)
            ? [...activeColumns, 3]
            : activeColumns
        })
      }
    },
    subGadget: subGadget => {
      setSelection(prev => ({
        ...prev,
        gadget: {
          ...prev.gadget,
          subGadget
        }
      }))
    }
  }

  const columns = [
    {
      title: i18n._({
        id: 'select.app.or.product',
        message: 'Select App/Product'
      }),
      values: sources,
      selected: selection.product || selection.dataset,
      loading,
      onChange: handleColumnChange.product,
      renderItem: value => {
        const Icon = value?.Icon
        return (
          <>
            {Icon ? <Icon mr={3} /> : <Img mr={3} as={ImgExternal} />}
            <span>{value?.label}</span>
          </>
        )
      }
    },
    {
      title: i18n._({ id: 'select.dataset', message: 'Select Dataset' }),
      values: selection.product?.datasets,
      selected: selection.dataset,
      onChange: handleColumnChange.dataset,
      renderItem: value => {
        const Icon = value?.Icon
        return (
          <>
            {Icon ? <Icon mr={3} /> : <Img mr={3} as={ImgExternal} />}
            <span>{value?.label}</span>
          </>
        )
      }
    },
    {
      title: i18n._({ id: 'select.gadget', message: 'Select Gadget' }),
      Component: GadgetChooserColumn,
      source: selection.dataset,
      selectedGadget: selection.gadget,
      onChange: handleColumnChange.gadget
    },
    {
      title: i18n._({ id: 'select.sub.gadget', message: 'Select Sub-Gadget' }),
      Component: GadgetChooserColumn,
      source: selection.dataset,
      selectedGadget: selection.gadget,
      isSubGadget: true,
      onChange: handleColumnChange.subGadget
    }
  ]

  const handleSubmit = () => {
    onSelect({
      type: 'Associations',
      details: {
        ...value,
        versionConfig: 'ANY_LINKED_VERSION',
        source: {
          product: selection.product,
          dataset: selection.dataset,
          gadget: selection.gadget && {
            formKey: selection.gadget.formKey,
            id: selection.gadget.id,
            label: selection.gadget.label,
            type: selection.gadget.type,
            childrenTemplate: selection.gadget.childrenTemplate,
            subGadget: selection.gadget.subGadget
          }
        }
      }
    })
  }

  return (
    <div className='flex w-[768px] animate-fade-in flex-col rounded bg-white shadow-xl'>
      <MillerColumns
        columns={columns}
        activeColumns={activeColumns}
        maxVisibleColumns={MAX_VISIBLE_COLUMNS}
        header={({ left, right }) => (
          <div className='flex w-full items-center justify-between border border-light-gray-300 bg-light-gray-100 px-4 py-2 text-sm uppercase text-medium-gray-500 dark:bg-light-gray-300'>
            <div>
              <button
                className='kp-button-transparent kp-button-icon kp-button-sm text-medium-gray-500'
                onClick={() => left.onClick()}
                disabled={left.disabled}
                aria-label={i18n._('Previous')}
              >
                <Icons.SelectDownArrow className='h-4 w-4 rotate-90 transform' />
              </button>
              <button
                className='kp-button-transparent kp-button-icon kp-button-sm text-medium-gray-500'
                onClick={() => right.onClick()}
                disabled={right.disabled}
                aria-label={i18n._('Next')}
              >
                <Icons.SelectDownArrow className='h-4 w-4 -rotate-90 transform' />
              </button>
            </div>
            <Trans id='sources' />
            <button
              className='kp-button-transparent kp-button-icon kp-button-sm text-medium-gray-500'
              onClick={hide}
              aria-label='close'
            >
              <Icons.Close width='12px' height='12px' />
            </button>
          </div>
        )}
      />
      <div className='flex items-center justify-end gap-2 border border-light-gray-300 bg-white px-4 py-2 uppercase text-medium-gray-500 dark:bg-light-gray-300'>
        <button
          className='kp-button-solid transition-all'
          disabled={!selection.dataset || !selection.gadget}
          onClick={handleSubmit}
        >
          <Trans id='continue' />
        </button>
      </div>
    </div>
  )
}

function GadgetChooserColumn ({
  title,
  source,
  selectedGadget,
  onChange,
  isSubGadget = false
}) {
  const { appId, pageId } = useParams()
  const [linkedGadgets, setLinkedGadgets] = React.useState(null)
  const subGadget = selectedGadget?.subGadget

  const { data, loading } = useQuery(formQuery, {
    skip: isSubGadget,
    variables: {
      id: source?.appId || source?.id,
      pageId: source?.type === 'dataset' ? source?.id : source?.id
    }
  })

  React.useEffect(() => {
    if (isSubGadget) {
      const gadgets = getRepeatableChildren(selectedGadget)?.filter(g =>
        gadgetReferencesDataset(g, pageId || appId)
      )
      setLinkedGadgets(gadgets)
    } else if (data) {
      const template = get(data, 'app.dataset.formContainer.template')
      setLinkedGadgets(getDataGadgets(template, pageId || appId))
    }
  }, [data, selectedGadget, isSubGadget])

  if (!source) return null

  return (
    <div className='relative flex h-full flex-col overflow-auto text-sm'>
      {source && (
        <div className='flex w-full items-center justify-between border-b border-light-gray-300 bg-white p-2 pl-4 uppercase text-medium-gray-500'>
          {title}
          {loading && (
            <div className='animate-fade-in'>
              <Spinner size={16} />
            </div>
          )}
        </div>
      )}
      <ul className='h-full w-full'>
        {!linkedGadgets ? (
          <div
            key='loading'
            className='flex h-full w-full animate-fade-in flex-col items-center justify-center p-6 text-medium-gray-500'
          >
            Searching for linked gadgets
          </div>
        ) : linkedGadgets.length === 0 ? (
          <div
            key='no-linked'
            className={cx(
              'flex h-full w-full animate-fade-in flex-col items-center justify-center p-6 transition-all',
              {
                'text-medium-gray-200': loading,
                'text-medium-gray-500': !loading
              }
            )}
          >
            No linked gadgets
          </div>
        ) : (
          linkedGadgets.map(field => {
            const Icon = gadgets[field.type]?.meta?.Icon
            const isSelected = isSubGadget
              ? subGadget?.id === field?.id
              : selectedGadget?.id === field?.id

            return (
              <li key={field?.id}>
                <button
                  disabled={loading}
                  onClick={() => onChange(field)}
                  key={field?.id}
                  className={cx(
                    'flex min-h-12 w-full animate-fade-in items-center px-4 py-2 text-left transition-all active:bg-blue-100',
                    {
                      'bg-blue-100': isSelected,
                      'hover:bg-light-gray-100': !isSelected && !loading,
                      'text-dark-gray-500': !loading,
                      'cursor-default text-medium-gray-200': loading
                    }
                  )}
                >
                  {Icon && <Icon className='mr-3' />}
                  <span className='text-sm'>{field?.label}</span>
                </button>
              </li>
            )
          })
        )}
      </ul>
    </div>
  )
}

const getDataGadgets = (template, datasetId) => {
  return uniqWith(
    collectGadgets(template).reduce((acc, gadget, i, arr) => {
      if (REPEATABLE_TYPES.includes(gadget.type)) {
        return getRepeatableChildren(gadget).reduce((acc, child) => {
          if (gadgetReferencesDataset(child, datasetId)) {
            acc.push(gadget)
          }
          return acc
        }, acc)
      }
      if (gadgetReferencesDataset(gadget, datasetId)) {
        acc.push(gadget)
      }
      return acc
    }, []),
    isEqual
  )
}

function gadgetReferencesDataset (gadget, datasetId) {
  if (!INTEGRATION_TYPES.includes(gadget.type)) return false
  if (gadget.details?.isProduct) {
    return gadget.details?.pageId === datasetId
  } else {
    return gadget.details?.id === datasetId
  }
}

const formQuery = gql`
  query FormQuery($id: ID!, $pageId: ID!) {
    app(id: $id) {
      id
      dataset(id: $pageId) {
        id
        formContainer {
          id
          template
        }
      }
    }
  }
`
