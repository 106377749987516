/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import cx from 'clsx'
import React from 'react'

import { useLocalStorageState } from './use-local-storage-state'

export default function GuideBox ({ offset, ...props }) {
  if (offset) {
    return (
      <div className='relative'>
        <div className={`absolute ${offset}`}>
          <GuideBoxInner {...props} />
        </div>
      </div>
    )
  }
  return <GuideBoxInner {...props} />
}

export function GuideBoxInner ({
  arrowPosition = 'top',
  arrowOrientation = 'right',
  className,
  localStorageName,
  type,
  title,
  message,
  expiration,
  learnMoreLink
}) {
  const [local, setLocal] = useLocalStorageState(localStorageName, null, {
    jsonify: false
  })

  if (local) return null
  if (expiration) {
    const date = new Date(expiration)
    if (date.valueOf() < Date.now()) return null
  }

  const side = arrowPosition === 'left' || arrowPosition === 'right'

  return (
    <div className='relative'>
      <span
        role='guide'
        className={cx(
          'bg-blend absolute z-10 w-72 rounded bg-dark-gray-300 px-5 py-4 text-sm text-white backdrop-blur-sm after:absolute after:border-8 after:content-[""]',
          {
            // Which side of the box the arrow is on (top, bottom, left, right)
            'top-[calc(50%+10px)] -translate-x-1/2 after:bottom-[100%] after:border-x-transparent after:border-b-dark-gray-300 after:border-t-transparent':
              arrowPosition === 'top',
            'bottom-[calc(50%+10px)] -translate-x-1/2 after:top-[100%] after:border-x-transparent after:border-b-transparent after:border-t-dark-gray-300':
              arrowPosition === 'bottom',
            'right-[calc(50%+10px)] after:left-[100%] after:border-y-transparent after:border-l-dark-gray-300 after:border-r-transparent':
              arrowPosition === 'right',
            'left-[calc(50%+10px)] after:right-[100%] after:border-y-transparent after:border-l-transparent after:border-r-dark-gray-300':
              arrowPosition === 'left',
            // Which corner of the box the arrow is closest to (center, top, bottom, left, right)
            'after:top-5': arrowOrientation === 'top',
            'after:bottom-5': arrowOrientation === 'bottom',
            'after:right-1/2 after:translate-x-28':
              arrowOrientation === 'right',
            'after:left-1/2 after:-translate-x-28': arrowOrientation === 'left',
            'after:top-1/2': arrowOrientation === 'center' && side,
            'after:left-1/2 after:-translate-x-1/2':
              arrowOrientation === 'center' && !side,
            // Positioning the box based on the arrow position
            '-left-24': arrowOrientation === 'right',
            'left-28': arrowOrientation === 'left',
            '-top-7': arrowOrientation === 'top',
            '-bottom-7': arrowOrientation === 'bottom',
            'top-[calc(100%-10px)] -translate-y-1/2':
              arrowOrientation === 'center' && side
          },
          className
        )}
      >
        <h1
          className={cx(
            'font-bold before:mr-2 before:rounded-sm before:px-2 before:py-1 before:font-normal before:text-black',
            {
              'before:bg-yellow-400 before:content-["UPDATE"]':
                type === 'update',
              'before:bg-green-200 before:content-["NEW!"]': type === 'new'
            }
          )}
        >
          {title}
        </h1>
        <h2 className='py-3'>{message}</h2>
        <div className='flex'>
          <div className='flex-1' />
          {learnMoreLink && (
            <button
              className='kp-button-outline mr-2 text-white hover:bg-dark-gray-500'
              onClick={() => window.open(learnMoreLink, '_blank')}
            >
              {i18n._({ id: 'learn.more', message: 'Learn More' })}
            </button>
          )}
          <button
            className='kp-button-solid shadow-dark-gray-300'
            onClick={() => setLocal(true)}
          >
            {i18n._({ id: 'got.it!', message: 'Got it!' })}
          </button>
        </div>
      </span>
    </div>
  )
}
