/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { includes } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import InfoBox from '../../../components/info-box'
import Formbot from '../../../formbot'
import StepIcon from '../../../pages/old-actions/components/step-icon'

export default function SimulationFormEditor ({
  currentUser,
  doc,
  handleChange,
  header = null,
  hideOfficeUse,
  imageCache,
  instructions,
  labelSize,
  metaFields,
  template,
  updateImageCache,
  usedFields = [],
  validations
}) {
  return (
    <ScrollableForm>
      {header}
      {instructions && (
        <InfoBox
          icon={StepIcon({
            type: 'Instruction',
            fillColor: 'white',
            mr: 0,
            size: '24px'
          })}
          title={i18n._('instructions')}
          details={instructions}
          backGroundColor='#468DCB'
        />
      )}
      <Formbot.Edit
        className='formbot'
        document={doc}
        onChange={handleChange}
        structure={{
          template,
          metaFields,
          integrationFields: [],
          trashed: []
        }}
        hideOfficeUse={hideOfficeUse}
        context={{
          actionId: '1234',
          documentMeta: doc?.meta,
          validations,
          labelSize,
          currentUser,
          imageCache,
          updateImageCache,
          warnings: [
            {
              message: i18n._('potential.issue.field.used.but.not.required'),
              shouldShow: template =>
                includes(usedFields, `data.${template?.formKey}`) &&
                !template.required
            }
          ]
        }}
        highlight={{
          fields: usedFields,
          color: 'var(--yellow-50)'
        }}
      />
    </ScrollableForm>
  )
}

const ScrollableForm = styled.div`
  min-height: 340px;
  height: 100%;
  overflow: auto;
`
