/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useOutletContext, useParams } from 'react-router'

import * as OldAdvanced from './advanced'

export default function LassoEdit () {
  const { id } = useOutletContext()
  const { integrationId } = useParams()
  if (integrationId.startsWith('ADVANCED:')) return <OldAdvanced.Edit />
  return (
    <iframe
      className='block h-full w-full'
      id='lasso-edit'
      src={`/modules/lasso/edit/${id}`}
    />
  )
}
