/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, isEqual } from 'lodash'
import React from 'react'
import { useNavigate, useOutletContext } from 'react-router'
import { useImmer } from 'use-immer'

import { Columns } from '../../../components/data-table/columns-button'
import {
  EmptySorts,
  Sorts,
  newSort
} from '../../../components/data-table/sort-button'
import { ModalPage } from '../../../components/modal-page'
import { gadgets } from '../../../formbot'
import { useAlerts } from '../../../ui/alerts'
import { useUpdateDataSettingsMutation } from './mutation.update-data-settings'
import { buildColumns, buildSorts, paramsToGql } from './use-params'
import { useQueryContext } from './use-query-context'

export default function DataSettings () {
  const { app, reset } = useOutletContext()
  const navigate = useNavigate()
  const query = useQueryContext()
  const updateSettings = useUpdateDataSettingsMutation()
  const alerts = useAlerts()

  const [params, updateParams] = useImmer({
    columns: app
      ? buildColumns(
          app.dataset.form,
          app.dataset.documentListConfig?.columns || []
        )
      : [],
    sorts: app
      ? buildSorts(
          app.dataset.form.schema,
          app.dataset.documentListConfig?.sort || []
        )
      : []
  })

  React.useEffect(() => {
    return handleUpdate(updateSettings, params, app, query, alerts, reset, 1000)
  }, [
    params,
    app?.dataset.documentListConfig?.columns || [],
    app?.dataset.documentListConfig?.sort || [],
    query,
    alerts,
    updateSettings
  ])

  const sortableColumns = React.useMemo(
    () =>
      params.columns.filter(
        col => !(col.unsortable || get(gadgets, [col.type, 'unsortable']))
      ),
    [params.columns]
  )

  if (!app) {
    return (
      <h1 className='loading'>
        <Trans id='pagesbuilder.doclist.loading' />
      </h1>
    )
  }

  return (
    <ModalPage
      title={i18n._('pagesbuilder.doclist.data.settings')}
      width='575px'
      side
      onClose={() => {
        navigate('..')
        handleUpdate(updateSettings, params, app, query, alerts, reset, 0)
      }}
    >
      <div className='mx-auto my-12 max-w-[495px] text-sm max-[500px]:max-w-[90%]'>
        <h2 className='mb-3 text-2xl'>
          <Trans id='pagesbuilder.doclist.default.options' />
        </h2>
        <p className='pb-3'>
          <Trans id='pagesbuilder.doclist.default.options.data' />
        </p>
        <Columns
          className='h-80 w-full'
          update={updateParams}
          value={params.columns}
        />
        {params.sorts.length === 0 ? (
          <EmptySorts
            onClick={() =>
              setTimeout(() =>
                updateParams(draft => {
                  draft.sorts = [newSort()]
                })
              )
            }
          />
        ) : (
          <Sorts
            update={updater =>
              updateParams(draft => {
                updater(draft.sorts)
              })
            }
            value={params.sorts}
            columns={sortableColumns}
            schema={app.dataset.form.schema}
          />
        )}
      </div>
    </ModalPage>
  )
}

const handleUpdate = (
  updateSettings,
  params,
  app,
  query,
  alerts,
  reset,
  time
) => {
  const { columns, sort } = paramsToGql(params)
  if (
    isEqual(columns, app?.dataset.documentListConfig?.columns || []) &&
    isEqual(sort, app?.dataset.documentListConfig?.sort || [])
  ) {
    return
  }

  const timeout = setTimeout(() => {
    updateSettings(sort, columns, query)
      .then(() => {
        alerts.type3(i18n._('pagesbuilder.doclist.settings.saved'), 'success')
        setTimeout(() => {
          reset()
        }, 200)
      })
      .catch(() =>
        alerts.type3(i18n._('pagesbuilder.doclist.error.saving'), 'error')
      )
  }, time)
  return () => clearTimeout(timeout)
}
