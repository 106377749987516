/* Copyright © 2024 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'

import Loading from '../../../components/loading'
import { NotFound } from '../../../components/system-error'
import { useQuery } from '../../../components/use-query'
import SimulatedEmail from '../../../flowbot/components/simulator-parts/simulated-email'

export default function ViewEmail () {
  const { historyId, documentId } = useParams()
  const q = getHistoryEventQuery(historyId, documentId)
  const { data, loading, error } = useQuery(q)
  if (loading) return <Loading />
  if (error) return <NotFound />

  const { content } = data?.historyEvent
  const body = content?.bodyHtml || ''
  const parser = new DOMParser()
  const doc = parser.parseFromString(body, 'text/html')
  const bodyContent = doc.body.innerHTML
  return (
    <SimulatedEmail
      attachment={content?.attachment}
      body={
        <div
          className='prose ml-[-24px] mt-[-80px] max-w-full prose-tr:border-0'
          dangerouslySetInnerHTML={{ __html: bodyContent }}
        />
      }
      hideFooter
      subject={content?.subject}
      toEmail={content?.recipients.join(', ')}
    />
  )
}

const getHistoryEventQuery = (historyId, documentId) => {
  return {
    variables: {
      historyId,
      documentId
    },
    query: gql`
      query HistoryEvent($historyId: ID!, $documentId: ID!) {
        historyEvent(id: $historyId, documentId: $documentId) {
          content
          id
        }
      }
    `
  }
}
