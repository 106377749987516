/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useQueryContext } from './use-query-context'

export const useCreatePolicyGroupMutation = applicationId => {
  const [createPolicyGroup] = useMutation(mutation)
  const query = useQueryContext()
  return (name, description = '') =>
    createPolicyGroup(getParams(applicationId, name, description, query))
}

const mutation = gql`
  mutation CreatePolicyGroup(
    $applicationId: String!
    $name: String!
    $description: String
  ) {
    createPolicyGroup(
      args: {
        applicationId: $applicationId
        name: $name
        description: $description
      }
    )
  }
`

const getParams = (applicationId, name, description, query) => ({
  variables: { applicationId, name, description },
  refetchQueries: [query]
})
