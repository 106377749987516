/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import React from 'react'

import { winnowList } from '../../../components/escape-string-regexp'
import InfiniteScroll from '../../../components/infinite-scroll'
import Spinner from '../../../components/spinner'
import { SearchBar } from '../form-typeahead/config.new'

export default function MillerColumns ({
  columns,
  activeColumns = [0],
  maxVisibleColumns = 3,
  className,
  header
}) {
  const [offset, setOffset] = React.useState(0)

  const startIndex = Math.max(
    0,
    activeColumns.length - maxVisibleColumns + offset
  )
  const endIndex = startIndex + maxVisibleColumns

  const renderColumns = Array.from(
    { length: maxVisibleColumns },
    (_, index) =>
      columns[activeColumns[startIndex + index]] || {
        Component: () => (
          <div className='h-[90vh] flex-1 overflow-auto md:h-[336px]' />
        )
      }
  )

  const canNavigateLeft = startIndex > 0
  const canNavigateRight = endIndex < activeColumns.length

  const navigateLeftButton = {
    disabled: !canNavigateLeft,
    onClick: () => setOffset(prev => prev - 1)
  }

  const navigateRightButton = {
    disabled: !canNavigateRight,
    onClick: () => setOffset(prev => prev + 1)
  }

  return (
    <div className='w-full'>
      {header && (
        <div className='flex w-full items-center justify-between'>
          {header?.({ left: navigateLeftButton, right: navigateRightButton })}
        </div>
      )}
      <div className={cx('flex divide-x divide-light-gray-300', className)}>
        {renderColumns.map((column, index) => (
          <div
            key={column.title || index}
            className='h-[90vh] flex-1 overflow-auto md:h-[336px]'
          >
            <MillerColumn {...column} />
          </div>
        ))}
      </div>
    </div>
  )
}

function MillerColumn ({
  title,
  values,
  loading,
  selected,
  onChange,
  searchable = true,
  renderItem,
  Component,
  ...props
}) {
  const [search, setSearch] = React.useState(null)
  const filteredValues = searchable
    ? winnowList(values, search, 'label')
    : values

  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref?.current && !ref.rendered) {
      ref.rendered = true
      ref.current.scrollIntoView({
        behavior: 'instant',
        block: 'start'
      })
    }
  })

  if (Component) {
    return (
      <Component
        title={title}
        values={filteredValues}
        selected={selected}
        onChange={onChange}
        loading={loading}
        {...props}
      />
    )
  }

  return (
    <div className='flex h-full flex-col text-sm'>
      <div className='z-10 flex w-full flex-col gap-2 bg-white'>
        <div className='flex items-center justify-between border-b border-light-gray-300 p-2 pl-4 pr-2 uppercase text-medium-gray-500'>
          <span>{title}</span>
          {loading && (
            <div className='animate-fade-in'>
              <Spinner size={16} />
            </div>
          )}
        </div>
        {searchable && (
          <div className='px-2 pb-2'>
            <SearchBar value={search} onChange={setSearch} />
          </div>
        )}
      </div>
      <div className='relative flex-1 overflow-hidden'>
        <InfiniteScroll className='absolute inset-0'>
          {filteredValues?.map(item => (
            <button
              ref={selected?.id === item.id ? ref : null}
              key={item.id}
              onClick={() => onChange?.(item)}
              className={cx(
                'flex max-h-12 w-full max-w-full animate-fade-in items-center px-4 py-2 text-left transition-all active:bg-blue-100',
                {
                  'bg-blue-100 dark:bg-light-gray-300':
                    selected?.id === item.id,
                  'hover:bg-light-gray-100': selected?.id !== item.id
                }
              )}
            >
              {renderItem ? renderItem(item) : <span>{item.label}</span>}
            </button>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}
