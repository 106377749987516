/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { includes } from 'lodash'

import { multipleLanguages } from '../../../components/feature-flags'
import {
  extractCurrencyConfig,
  toCurrency
} from '../../../pages-builder/calculations/calculation-functions'

export const handleCurrencyValue = (value, details) => {
  const { currency, decimals } = extractCurrencyConfig(details)
  return toCurrency(value, currency, decimals) ?? ''
}

// Removes invalid characters and returns a valid integer
export const handleCurrencyInput = (input, decimals) => {
  const sanitized = sanitizeNumericInput(input)
  if (sanitized === '') return null
  if (sanitized === '-') return -0
  if (isZeroes(sanitized)) return null // enables clearing the field with backspaces
  const number = convertToNumber(sanitized)
  if (number > Number.MAX_SAFE_INTEGER) return
  // we always convert and store in pennies
  const pennies = convertToPennies(number, decimals)
  return pennies
}

export const handleCurrencyInputOld = (rawVal, value) => {
  if (rawVal === '-') return -0
  const isNegative = rawVal.startsWith('-') || rawVal.startsWith('$-')
  const val = rawVal.replace(/[^\d]/g, '')
  if (val === '') return null
  if (includes(['0', '00'], val) && value === 0) {
    return null
  }
  const multiplier = isNegative ? -1 : 1
  const number = parseInt(val, 10) * multiplier
  if (number > Number.MAX_SAFE_INTEGER) return
  if (!isNaN(number)) return number
}

export const handleChange = (rawVal, value, details, onChange) => {
  if (multipleLanguages) {
    const { decimals } = extractCurrencyConfig(details)
    const results = handleCurrencyInput(rawVal, decimals)
    return onChange(results)
  } else {
    if (rawVal === '-') return onChange(-0)
    const isNegative = rawVal.startsWith('-') || rawVal.startsWith('$-')
    const val = rawVal.replace(/[^\d]/g, '')
    if (val === '') return onChange(null)
    if (includes(['0', '00'], val) && value === 0) {
      return onChange(null)
    }
    const multiplier = isNegative ? -1 : 1
    const number = parseInt(val, 10) * multiplier
    if (number > Number.MAX_SAFE_INTEGER) return
    if (!isNaN(number)) return onChange(number)
  }
}

// Removes all non-numeric characters and negative symbols not at the start
// and returns a valid integer
const sanitizeNumericInput = input => {
  if (typeof input !== 'string') return ''

  return input
    .replace(/[^\d-]/g, '') // Remove non-numeric and non-negative symbols
    .replace(/(?!^)-/g, '') // Remove negative symbols not at start
}

const convertToNumber = sanitizedInput => {
  if (!sanitizedInput) return 0

  const num = parseInt(sanitizedInput, 10)
  if (Number.isSafeInteger(num)) return num

  return sanitizedInput.startsWith('-')
    ? Number.MIN_SAFE_INTEGER
    : Number.MAX_SAFE_INTEGER
}

const isZeroes = input => {
  return includes(
    ['0', '-0', '00', '-00', '000', '-000', '0000', '-0000'],
    input
  )
}

const convertToPennies = (number, decimals) => {
  switch (decimals) {
    case '0':
    case 0:
      return number * 100
    case '1':
    case 1:
      return number * 10
    case '2':
    case 2:
      return number
    case '3':
    case 3:
      return number / 10
    default:
      return number
  }
}
