/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

export const useSetDataSharingMutation = id => {
  const [setDataSharing] = useMutation(mutation)
  return (sharingType, apps, conditionallyShared) =>
    setDataSharing(getParams(id, sharingType, apps, conditionallyShared))
}

const mutation = gql`
  mutation SetDataSharing(
    $id: ID!
    $sharingType: SharingType!
    $apps: [IdLabelInput!]
  ) {
    shareApp(id: $id, sharingType: $sharingType, apps: $apps) {
      id
      sharedWithOthers {
        type
        conditionallyShared
        apps {
          id
          name
          tileOptions {
            backgroundColor
            iconName
          }
        }
      }
    }
  }
`

const getParams = (id, sharingType, apps, conditionallyShared) => ({
  variables: {
    id,
    sharingType,
    apps: apps.map(app => ({ id: app.id, label: app.name }))
  },
  optimisticResponse: {
    __typename: 'Mutation',
    shareApp: {
      __typename: 'App',
      id,
      sharedWithOthers: {
        __typename: 'SharedWithOthers',
        conditionallyShared,
        type: sharingType,
        apps
      }
    }
  }
})
