/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useLazyQuery, useSubscription } from '@apollo/client'
import { Trans } from '@lingui/react'
import React from 'react'
import { useLocation } from 'react-router'

import config from '../config'
import Button from '../ui/button'

const GET_CLIENT_VERISON = gql`
  query getClientVersion {
    clientVersion {
      latestVersion
      minVersion
    }
  }
`

const CLIENT_UPDATED_SUBSCRIPTION = gql`
  subscription onClientUpdated {
    clientUpdated {
      latestVersion
      minVersion
    }
  }
`

function useVersionChecker (version) {
  const [shouldRefresh, setShouldRefresh] = React.useState(false)
  const [shouldReload, setShouldReload] = React.useState(false)
  const setVersion = React.useCallback(
    newVersion => {
      if (newVersion == null) return
      if (version >= newVersion.latestVersion) return
      if (version < newVersion.minVersion) {
        setShouldReload(true)
      } else if (version < newVersion.latestVersion) {
        setShouldRefresh(true)
      }
    },
    [version]
  )

  return [setVersion, shouldRefresh, shouldReload]
}

function useReconnect (cb) {
  React.useEffect(() => {
    window.addEventListener('online', cb)
    return () => window.removeEventListener('online', cb)
  }, [cb])
}

const blacklist = ['/action', '/start', '/run']
const isRunPage = path =>
  blacklist.find(slug => path.endsWith(slug) || path.includes(`${slug}/`))

export default () => {
  const location = useLocation()
  if (isRunPage(location.pathname)) return null
  return <ClientUpdatedBar />
}

function ClientUpdatedBar () {
  const [setVersion, shouldRefresh, shouldReload] = useVersionChecker(
    config.version
  )

  const [
    getClientVersion,
    { called, data: initialData, loading: initialLoading }
  ] = useLazyQuery(GET_CLIENT_VERISON, {
    fetchPolicy: 'network-only'
  })

  useSubscription(CLIENT_UPDATED_SUBSCRIPTION, {
    onData: ({ data: { data } }) => {
      setVersion(data.clientUpdated)
    }
  })

  useReconnect(getClientVersion)
  const refresh = React.useCallback(() => window.location.reload(true), [])

  React.useEffect(() => {
    if (!initialLoading && initialData) {
      setVersion(initialData.clientVersion)
    }
  }, [initialData, initialLoading, setVersion])

  React.useEffect(() => {
    if (!called) {
      getClientVersion()
    }
  }, [called, getClientVersion])

  return (
    <>
      {shouldRefresh && !shouldReload && (
        <div className='w-full select-none'>
          <div className='flex h-11 cursor-default items-center justify-center whitespace-nowrap bg-blue-300 fill-white px-3 text-sm leading-tight text-white'>
            <div className='min-w-0 overflow-hidden text-ellipsis whitespace-nowrap'>
              <Trans id='is.new.version.of.build' />
              <Button ml='12px' outline onClick={refresh}>
                <Trans id='update.now' />
              </Button>
            </div>
          </div>
        </div>
      )}
      {shouldReload && (
        <div className='bg-black/25 absolute inset-0 z-30 flex items-center justify-center'>
          <div className='relative z-10 mx-6 w-96 overflow-hidden rounded-sm bg-white px-8 py-6 text-base shadow-[rgba(15,15,15,0.05)_0px_0px_0px_1px,rgba(15,15,15,0.1)_0px_5px_10px,rgba(15,15,15,0.2)_0px_15px_40px]'>
            <div className='flex min-h-16 items-center justify-center'>
              <Trans id='is.new.version.of.build' />
            </div>
            <Button width='100%' mt={2} outline onClick={refresh}>
              <Trans id='update.now' />
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
