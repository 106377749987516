/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import ReactDOM from 'react-dom'

import { GLaDOS, PortalBlue } from '../../components/portals'
import DocView from './components/doc-view'

export default function DocViewPortal () {
  return ReactDOM.createPortal(
    <GLaDOS>
      <div className='flex items-center justify-between border-b border-b-light-gray-300 pl-6 pr-6 dark:border-b-light-gray-100 dark:bg-light-gray-300 max-[985px]:p-4 max-md:max-h-24 max-md:min-h-16 md:h-16'>
        <PortalBlue className='flex w-full items-center justify-between empty:hidden' />
      </div>
      <DocView />
    </GLaDOS>,
    document.body
  )
}
