/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'

import { VisuallyHidden } from '../../ui/a11y'
import { Wrapper } from '../../ui/layout'
import Radios from '../../ui/radios'
import { Body1 } from '../../ui/typography'

export const Config = ({ value, updateValue, workflowSettings }) => {
  return (
    <>
      <Wrapper p={3}>
        <Body1 py={2} id='if-denied-action-title'>
          <Trans id='what.happens.if.denied' />
        </Body1>
        <Radios
          aria-labelledby='if-denied-action-title'
          name='if-denied-action'
          value={value.ifDenied.action}
          onChange={id =>
            updateValue(draft => {
              draft.ifDenied.action = id
            })
          }
          options={[
            {
              id: 'custom_steps',
              label: i18n._('custom.steps.add.in.denial')
            },
            { id: 'nothing', label: i18n._('do.nothing') }
          ]}
        />
        <Body1 py={2}>
          <Trans id='then.colon' />
        </Body1>
        <VisuallyHidden id='if-denied-then-title'>
          <Trans id='after.workflow.denial.action.happens' />
        </VisuallyHidden>
        <Radios
          aria-labelledby='if-denied-then-title'
          name='if-denied-then'
          value={value.ifDenied.andThen}
          onChange={id =>
            updateValue(draft => {
              draft.ifDenied.andThen = id
            })
          }
          options={[
            { id: 'stop', label: i18n._('stop.workflow') },
            { id: 'continue', label: i18n._('continue.workflow') }
          ]}
        />
      </Wrapper>
      <div className='w-full border-b border-light-gray-400 pb-4' />
    </>
  )
}

export const View = ({ details, children }) => (
  <Wrapper p={2}>
    <div className='px-1 text-sm'>
      <Trans id='if.denied.colon' />
    </div>
    {details.ifDenied.action === 'custom_steps' ? (
      children
    ) : (
      <div className='px-1 text-sm'>
        <Trans id='do.nothing' />
      </div>
    )}
    <div className='px-1 text-sm'>
      <Trans id='and.then' />
      {details.ifDenied.andThen === 'stop' ? (
        <span className='ml-1 text-[#c23e38] dark:text-red-500'>stop</span>
      ) : (
        <span className='ml-1 text-green-400'>continue</span>
      )}
    </div>
  </Wrapper>
)

export const defaults = () => ({
  ifDenied: {
    action: 'nothing',
    andThen: 'stop'
  }
})
