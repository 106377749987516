/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { useApolloClient } from '@apollo/client'
import React from 'react'
import { useNavigate, useOutletContext } from 'react-router'

import { ModalPage } from '../../components/modal-page'
import {
  useEvent,
  useModuleCommunication
} from '../../components/use-module-communication'
import { useQueryContext } from './components/use-query-context'

export default function LassoNew () {
  const { spaceId } = useOutletContext()
  const apolloClient = useApolloClient()
  const query = useQueryContext()
  const navigate = useNavigate()
  const [ref, sendMessage] = useModuleCommunication((event, data) => {
    if (event === 'refetch') apolloClient.refetchQueries({ include: [query] })
    if (event === 'navigate') navigate(data.to)
  })
  useEvent(window, 'theme-toggled', e => sendMessage('theme', e.detail))

  return (
    <ModalPage title='New Lasso'>
      <iframe
        ref={ref}
        className='block h-full w-full'
        id='lasso-new'
        src={`/modules/lasso/new?space_id=${spaceId || ''}`}
      />
    </ModalPage>
  )
}
