/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import cx from 'clsx'
import { getMonth } from 'date-fns/getMonth'
import { getYear } from 'date-fns/getYear'
import { range } from 'lodash'
import React from 'react'
import DatePickerLib from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { getFormatLocale } from '../i18n'
import * as Icons from '../icons'
import Button from '../ui/button'
import { Option, Select } from '../ui/select'

/**  Returns array of localized month names in short format
 * English output:
 * ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
 * Spanish output:
 * ['ene.', 'feb.', 'mar.', 'abr.', 'may.', 'jun.', 'jul.', 'ago.', 'sept.', 'oct.', 'nov.', 'dic.']
 * French output:
 * ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.']
 */
const getLocalizedMonthNames = locale => {
  const formatter = new Intl.DateTimeFormat(locale, { month: 'short' })
  const months = []

  for (let month = 0; month < 12; month++) {
    const date = new Date(2020, month, 1) // Can be any date
    months.push(formatter.format(date))
  }
  return months
}

/** Returns the expected date format for the current locale
 *  This is used as placeholder text in the date picker
 *  English (United States) output: MM/DD/YYYY
 *  English (Canada) output: YYYY-MM-DD
 *  Spanish output: DD/MM/YYYY
 *  French output: DD/MM/YYYY
 */
const getExpectedDatePattern = locale => {
  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  const sampleDate = new Date(2023, 0, 28) // can be any date
  const formattedDate = formatter.format(sampleDate)

  const formatPattern = formattedDate
    .replace('2023', 'YYYY')
    .replace('01', 'MM')
    .replace('28', 'DD')

  return formatPattern
}

const curYear = getYear(new Date())
const years = range(curYear - 120, curYear + 120, 1)

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  i18n
}) => {
  const months = getLocalizedMonthNames(i18n.locale)

  return (
    <div className='flex justify-between px-[25px] pb-3 pt-1 [&_select]:w-[73px]'>
      <Button
        icon
        transparent
        small
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        aria-label={i18n._('previous.month')}
      >
        <Icons.SelectDownArrow style={{ transform: 'rotateZ(90deg)' }} />
      </Button>
      <Select
        small
        onChange={value => changeMonth(months.indexOf(value))}
        value={months[getMonth(date)]}
      >
        {months.map(option => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
      <Select small onChange={value => changeYear(value)} value={getYear(date)}>
        {years.map(option => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
      <Button
        icon
        transparent
        small
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        aria-label={i18n._('next.month')}
      >
        <Icons.SelectDownArrow style={{ transform: 'rotateZ(-90deg)' }} />
      </Button>
    </div>
  )
}

export function DatePicker ({
  className,
  placeholder,
  value,
  onChange,
  ...props
}) {
  const formatLocale = getFormatLocale(i18n.locale)
  const expectedDatePattern = getExpectedDatePattern(formatLocale.code)

  const noDelimiters = expectedDatePattern
    .replace(/[/-]/g, '')
    .replace(/DD/g, 'dd')
    .replace(/YYYY/g, 'yyyy')

  return (
    <DatePickerLib
      locale={formatLocale}
      dateFormat={['P', noDelimiters]} // P is for the short locale-specific date format ie: MM/DD/YYYY in the US
      showPopperArrow={false}
      portalId='datepicker-portal'
      renderCustomHeader={props => <CustomHeader i18n={i18n} {...props} />}
      popperPlacement='bottom'
      placeholderText={placeholder || expectedDatePattern}
      selected={value}
      onChange={onChange}
      className={cx(
        'h-8 w-full min-w-[150px] rounded-sm border border-[#bebebe] bg-white px-2 py-0 text-sm font-normal dark:border-light-gray-300 [.formbot-config_&]:min-w-0',
        className
      )}
      {...props}
    />
  )
}
