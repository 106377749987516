/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { get, pickBy } from 'lodash'
import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import styled from 'styled-components'

import * as Icons from '../../../icons'
import Button from '../../../ui/button'
import { Body1 } from '../../../ui/typography'
import imageBuilder from './image-builder'
import VariablePicker from './variable-picker'

const Embed = Quill.import('blots/embed')
class Variable extends Embed {
  static create (props) {
    const node = super.create()
    node.setAttribute('data-key', props.key)
    node.setAttribute('src', props.data)
    return node
  }

  static value (node) {
    return {
      key: node.getAttribute('data-key'),
      data: node.getAttribute('src')
    }
  }
}
Variable.blotName = 'variable'
Variable.tagName = 'img'
Quill.register(Variable)

const QUILL_CLIPBOARD_OPTIONS = {
  hooks: {
    uponSanitizeElement (node, hookEvent) {
      if (hookEvent.tagName === 'img' && node.dataset.key) {
        hookEvent.allowedTags.img = true
      }
    }
  }
}
const QUILL_MODULES = {
  basic: {
    toolbar: {
      container: '#notification-config-editor-toolbar',
      handlers: {
        clean: () => false
      }
    },
    clipboard: { matchVisual: false, ...QUILL_CLIPBOARD_OPTIONS }
  },
  'sendback-': {
    toolbar: {
      container: '#sendback-notification-config-editor-toolbar',
      handlers: {
        clean: () => false
      }
    },
    clipboard: { matchVisual: false, ...QUILL_CLIPBOARD_OPTIONS }
  }
}
const QUILL_FORMATS = [
  'align',
  'blockquote',
  'bold',
  'bullet',
  'color',
  'font',
  'header',
  'image',
  'indent',
  'italic',
  'link',
  'list',
  'size',
  'strike',
  'underline',
  'variable'
]
const QUILL_KEYBOARD_OVERRIDE = {
  bindings: {
    enter: {
      key: 13,
      handler: () => false
    },
    tab: false
  }
}

const EXCLUDE_GADGET_TYPES = ['RichText', 'Signature', 'Table', 'Repeater']
const EXCLUDE_GADGET_TYPES_FROM_SUBJECT = ['ImageUpload']

export default function EmailContent ({
  value,
  onChange,
  allFormFields,
  type,
  embedded,
  prefix
}) {
  const quill = React.useRef()
  const quillRef = React.useRef()
  const changesInProgress = React.useRef()
  const [body, setBody] = React.useState('')
  const [showPicker, setShowPicker] = React.useState(false)

  React.useEffect(() => {
    if (typeof get(quillRef.current, 'getEditor') !== 'function') return
    quill.current = quillRef.current.getEditor()
  }, [])

  const addImages = React.useCallback(
    (text = '') => {
      return text.replace(/{{([^}]*)}}/g, (_, key) => {
        const label = get(allFormFields[key], 'label', '*Missing*')
        const color = get(allFormFields[key], 'label')
          ? 'rgb(47, 132, 187)'
          : '#c23e38'
        return `<img data-key="${key}" src="${imageBuilder(label, color)}">`
      })
    },
    [allFormFields]
  )

  React.useEffect(() => {
    const newBody = addImages(value)
    setBody(newBody)
  }, [addImages, value])

  const handleEditorChange = () => {
    if (changesInProgress.current || !quill.current) return
    changesInProgress.current = true
    const oldBody = quill.current.root.innerHTML
    const newBody = addImages(oldBody)
    if (newBody === body) {
      // We must set the body twice in this case or else the element will not re-render and the
      // "newBody" with images won't display
      setBody(oldBody)
    }
    setBody(newBody)
    onChange(replaceImages(oldBody, type === 'subject'))
    changesInProgress.current = false
  }

  const handleVariablePickerSelection = ({ formKey, label }) => {
    setShowPicker(false)
    setTimeout(() => {
      const range = quill.current.getSelection(true)
      const pos = range ? range.index : 0
      const data = imageBuilder(label)
      quill.current.insertEmbed(pos, 'variable', { data, key: formKey })
      quill.current.setSelection(pos + 1, 0)
    }, 0)
  }

  return type === 'subject' ? (
    <>
      <EmailBody>
        <EmailSubject
          className={cx(
            {
              'bg-[#f1faff]': embedded,
              'bg-white': !embedded
            },
            'border-b border-light-gray-200 dark:border-b-light-gray-300 dark:bg-transparent'
          )}
        >
          <Body1 py={2} required>
            <Trans id='email.subject' />
          </Body1>
          <MyButton transparent onClick={() => setShowPicker(a => !a)}>
            <Add /> <Trans id='add.variable' />
          </MyButton>
        </EmailSubject>
        {showPicker && (
          <VariablePicker
            onSelect={handleVariablePickerSelection}
            formFields={subjectFormFields(allFormFields)}
          />
        )}
        <MyMiniReactQuill
          ref={quillRef}
          onChange={handleEditorChange}
          placeholder={i18n._('notification.subject')}
          modules={{
            keyboard: QUILL_KEYBOARD_OVERRIDE,
            clipboard: QUILL_CLIPBOARD_OPTIONS,
            toolbar: false
          }}
          formats={['variable']}
          theme='snow'
          value={body}
          className='quill-contents notranslate'
        />
      </EmailBody>
    </>
  ) : (
    <>
      <EmailBody>
        <div id={`${prefix}notification-config-editor-toolbar`}>
          <select
            className='ql-header'
            defaultValue='false'
            title={i18n._({
              id: 'paragraph.format',
              message: 'Paragraph Format'
            })}
          >
            <option value='1' />
            <option value='2' />
            <option value='false' />
          </select>
          <button
            className='ql-bold'
            title={i18n._({ id: 'bold', message: 'Bold' })}
          />
          <button
            className='ql-italic'
            title={i18n._({ id: 'italic', message: 'Italic' })}
          />
          <select
            className='ql-color'
            defaultValue='false'
            title={i18n._({ id: 'text.color', message: 'Text Color' })}
          >
            <option value='red' />
            <option value='green' />
            <option value='blue' />
            <option value='orange' />
            <option value='violet' />
            <option value='#d0d1d2' />
            <option value='false' />
          </select>
          <button
            className='ql-link'
            title={i18n._({ id: 'remove.link', message: 'Insert Link' })}
          />
          <button
            className='ql-clean'
            title={i18n._({
              id: 'remove.formatting',
              message: 'Remove Formatting'
            })}
            onClick={() => removeFormattingExceptVariables(quill.current)}
          />
          <MyButton transparent onClick={() => setShowPicker(a => !a)}>
            <Add />
            <Trans id='add.variable' message='Add Variable' />
          </MyButton>
        </div>
        {showPicker && (
          <VariablePicker
            onSelect={handleVariablePickerSelection}
            formFields={bodyFormFields(allFormFields)}
          />
        )}
        <MyReactQuill
          ref={quillRef}
          onChange={handleEditorChange}
          placeholder={i18n._('notification.body')}
          modules={QUILL_MODULES[prefix] || QUILL_MODULES.basic}
          formats={QUILL_FORMATS}
          theme='snow'
          value={body}
          className='quill-contents notranslate'
        />
      </EmailBody>
    </>
  )
}

export function replaceImages (body, removeHtmlWrapper) {
  if (body === '<p><br></p>') {
    return '' // Fix a quilljs issue where you enter data and backspace it out it leaves br behind
  }
  const noImages = body.replace(/<img(.*?)data-key="([^"]*)"(.*?)>/g, '{{$2}}')
  if (removeHtmlWrapper) {
    return noImages.replace(/<p>(.*)<\/p>/, '$1')
  }
  return noImages
}

export function subjectFormFields (allFormFields) {
  return pickBy(allFormFields, field => {
    return (
      !EXCLUDE_GADGET_TYPES.includes(field.type) &&
      !EXCLUDE_GADGET_TYPES_FROM_SUBJECT.includes(field.type)
    )
  })
}

export function bodyFormFields (allFormFields) {
  return pickBy(allFormFields, field => {
    return !EXCLUDE_GADGET_TYPES.includes(field.type)
  })
}

function removeFormattingExceptVariables (quill) {
  const range = quill.getSelection()
  if (!range || range.length === 0) return
  const { index, length } = range
  const [line, offset] = quill.scroll.line(index + length)

  let suffixLength = 0
  if (line != null) {
    suffixLength = line.length() - offset
  }

  const formats = QUILL_FORMATS.reduce((acc, format) => {
    if (format === 'variable' || format === 'header') return acc
    acc[format] = false
    return acc
  }, {})

  // header formatting is applied to the \n character at the end of line, so is handled separately
  quill.formatText(index, length, formats)
  quill.formatText(index, length + suffixLength, { header: false })
}

const EmailBody = styled.div`
  & .ql-tooltip {
    left: 12px !important;
  }
`

const EmailSubject = styled.div`
  display: flex;
  align-items: center;
`

const MyButton = styled(Button)`
  margin-left: 8px;
  width: 110px !important;
`

const Add = styled(Icons.Add)`
  padding-right: 4px;
`

const MyReactQuill = styled(ReactQuill)`
  min-height: 20px;
  overflow-y: scroll;

  & img {
    border-radius: 13px;
    vertical-align: middle;
  }

  & .ql-editor.ql-blank::before {
    font-style: normal;
    margin-bottom: 12px;
  }

  & .ql-editor p {
    margin-bottom: 12px;
  }

  & .ql-editor h1,
  & .ql-editor h2 {
    margin-bottom: 20px;
  }
`
const MyMiniReactQuill = styled(MyReactQuill)`
  border-bottom: 1px solid #eee;
  html.dark & {
    // Outlier: dark:border-b-light-gray-300
    border-bottom-color: #333;
  }

  & .ql-editor {
    max-height: 64px;
    min-height: 64px !important;
  }
`
