/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { produce } from 'immer'
import React from 'react'

import { autoUpdatingFilters } from '../../../components/feature-flags'
import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import * as Icons from '../../../icons'
import { FiltersConfig } from '../../filter-config'

export default function AutoUpdateConfig ({
  id,
  Gadgets,
  className,
  columns,
  compareGadgets,
  hasVersions,
  needsVersionFilter
}) {
  const configKey = 'autoUpdate'
  return (
    <Gadgets.Custom
      configKey={configKey}
      group
      className={cx('space-y-2', className)}
    >
      {({ onChange, value }) => (
        <AutoUpdateConfigInner
          id={id}
          columns={columns}
          compareGadgets={compareGadgets}
          configKey={configKey}
          hasVersions={hasVersions}
          needsVersionFilter={needsVersionFilter}
          onChange={onChange}
          value={value}
        />
      )}
    </Gadgets.Custom>
  )
}

function AutoUpdateConfigInner ({
  id,
  columns,
  compareGadgets,
  configKey,
  hasVersions,
  needsVersionFilter,
  onChange,
  value
}) {
  React.useEffect(() => {
    // we switched to filter settings and don't have a filter yet
    if (needsVersionFilter && value?.updateOn !== 'filter') {
      const sharedTermsGadget = columns?.find(
        c => c.type === 'Terms' && !c.id.includes('.data.')
      )
      const myTermsGadget = compareGadgets?.Terms?.find(
        c => !c?.id?.includes('.data.')
      )
      if (sharedTermsGadget && myTermsGadget) {
        onChange({
          enabled: true,
          updateOn: 'filter',
          filter: value?.filter || {
            type: 'AND',
            operators: [
              {
                field: sharedTermsGadget.formKey,
                type: 'OVERLAPS',
                value: myTermsGadget.formKey,
                gadget: 'Terms',
                isDefault: true
              }
            ]
          }
        })
      }

      // we switched back to custom settings
    } else if (!needsVersionFilter && value?.updateOn === 'filter') {
      onChange(
        produce(value, draft => {
          // Keep the filters around just in case it is changed back
          draft.enabled = false
          delete draft.updateOn
        })
      )
    }
  }, [columns, compareGadgets, needsVersionFilter, onChange, value])
  return (
    <>
      <legend className='text-sm font-medium'>
        <Trans
          id='document.updating.when'
          message='When should the primary document update?'
        />
      </legend>
      {autoUpdatingFilters && needsVersionFilter ? (
        <FiltersConfig
          params={{ columns, filter: value?.filter }}
          compareGadgets={compareGadgets}
          topTitle={
            <Trans id="when.the.version's" message="When the version's" />
          }
          updateParams={func => {
            const newVal = produce(value, func)
            return onChange(newVal)
          }}
        />
      ) : (
        <DefaultConfig
          configKey={configKey}
          id={id}
          hasVersions={hasVersions}
          onChange={onChange}
          value={value}
        />
      )}
    </>
  )
}

const BASE_OPTIONS = [
  {
    id: 'disabled',
    label: i18n._({ id: 'never', message: 'Never' }),
    value: { enabled: false }
  },
  {
    id: 'enabled',
    label: i18n._({
      id: 'document.updating.saved',
      message: 'When the linked document is saved'
    }),
    helpText: i18n._({
      id: 'document.updating.saved.help',
      message:
        'This includes when a document is edited then saved on the specific version that is linked (whether as a draft, submitted to workflow, or completed). This does not update upon creation or saving of a new version of the linked document.'
    }),
    value: { enabled: true }
  }
]

const VERSION_OPTIONS = [
  {
    id: 'submitted',
    label: i18n._({
      id: 'document.updating.submitted',
      message: 'When a new version of the linked document is submitted'
    }),
    value: { enabled: true, updateOn: 'submitted' }
  },
  {
    id: 'completed',
    label: i18n._({
      id: 'document.updating.completed',
      message: 'When a new version of the linked document is completed'
    }),
    value: { enabled: true, updateOn: 'completed' }
  }
]

function getCheckedId (value, hasVersions) {
  if (!value?.enabled) return 'disabled'
  if (!hasVersions) return 'enabled'
  if (['submitted', 'completed'].includes(value.updateOn)) {
    return value.updateOn
  }
  return 'enabled'
}

function DefaultConfig ({ configKey, id, hasVersions, onChange, value }) {
  const options = hasVersions
    ? [...BASE_OPTIONS, ...VERSION_OPTIONS]
    : BASE_OPTIONS
  const checkedId = getCheckedId(value, hasVersions)
  return options.map(option => {
    const optionId = `${id}-${configKey}-${option.id}`
    return (
      <div key={option.id} className='flex items-center gap-2'>
        <label>
          <input
            id={optionId}
            type='radio'
            className='kp-radio'
            name={configKey}
            value={option.id}
            checked={option.id === checkedId}
            onChange={() => onChange(option.value)}
            aria-describedby={option.helpText ? `${optionId}-help` : undefined}
          />
          <span>{option.label}</span>
        </label>
        {option.helpText && (
          <>
            <Tooltip id={`${optionId}-help`} place='right' className='w-52'>
              {option.helpText}
            </Tooltip>
            <TooltipTrigger
              as={Icons.AlertHelp}
              tooltipId={`${optionId}-help`}
              label={option.label}
            />
          </>
        )}
      </div>
    )
  })
}
