/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { Outlet, useLocation } from 'react-router'
import { ThemeProvider } from 'styled-components'

import Chatbot from './components/chatbot'
import FeatureFlagsModal from './components/feature-flags-modal'
import FeedbackModal from './components/feedback-modal'
import useKeyboardShortcuts from './components/keyboard-shortcuts'
import SystemError from './components/system-error'
import { TileTransitionProvider } from './components/tile-transition'
import { useDocumentTitle } from './components/use-document-title'
import VersionBar from './components/version-bar'
import client from './gql'
import theme from './theme'
import { AlertsProvider } from './ui/alerts'
import { ThemeProvider as DarkThemeProvider } from './ui/theme'

export default function RootLayout () {
  useKeyboardShortcuts()
  useDocumentTitle()
  return (
    <ApolloProvider client={window.testApolloClient || client}>
      <DarkThemeProvider>
        <AlertsProvider>
          <TileTransitionProvider>
            <ThemeProvider theme={theme}>
              <SystemError>
                <FeatureFlagsModal />
                <FeedbackModal />
                <LocationTracker />
                <VersionBar />
                <Outlet />
                <Chatbot />
              </SystemError>
            </ThemeProvider>
          </TileTransitionProvider>
        </AlertsProvider>
      </DarkThemeProvider>
    </ApolloProvider>
  )
}

function LocationTracker () {
  const location = useLocation()
  React.useEffect(() => {
    window.dispatchEvent(new CustomEvent('routechange'))
  }, [location])
}
