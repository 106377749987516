/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { capitalize } from 'lodash'
import React from 'react'
import { Link } from 'react-router'

import * as Icons from '../../../icons'
import ViewModal from '../table/view-modal'

export default function AssociationsView ({ value, details, condensed }) {
  const [showModal, setShowModal] = React.useState(false)
  const labelMap = createLabelMap(value)

  if (!value?.length) {
    return <NoLinkedDocuments />
  }

  if (condensed) {
    return (
      <div className='flex items-center justify-center'>
        {value?.length && (
          <button
            onClick={event => {
              event.stopPropagation(true)
              event.preventDefault()

              setShowModal(true)
            }}
            className='kp-button-transparent'
            aria-label={i18n._({
              id: 'view.linked.documents',
              message: 'Click to view linked documents'
            })}
          >
            {value.length} Items
          </button>
        )}
        <ViewModal
          setShowModal={setShowModal}
          showModal={showModal}
          title={details.label}
        >
          <GadgetView value={value} labelMap={labelMap} />
        </ViewModal>
      </div>
    )
  }

  return <GadgetView value={value} labelMap={labelMap} />
}

function GadgetView ({ labelMap }) {
  return (
    <div className='flex flex-col gap-2 pt-2'>
      {Object.keys(labelMap).map(label => (
        <LabelGroup
          key={label}
          label={label}
          linkedDocuments={labelMap[label]}
        />
      ))}
    </div>
  )
}

function LabelGroup ({ label, linkedDocuments }) {
  return (
    <div>
      {label !== '::root' && (
        <span className='pb-1 font-medium text-dark-gray-500'>{label}</span>
      )}
      <ul className='ml-6 list-disc'>
        {linkedDocuments.map(doc => {
          const url = getUrl(doc)
          return (
            <li className='text-text-link' key={doc.id}>
              <Link
                aria-label={doc.label}
                title={'Open ' + doc.label}
                to={url}
                target='_blank'
                className='hover:underline'
              >
                {doc.label}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

function NoLinkedDocuments () {
  return (
    <div className='flex h-8 w-full items-center justify-center gap-1 rounded bg-light-gray-100 text-dark-gray-100'>
      <Icons.Link className='h-4 w-4' />
      <Trans id='no.linked.documents' message='No linked documents' />
    </div>
  )
}

export function createLabelMap (data) {
  if (!data?.length) return {}
  return data?.reduce(
    (labelMap, doc) => {
      const ruleGroups = doc.ruleGroups?.filter(Boolean)

      if (!ruleGroups?.length) {
        labelMap['::root'].push(doc)
        return labelMap
      }

      ruleGroups?.forEach(ruleGroup => {
        const label = ruleGroup?.[0] ? capitalize(ruleGroup[0]) : '::root'
        labelMap[label] = labelMap[label] || []
        const exists = labelMap[label].find(d => d.id === doc.id)
        if (exists) return
        labelMap[label].push(doc)
      })

      return labelMap
    },
    { '::root': [] }
  )
}

function getUrl (part) {
  return part.appId && part.datasetId
    ? `/app/${part.appId}/page/${part.datasetId}/document-list/${part.id}/view`
    : `/document-list/${part.datasetId}/${part.id}/view`
}
