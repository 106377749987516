/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { RequiredConfig, getOptionalConfig } from './config'
import Edit from './edit'
import * as filters from './filters'
import { ReactComponent as Icon } from './icon.svg.jsx'
import * as progressiveDisclosure from './progressive-disclosure'
import { validateShape } from './validations'
import View from './view'

// TODO:: Need to migrate current data to add schoolId:
const fields = [
  {
    path: 'displayName',
    label: 'Display Name',
    type: 'Text',
    details: {}
  },
  { path: 'id', label: 'ID', type: 'Text', details: {} },
  {
    path: 'schoolId',
    label: 'School ID',
    type: 'Text',
    details: {}
  },
  { path: 'email', label: 'Email', type: 'Email', details: {} },
  {
    path: 'username',
    label: 'Username',
    type: 'Text',
    details: {}
  },
  { path: 'firstName', label: 'First Name', type: 'Text', details: {} },
  { path: 'lastName', label: 'Last Name', type: 'Text', details: {} }
]
const subFields = ({ id, formKey, label }) => {
  return fields.map(field => ({
    id: `${id}.${field.path}`,
    type: field.type,
    formKey: `${formKey}.${field.path}`,
    label: `${label} - ${field.label}`,
    subFieldLabel: field.label,
    details: field.details
  }))
}

export default {
  getAssembler: ({ Basic, GriddedText }, gridded) =>
    gridded ? GriddedText : Basic,
  RequiredConfig,
  OptionalConfig: getOptionalConfig(fields),
  Edit,
  View,

  sortSuffix: '.label',
  filters,
  progressiveDisclosure,
  validateShape,
  subFields,

  defaultValue: null,
  sampleValue: { id: '', label: '' },

  meta: { label: 'Data Lookup (Person)', hidden: true, Icon }
}
