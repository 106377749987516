/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { isArray, map } from 'lodash'
import React from 'react'

import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import * as Icons from '../../../icons'
import Button from '../../../ui/button'
import { InfoPage, InfoTitle, SimulateButtons, SimulateWrapper } from './index'

export default function SimulationError ({
  form,
  headless,
  simulateAction,
  simulationState,
  value
}) {
  const { errorMsg } = value
  const errorMsgs = isArray(errorMsg) ? errorMsg : [errorMsg]
  return (
    <SimulateWrapper
      headless={headless}
      buttons={
        <SimulateButtons>
          <Button
            disabled={simulationState.processing}
            id='back-btn'
            onClick={() => simulateAction('back', simulationState)}
            outline
          >
            <Trans id='back' />
          </Button>
          <Button
            disabled={simulationState.processing}
            id='default-btn'
            onClick={() => simulateAction('skip', simulationState)}
          >
            <Trans id='skip.this.step' />
          </Button>
        </SimulateButtons>
      }
      simulateAction={simulateAction}
      simulationState={simulationState}
    >
      <InfoPage justifyContent='center' flexDirection='column'>
        <Icons.AlertError className='size-16 fill-[#ef6c05]' />
        <InfoTitle>
          <Trans id='workflow.cannot.continue' />
        </InfoTitle>
        <div className='flex p-1 text-center'>
          <Tooltip className='w-72' id='errorInfo' place='top'>
            <div>
              <Trans id='can.still.publish.error.notifications' />
            </div>
          </Tooltip>
          <TooltipTrigger
            as={Icons.AlertHelp}
            label={i18n._('additional.information')}
            tooltipId='errorInfo'
          />
          <div className='pl-1'>
            <Trans id='workflow.will.stop.due.to.issues' />
          </div>
        </div>
        <ul>
          {map(errorMsgs, (msg, i) => (
            <li className='text-[#ef6c05]' key={`error-${i}`}>
              {msg}
            </li>
          ))}
        </ul>
      </InfoPage>
    </SimulateWrapper>
  )
}
