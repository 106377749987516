/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { get, map } from 'lodash'
import React from 'react'

import * as Icons from '../../../icons'
import Button from '../../../ui/button'
import * as Lookup from '../../../ui/lookup'

const categorySearchQuery = gql`
  query CategorySearch($query: String!) {
    categoriesConnection(args: { limit: 50, query: $query }) {
      edges {
        node {
          id
          label: name
        }
      }
    }
  }
`

const formatCategories = data => {
  if (!data) return []
  const { edges } = data.categoriesConnection
  return map(edges, ({ node: { id, label } }) => ({ id, label }))
}

const CategoryLookup = ({ onChange, id, ...props }) => {
  const [query, setQuery] = React.useState('')
  const { data } = useQuery(categorySearchQuery, { variables: { query } })
  const options = formatCategories(data)
  const aria = props['aria-labelledby']
  const lookup = Lookup.useLookup({
    id,
    options,
    onChange,
    query,
    setQuery,
    aria
  })
  return <Lookup.Control state={lookup} />
}

const categoryFetchQuery = gql`
  query GetCategory($id: ID!) {
    category(id: $id) {
      id
      label: name
    }
  }
`

const CategoryPill = ({ value, onRemove }) => {
  const opts = { variables: { id: value } }
  const { data, error, loading } = useQuery(categoryFetchQuery, opts)
  if (loading) return '...'
  return (
    <div>
      <Lookup.Chip>
        <Lookup.ChipLabel>
          {error || !get(data, 'category')
            ? 'Category Deleted'
            : get(data, 'category.label', '--')}
        </Lookup.ChipLabel>
        <Button transparent onClick={onRemove}>
          <Icons.Close width='8px' height='8px' />
        </Button>
      </Lookup.Chip>
    </div>
  )
}

export default ({ value, onChange, ...props }) => {
  return value ? (
    <CategoryPill value={value} onRemove={() => onChange(null)} />
  ) : (
    <CategoryLookup {...props} onChange={({ id }) => onChange(id)} />
  )
}
