/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { useParams } from 'react-router'

import BasicEdit from './basic-edit'
import LassoEdit from './lasso-edit'

export default function IntegrationEdit () {
  const { integrationId } = useParams()
  if (integrationId.startsWith('ADVANCED:')) return <LassoEdit />
  if (integrationId.startsWith('LASSO-')) return <LassoEdit />
  return <BasicEdit />
}
