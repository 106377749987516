/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { find, get, includes, isEmpty, last } from 'lodash'
import React from 'react'

import * as Icons from '../../icons'
import ConfigBox from '../../ui/config-box'
import * as ConsensusPicker from '../components/consensus-picker'
import * as CustomTitle from '../components/custom-title'
import * as Denial from '../components/denial'
import * as EmailBuilder from '../components/email-builder'
import * as PersonPicker from '../components/person-picker'
import * as ReminderSettings from '../components/reminder-settings'
import * as SectionSettings from '../components/section-settings'
import {
  SimulateButtons,
  SimulateHeader,
  SimulateWrapper
} from '../components/simulator-parts'
import ActionEmail from '../components/simulator-parts/action-email'
import SimulationContext from '../components/simulator-parts/context'
import SimulationError from '../components/simulator-parts/error-page'
import SimulationFormEditor from '../components/simulator-parts/form-editor'
import GroupResponse from '../components/simulator-parts/group-response-page'
import SendbackEmail from '../components/simulator-parts/sb-email'
import SendbackButton from '../components/simulator-parts/sendback-button'
import { Simulation } from '../engine/simulate'

const Node = ({ details, errors, Flowbot, fbProps }) => (
  <>
    <PersonPicker.View
      details={details}
      errors={errors}
      truncate={truncate(details)}
    />
    {details.customNotification?.enabled && (
      <EmailBuilder.View errors={errors} />
    )}
    {details.customSendbackNotification?.enabled && (
      <EmailBuilder.View errors={errors} prefix='sendback-' />
    )}
    {(details.ifDenied.enabled ?? !fbProps.workflowSettings?.disableDeny) && (
      <>
        <div className='w-full border-b border-light-gray-400 dark:border-light-gray-200' />
        <Denial.View details={details}>
          <Flowbot
            parentId={`${details.clientId}::0`}
            a11yLabel={`in the denial flow for ${
              details.stepName || 'Approval'
            }`}
            nodes={details.subflows[0].steps}
            {...fbProps}
          />
        </Denial.View>
      </>
    )}
  </>
)

const Config = ({
  appId,
  value,
  updateValue,
  errors,
  fieldsMultiUsers,
  fieldsUsers,
  fieldsGroups,
  fieldsAll,
  formSections,
  workflowSettings
}) => {
  const updateNotification = fn => {
    updateValue(draft => {
      const customNotification = draft.customNotification || {}
      fn(customNotification)
      draft.customNotification = customNotification
    })
  }
  const updateSendbackNotification = fn => {
    updateValue(draft => {
      const customSendbackNotification = draft.customSendbackNotification || {}
      fn(customSendbackNotification)
      draft.customSendbackNotification = customSendbackNotification
    })
  }
  const denyEnabled = value.ifDenied?.enabled ?? !workflowSettings.disableDeny
  const sendbackEnabled = !(
    value.disableSendback ?? workflowSettings.disableSendback
  )
  const historyEnabled = !(
    value.disableDocumentHistory ?? workflowSettings.disableDocumentHistory
  )
  return (
    <>
      <CustomTitle.Config
        value={value}
        updateValue={updateValue}
        defaultName='Approval'
        label={i18n._('step.label')}
      />
      <div className='w-full border-b border-light-gray-300 pb-4 dark:border-light-gray-400' />
      <PersonPicker.Config
        value={value}
        updateValue={updateValue}
        errors={errors}
        fieldsMultiUsers={fieldsMultiUsers}
        fieldsUsers={fieldsUsers}
        fieldsGroups={fieldsGroups}
        title={i18n._('who.will.approve.step')}
      />
      <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
      {(value.ifDenied.enabled ?? !workflowSettings?.disableDeny) && (
        <Denial.Config
          value={value}
          updateValue={updateValue}
          workflowSettings={workflowSettings}
        />
      )}
      {includes(
        ['formRole', 'globalRole', 'formMultiUser'],
        value.assignee?.type
      ) && (
        <ConsensusPicker.Config
          value={value}
          updateValue={updateValue}
          errors={errors}
        />
      )}
      <EmailBuilder.Config
        toggle
        value={value?.customNotification || {}}
        updateValue={updateNotification}
        errors={errors}
        fieldsAll={fieldsAll}
        defaultSubject={i18n._('pagesbuilder.workflow.step.subject.approval')}
      />
      <SectionSettings.Config
        appId={appId}
        defaultFormToViewOnly={workflowSettings.defaultFormToViewOnly}
        formSections={formSections}
        value={value}
        updateValue={updateValue}
      />
      <ReminderSettings.Config
        updateValue={updateValue}
        value={value}
        description={
          <div>
            <Trans id='enable.workflow.reminder.email.notifications' />
          </div>
        }
      />
      <ConfigBox
        label={
          workflowSettings.disableDeny
            ? i18n._('pagesbuilder.workflow.step.override.deny.label')
            : i18n._('pagesbuilder.workflow.step.disable.deny.label')
        }
        enabled={workflowSettings.disableDeny ? denyEnabled : !denyEnabled}
        save={() => () =>
          updateValue(draft => {
            draft.ifDenied.enabled = !(
              draft.ifDenied.enabled ?? !workflowSettings.disableDeny
            )
          })
        }
      />
      <ConfigBox
        label={
          workflowSettings.disableSendback
            ? i18n._('pagesbuilder.workflow.step.override.sendback.label')
            : i18n._('pagesbuilder.workflow.step.disable.sendback.label')
        }
        enabled={
          workflowSettings.disableSendback ? sendbackEnabled : !sendbackEnabled
        }
        save={() => () =>
          updateValue(draft => {
            draft.disableSendback = !(
              draft.disableSendback ?? workflowSettings.disableSendback
            )
          })
        }
      />
      {!workflowSettings.disableSendback && sendbackEnabled && (
        <EmailBuilder.Config
          toggle
          value={value?.customSendbackNotification || {}}
          updateValue={updateSendbackNotification}
          errors={errors}
          fieldsAll={[
            ...fieldsAll,
            {
              id: 'workflow.sendbackComment',
              formKey: 'workflow.sendbackComment',
              type: 'WorkflowComment',
              label: 'Sendback Comment'
            }
          ]}
          defaultSubject={i18n._('pagesbuilder.workflow.step.subject.sendback')}
          label={i18n._('customize.sendback.email.notification')}
          prefix='sendback-'
          helpText={
            <Trans id='setting.enables.custom.message.in.sendback.notification' />
          }
        />
      )}
      <ConfigBox
        label={
          workflowSettings.disableDocumentHistory
            ? i18n._('pagesbuilder.workflow.step.override.history.label')
            : i18n._('pagesbuilder.workflow.step.disable.history.label')
        }
        enabled={
          workflowSettings.disableDocumentHistory
            ? historyEnabled
            : !historyEnabled
        }
        save={() => () =>
          updateValue(draft => {
            draft.disableDocumentHistory = !(
              draft.disableDocumentHistory ??
              workflowSettings.disableDocumentHistory
            )
          })
        }
      />
    </>
  )
}

const Simulate = ({
  appName,
  branding,
  form,
  headless,
  imageCache,
  simulateAction,
  simulationState,
  updateImageCache,
  value,
  workflow,
  workflowSettings,
  ...rest
}) => {
  const { doc, handleChange, metaFields, template, usedFields, validations } =
    React.useContext(SimulationContext)
  const { assignees, status } = value
  const [currentUser, setCurrentUser] = React.useState(() => {
    if (assignees.length) return assignees[0]
  })
  const changeUser = newUser => setCurrentUser(newUser.user)
  const stepDefinition = find(workflow?.steps, { _id: value.stepDefinitionId })
  if (value.isSendback) {
    return (
      <SimulateWrapper
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <SimulateHeader
          selectedUser={currentUser}
          onCurrentUserChange={changeUser}
          userOptions={assignees}
        />
        <SendbackEmail
          appName={appName}
          branding={branding}
          currentUser={currentUser}
          customNotification={value.customNotification}
          imageCache={imageCache}
          onClick={() => simulateAction('next', simulationState)}
          simulationState={simulationState}
        />
      </SimulateWrapper>
    )
  } else if (status === 'Notified') {
    return (
      <SimulateWrapper
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <SimulateHeader
          selectedUser={currentUser}
          onCurrentUserChange={changeUser}
          userOptions={assignees}
        />
        <ActionEmail
          actionType='approval'
          appName={appName}
          branding={branding}
          currentUser={currentUser}
          customNotification={value.customNotification}
          imageCache={imageCache}
          stepName={value.stepName}
          onClick={() => simulateAction('next', simulationState)}
          simulationState={simulationState}
        />
      </SimulateWrapper>
    )
  } else if (status === 'In Denial') {
    const { denialFlow } = value
    const latestState = last(denialFlow.states)
    const subflowDefinition = find(stepDefinition.subflows, {
      _id: denialFlow.flowId
    })
    return (
      <Simulation
        {...rest}
        appName={appName}
        branding={branding}
        form={form}
        imageCache={imageCache}
        simulateAction={simulateAction}
        simulationState={simulationState}
        updateImageCache={updateImageCache}
        value={latestState}
        workflow={subflowDefinition}
        workflowSettings={workflowSettings}
      />
    )
  } else if (status === 'Error') {
    return (
      <SimulationError
        form={form}
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
        value={value}
      />
    )
  } else if (value.voting?.rule === 'percentage') {
    return (
      <GroupResponse
        formProps={{
          currentUser,
          doc,
          handleChange,
          imageCache,
          labelSize: form.labelSize,
          metaFields,
          template,
          updateImageCache,
          usedFields
        }}
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
        validations={validations}
        value={value}
      />
    )
  }
  const showDenyButton =
    stepDefinition?.ifDenied?.enabled ?? !workflowSettings?.disableDeny
  const showSendbackButton = !(
    stepDefinition?.disableSendback ?? workflowSettings?.disableSendback
  )
  const buttons = (
    <SimulateButtons>
      <div>
        <button
          className='kp-button-outline'
          disabled={simulationState.processing}
          id='back-btn'
          onClick={() => simulateAction('back', simulationState)}
        >
          <Trans id='back' />
        </button>
      </div>
      <div className='flex'>
        {showDenyButton && (
          <button
            className='kp-button-outline mr-2'
            id='deny-btn'
            disabled={!isEmpty(validations) || simulationState.processing}
            onClick={() =>
              simulateAction('deny', simulationState, {
                completedBy: currentUser
              })
            }
          >
            <Trans id='deny' />
          </button>
        )}
        {showSendbackButton && (
          <SendbackButton
            currentUser={currentUser}
            simulateAction={simulateAction}
            simulationState={simulationState}
            validations={validations}
            value={value}
          />
        )}
        <button
          className='kp-button-solid'
          id='default-btn'
          disabled={!isEmpty(validations) || simulationState.processing}
          onClick={() =>
            simulateAction('approve', simulationState, {
              completedBy: currentUser
            })
          }
        >
          <Trans id='approve' />
        </button>
      </div>
    </SimulateButtons>
  )
  return (
    <SimulateWrapper
      buttons={buttons}
      headless={headless}
      simulateAction={simulateAction}
      simulationState={simulationState}
    >
      <SimulateHeader
        selectedUser={currentUser}
        onCurrentUserChange={changeUser}
        userOptions={assignees}
      />
      <SimulationFormEditor
        currentUser={currentUser}
        doc={doc}
        handleChange={handleChange}
        imageCache={imageCache}
        labelSize={form.labelSize}
        metaFields={metaFields}
        template={template}
        updateImageCache={updateImageCache}
        usedFields={usedFields}
        validations={validations}
      />
    </SimulateWrapper>
  )
}

const truncate = value => get(value, 'ifDenied.action') === 'custom_steps'

export default {
  name: 'Approval',
  Icon: Icons.CheckCircle,
  color: '#49AA30',
  truncate,
  validate: (value, fieldsAll, lineage) => {
    let validations = [
      ...PersonPicker.validate(value, fieldsAll),
      ...ConsensusPicker.validate(value)
    ]
    if (value?.customNotification?.enabled) {
      const more = EmailBuilder.validate(
        value?.customNotification,
        fieldsAll,
        lineage
      )
      validations = validations.concat(more)
    }
    if (value?.customSendbackNotification?.enabled) {
      const more = EmailBuilder.validate(
        value?.customSendbackNotification,
        [
          ...fieldsAll,
          {
            id: 'workflow.sendbackComment',
            formKey: 'workflow.sendbackComment',
            type: 'WorkflowComment',
            label: 'Sendback Comment'
          }
        ],
        lineage,
        'sendback-',
        'Send back '
      )
      validations = validations.concat(more)
    }
    return validations
  },
  defaultTemplate: () => ({
    subflows: [{ steps: [] }],
    ...PersonPicker.defaults(),
    ...ConsensusPicker.defaults(),
    ...Denial.defaults(),
    customNotification: { ...EmailBuilder.defaults(), enabled: false },
    customSendbackNotification: { ...EmailBuilder.defaults(), enabled: false }
  }),
  canHaveEditPerms: true,
  Config,
  Node,
  Simulate
}
