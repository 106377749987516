/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'

import { useRefetchQueries } from './use-refetch-queries'

export const useSubmitDocumentMutation = query => {
  const [submitDocument] = useMutation(mutation)
  const refetchQueries = useRefetchQueries(['ActionPage'])
  return (docId, data, actionId, status, comment, disableUpdate) =>
    submitDocument({
      variables: {
        documentId: docId,
        data,
        actionId,
        status,
        comment,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      refetchQueries
    })
}

const mutation = gql`
  mutation submitDocumentV2(
    $documentId: ID!
    $data: JSON
    $actionId: ID!
    $status: String
    $comment: String
    $timeZone: String
  ) {
    submitDocumentV2(
      id: $documentId
      data: $data
      actionId: $actionId
      status: $status
      comment: $comment
      timeZone: $timeZone
    ) {
      id
      status
      query {
        viewer {
          id
          actionCount
        }
        myDrafts(args: { skip: 0, limit: 1 }) {
          totalCount
        }
      }
    }
  }
`
