/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

// THIS IS A GENERATED FILE. PLEASE DO NOT EDIT DIRECTLY.
// See https://docs.kualibuild.ninja/scripts/browser-check
// for instructions on how to re-generate this file.

const browserCheckRegEx =
  /Edge?\/13[23]\.\d+(\.\d+|)|Firefox\/(128|13[4-8])\.\d+(\.\d+|)|Chrom(ium|e)\/13[2-6]\.\d+(\.\d+|)|(Maci|X1{2}).+ Version\/18\.\d+([,.]\d+|)( \(\w+\)|)( Mobile\/\w+|) Safari\/|(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+18[._]\d+([._]\d+|)/
export default browserCheckRegEx
