/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'
import { useSearchParams } from 'react-router'

import * as Icons from '../icons'
import * as featureFlagExports from './feature-flags'
import CenterModal from './modal-centered'
import { Tooltip, TooltipTrigger } from './new-tooltip'
import { useLocalStorageState } from './use-local-storage-state'

const { flags, resolveState, isAfter, ...ff } = featureFlagExports

export default function FeatureFlagsModal () {
  const [overrides, setOverrides] = useLocalStorageState('feature-flags', {})
  const [searchParams, setSearchParams] = useSearchParams()
  const dismiss = () => setSearchParams(prev => prev.delete('center-modal'))
  const showing = searchParams.get('center-modal') === 'feature-flags'
  const flagsMatch = checkIfFlagsMatch(overrides)
  return (
    <CenterModal
      dismiss={dismiss}
      showing={showing}
      className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-5xl sm:p-6'
    >
      <div className='sm:flex sm:items-start'>
        <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
          <div className='flex items-center gap-4'>
            <h3
              className='text-gray-900 text-base font-semibold leading-6'
              id='modal-title'
            >
              <Trans id='feature.flags' />
            </h3>
            <div className='flex-1' />
            {!flagsMatch && (
              <button
                className='kp-button-outline kp-button-sm'
                onClick={() => window.location.reload()}
              >
                <Trans id='refresh.required' />
              </button>
            )}
            <button
              className='kp-button-transparent kp-button-icon gap-2 text-medium-gray-500'
              onClick={dismiss}
            >
              <span className='sr-only'>
                <Trans id='close' />
              </span>
              <span aria-hidden className='text-medium-gray-100'>
                (esc)
              </span>
              <Icons.Close />
            </button>
          </div>
          <p className='mb-4 mt-2 text-sm text-medium-gray-500'>
            <Trans id='use.these.toggles.specific.features.on.user' />
          </p>
          <div className='grid h-full max-h-[60vh] gap-2 overflow-auto md:grid-cols-2'>
            {flags.map(flag => (
              <Flag
                key={flag.id}
                flag={flag}
                enabled={resolveState(flag, overrides)}
                overridden={flag.id in overrides && !isFrozen(flag)}
                onToggle={() => {
                  setOverrides(overrides => {
                    const enabled = resolveState(flag, overrides)
                    return { ...overrides, [flag.id]: !enabled }
                  })
                }}
                onRevert={() => {
                  setOverrides(overrides => {
                    const newOverrides = { ...overrides }
                    delete newOverrides[flag.id]
                    return newOverrides
                  })
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </CenterModal>
  )
}

function Flag ({ flag, enabled, overridden, onToggle, onRevert }) {
  return (
    <div
      className={cx('flex items-center justify-between rounded-xl p-4', {
        'bg-light-gray-100 dark:bg-light-gray-500': flag.status === 'disabled',
        'bg-wintergreen-100 dark:bg-light-gray-500': flag.status === 'enabled',
        'bg-blue-000 dark:bg-light-gray-300': overridden
      })}
    >
      <span className='flex flex-grow flex-col'>
        <span
          className='text-sm font-medium leading-6 text-dark-gray-300'
          id={`toggle-${flag.id}-label`}
        >
          {flag.title}
          {flag.status === 'alpha' && (
            <span className='ml-4 inline-flex items-center rounded-md bg-wintergreen-100 px-2 py-1 text-xs font-medium text-wintergreen-500 ring-1 ring-inset ring-wintergreen-500/20'>
              Alpha
            </span>
          )}
          {!!flag.deprecationReason && (
            <TooltipTrigger
              id={`flag-${flag.id}-deprecated`}
              className='relative inline-block'
            >
              <span className='ring-medium-gray-500/20 ml-4 inline-flex items-center rounded-md bg-light-gray-300 px-2 py-1 text-xs font-medium text-medium-gray-500 ring-1 ring-inset'>
                Deprecated
              </span>
              <Tooltip position='bottom' className='w-80 text-center'>
                {flag.deprecationReason}
              </Tooltip>
            </TooltipTrigger>
          )}
        </span>
        <span
          className='text-sm text-medium-gray-500'
          id={`toggle-${flag.id}-description`}
        >
          {flag.description}
        </span>
        {!!flag.enabledIfAfter && (
          <span className='text-sm font-medium text-black'>
            * Permanently enabled on {flag.enabledIfAfter.toLocaleDateString()}
          </span>
        )}
        {flag.server && (
          <span className='text-sm font-medium text-black'>
            <Trans id='requires.flag.migration.to.function' />
          </span>
        )}
      </span>
      {overridden && (
        <button
          className='kp-button-transparent kp-button-icon ml-4'
          onClick={onRevert}
        >
          <Icons.Undo />
        </button>
      )}
      <button
        type='button'
        onClick={onToggle}
        disabled={isFrozen(flag)}
        className={cx(
          'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-wintergreen-400 focus:ring-offset-2 disabled:cursor-not-allowed',
          {
            'bg-wintergreen-400': enabled,
            'bg-light-gray-400 dark:bg-dark-gray-300': !enabled
          }
        )}
        role='switch'
        aria-checked={enabled}
        aria-labelledby={`toggle-${flag.id}-label`}
        aria-describedby={`toggle-${flag.id}-description`}
      >
        <span
          aria-hidden='true'
          className={cx(
            'pointer-events-none inline-flex h-5 w-5 transform items-center justify-center rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out dark:bg-black',
            { 'translate-x-5': enabled, 'translate-x-0': !enabled }
          )}
        >
          {isFrozen(flag) && <Icons.Lock className='size-3' />}
        </span>
      </button>
    </div>
  )
}

const isFrozen = flag =>
  ['enabled', 'disabled'].includes(flag.status) || isAfter(flag.enabledIfAfter)

function checkIfFlagsMatch (overrides) {
  for (const flag of flags) {
    if (ff[flag.id] !== resolveState(flag, overrides)) return false
  }
  return true
}
