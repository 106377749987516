/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { get, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { multipleLanguages } from '../../../components/feature-flags'
import Calculation from '../../../pages-builder/calculations/calculation-components'
import {
  extractCurrencyConfig,
  toDollars
} from '../../../pages-builder/calculations/calculation-functions'
import Input from '../../../ui/input'
import { useFormbotData } from '../../engine/formbot-react/hooks'
import {
  handleCurrencyInput,
  handleCurrencyInputOld,
  handleCurrencyValue
} from './utils'

export default function CurrencyEdit (props) {
  const formbotData = useFormbotData()

  const { context, details, formKey, gridded, id, onChange, value } = props
  const placeholder =
    details?.placeholder?.enabled && (details?.placeholder?.value ?? '')

  const isCalculation = details?.calculation?.enabled
  const Component = gridded ? MyInput : Input
  const validations = get(context, ['validations', formKey], [])
  return (
    <>
      {isCalculation ? (
        <Calculation
          gridded={gridded}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          formbotData={formbotData}
          details={details}
          {...props}
          type='Currency'
        />
      ) : (
        <Component
          aria-labelledby={props['aria-labelledby']}
          {...(props['aria-describedby'] && {
            'aria-describedby': props['aria-describedby']
          })}
          aria-required={props.required}
          id={id}
          onChange={rawVal => {
            if (multipleLanguages) {
              const { decimals } = extractCurrencyConfig(details)
              const results = handleCurrencyInput(rawVal, decimals)
              return results !== undefined && onChange(results)
            } else {
              const results = handleCurrencyInputOld(rawVal, value)
              return results !== undefined && onChange(results)
            }
          }}
          placeholder={placeholder}
          value={
            multipleLanguages
              ? handleCurrencyValue(value, details)
              : (toDollars(value) ?? '')
          }
        />
      )}
      {map(validations, message => (
        <Error className='text-red-500'>{message}</Error>
      ))}
    </>
  )
}

const MyInput = styled(Input)`
  border: none;
  outline: none;
  padding: 0 16px 16px 16px;
  background: none;
  width: 100%;
  text-align: right;
  html.dark & {
    // Outlier: dark:bg-light-gray-400
    background: #444;
  }
`

const Error = styled.div`
  padding-left: 16px;
  padding-bottom: 8px;
`
