/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { debounce, keyBy, set } from 'lodash'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Link, useLocation, useParams } from 'react-router'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import IconPicker from '../../components/icon-picker'
import { ConditionalTooltip } from '../../components/tooltip'
import * as Icons from '../../icons'
import Button from '../../ui/button'
import Input from '../../ui/input'
import { Flex, Wrapper } from '../../ui/layout'
import { Popover2 } from '../../ui/popover'
import { Body1 } from '../../ui/typography'
import iconNames from './icon-names'
import { useAddPageMutation } from './mutation.add-page'
import { useRemovePageMutation } from './mutation.remove-page'
import { useReorderPageMutation } from './mutation.reorder-page'
import { useUpdatePageMutation } from './mutation.update-page'
import pageTypes from './page-types'

const pageTypesMap = keyBy(pageTypes, 'id')

export default function Sidebar ({
  configMode,
  isTable,
  pages,
  sidebarActive
}) {
  const Component = configMode ? SidebarConfig : SidebarView
  return <Component isTable={isTable} pages={pages} active={sidebarActive} />
}

function SidebarView ({ pages, active }) {
  const { pageId, tableId } = useParams()
  pages = pages.filter(p => p.type !== 'dataset' || p.details.formVersionId)
  return (
    <SidebarWrapper
      className='bg-[#f7f7f7] dark:bg-light-gray-300'
      active={active}
    >
      {pages.map(page => (
        <SidebarItem
          key={page.id}
          data-active={[pageId, tableId].includes(page.id)}
          page={page}
        >
          {page.icon && <PageIcon src={page.icon.value} />}
          <span>{page.label.value}</span>
        </SidebarItem>
      ))}
    </SidebarWrapper>
  )
}

function SidebarConfig ({ isTable, pages: initialPages, active }) {
  const { appId, pageId, tableId } = useParams()
  const [pages, updatePages] = useImmer(initialPages)
  React.useEffect(() => {
    updatePages(() => initialPages)
  }, [initialPages.length])
  const addPage = useAddPageMutation(appId, isTable)
  const reorderPage = useReorderPageMutation(appId)
  return (
    <DragDropContext
      onDragEnd={result => {
        if (!result.destination) return
        const { id } = pages[result.source.index]
        updatePages(pages => {
          const i = pages.findIndex(page => page.id === id)
          const [page] = pages.splice(i, 1)
          pages.splice(result.destination.index, 0, page)
        })
        reorderPage(id, result.destination.index)
      }}
    >
      <Droppable droppableId='sidemenu'>
        {(provided, snapshot) => (
          <SidebarWrapper
            className='bg-[#f7f7f7] dark:bg-light-gray-300'
            active={active}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {pages.map((page, i) => (
              <Draggable key={page.id} draggableId={page.id} index={i}>
                {(provided, snapshot) => (
                  <SidebarItemWrapper
                    data-page-type={page.type}
                    data-active={[pageId, tableId].includes(page.id)}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <div {...provided.dragHandleProps}>
                      <DragIcon />
                      <SidebarItem page={page}>
                        {page.icon && <PageIcon src={page.icon.value} />}
                        <span>{page.label.value}</span>
                      </SidebarItem>
                    </div>
                    <EditPopover
                      role='menu'
                      trigger={
                        <MyButton transparent small icon>
                          <Icons.Edit />
                        </MyButton>
                      }
                    >
                      {() => (
                        <SidebarEditMenu
                          appId={appId}
                          value={page}
                          updateValue={update => {
                            updatePages(draft => {
                              update(draft[i])
                            })
                          }}
                          isActive={[pageId, tableId].includes(page.id)}
                        />
                      )}
                    </EditPopover>
                  </SidebarItemWrapper>
                )}
              </Draggable>
            ))}
            {provided.placeholder}

            <Popover2
              role='menu'
              trigger={
                <Button transparent ml={3} mt={3} mb={4}>
                  <Icons.Add className='fill-blue-500' mr={2} />
                  <span>
                    <Trans id='new.menu.item' />
                  </span>
                </Button>
              }
            >
              {hide => (
                <Menu>
                  {pageTypes.map(type => (
                    <MenuItem
                      key={type.id}
                      onClick={() => {
                        addPage(type.id)
                        hide()
                      }}
                    >
                      {type.label}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </Popover2>
          </SidebarWrapper>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function SidebarEditMenu ({ appId, value, updateValue, isActive }) {
  const removePage = useRemovePageMutation(appId, value.id)
  const updatePage = useUpdatePageMutation(appId, value.id)
  const debouncedUpdatePage = React.useMemo(
    () => debounce(updatePage, 300),
    [updatePage]
  )
  const update = (key, val) => {
    updateValue(draft => {
      set(draft, key, val)
    })
    const obj = {}
    set(obj, key, val)
    debouncedUpdatePage(obj)
  }
  const Type = pageTypesMap[value.type]
  return (
    <Wrapper pb={2} className='text-sm'>
      <EditMenuTitle className='bg-[#f7f7f7] dark:bg-light-gray-300'>
        <span>
          <Trans id='type.settings' values={{ type: Type.label }} />
        </span>
        {Type.helpLink && (
          <Button
            icon
            small
            transparent
            as='a'
            href={Type.helpLink}
            target='_blank'
            rel='noreferrer noopener'
          >
            <Icons.AlertHelp />
          </Button>
        )}
      </EditMenuTitle>
      <EditMenuBlock>
        <label>
          <Trans id='label' />
        </label>
        <Flex>
          <Input
            value={value.label.value}
            onChange={label => update('label.value', label)}
          />
          <Button
            transparent
            small
            icon
            onClick={() => update('label.locked', !value.label.locked)}
          >
            {value.label.locked ? (
              <Icons.Lock fill='#C83397' />
            ) : (
              <Icons.Unlock />
            )}
          </Button>
        </Flex>
      </EditMenuBlock>
      {value.type !== 'heading' && (
        <EditMenuBlock>
          <Flex>
            <label>
              <Trans id='icon' />
            </label>
            <Button
              transparent
              small
              icon
              onClick={() => update('icon.locked', !value.icon.locked)}
            >
              {value.icon.locked ? (
                <Icons.Lock fill='#C83397' />
              ) : (
                <Icons.Unlock />
              )}
            </Button>
          </Flex>
          <IconPicker
            icons={iconNames}
            value={value.icon.value}
            onChange={name => update('icon.value', name)}
            getSrc={name =>
              `${process.env.PUBLIC_URL}/icons-dataset/${name}.svg`
            }
          />
        </EditMenuBlock>
      )}
      <Type.Config
        value={value.details}
        onChange={details => update('details', details)}
      />
      <EditMenuBlock>
        <Flex>
          <div>
            <Trans id='sidebar.order' />
          </div>
          <Button
            transparent
            small
            icon
            onClick={() => update('orderLocked', !value.orderLocked)}
          >
            {value.orderLocked ? (
              <Icons.Lock fill='#C83397' />
            ) : (
              <Icons.Unlock />
            )}
          </Button>
        </Flex>
      </EditMenuBlock>
      <Flex mx={3} mt={2}>
        <ConditionalTooltip
          active={isActive}
          id='remove-page-tooltip'
          label={i18n._('why.remove.page.button.disabled')}
          value='Cannot remove the currently active dataset'
        >
          <Button width='100%' outline disabled={isActive} onClick={removePage}>
            <Icons.Delete mr={2} />
            <Trans id='remove.page' />
          </Button>
        </ConditionalTooltip>
        <Button
          transparent
          small
          icon
          onClick={() => update('removeLocked', !value.removeLocked)}
        >
          {value.removeLocked ? (
            <Icons.Lock fill='#C83397' />
          ) : (
            <Icons.Unlock />
          )}
        </Button>
      </Flex>
    </Wrapper>
  )
}

const SidebarItem = ({ page, ...props }) => {
  const { appId, pageId, tableId } = useParams()
  const location = useLocation()
  const path = location.pathname
    .replace(appId, ':appId')
    .replace(pageId, ':pageId')
    .replace(tableId, ':tableId')
  const isTable = !!tableId
  const to = path
    .replace(':appId', appId)
    .replace(':pageId', isTable ? pageId : page.id)
    .replace(':tableId', page.id)
  const [type, url] = pageTypesMap[page.type].getUrl(page, to)
  if (type === 'INTERNAL') return <SidebarLink {...props} as={Link} to={url} />
  if (type === 'NONE') return <Heading as='div' {...props} />
  return (
    <SidebarLink
      {...props}
      href={url}
      target='_blank'
      rel='noreferrer noopener'
    />
  )
}

const SidebarWrapper = styled.div`
  width: 240px;
  @media (max-width: 500px) {
    top: 0;
    height: 100%;
    position: fixed;
    padding: 16px 16px 0 0;
    z-index: 12;
    left: ${p => (p.active ? 0 : '-240px')};
    transition: left 0.4s ease-in-out;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  }
`

const Menu = styled.div`
  padding: 8px 0;
`

const EditPopover = styled(Popover2)`
  position: absolute;
  top: 8px;
  right: 12px;
  [data-page-type='heading'] & {
    top: -1px;
  }
`

const EditMenuTitle = styled(Flex)`
  justify-content: space-between;
  padding: 8px 16px;
`

const EditMenuBlock = styled.div`
  padding: 8px 16px 0;
`

const MenuItem = styled(Body1).attrs({ transparent: true })`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0;
  width: 100%;
  padding: 8px 16px;
  white-space: nowrap;
  &:hover {
    background: #eee;
    html.dark & {
      // Outlier: dark:hover:bg-light-gray-300
      background: #333;
    }
  }
`
MenuItem.defaultProps = { as: Button }

const SidebarItemWrapper = styled.div`
  position: relative;
`

const SidebarLink = styled.a`
  position: relative;
  color: var(--black);
  text-decoration: none;
  height: 40px;
  padding: 0 12px;
  margin-left: 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: background 300ms;
  &:hover,
  ${SidebarItemWrapper}:hover & {
    background: #ddd;
    html.dark & {
      // (I'm not sure if this is how it's done, just trying to get the point across)
      // Outlier: dark:hover:<SidebarItemWrapper>bg-light-gray-200
      background: #222;
    }
  }
  &[data-active='true'],
  ${SidebarItemWrapper}[data-active='true'] & {
    // (I'm not sure if this is how it's done, just trying to get the point across)
    // Outlier: dark:[data-active='true']:<SidebarItemWrapper>bg-light-gray-200
    background: #fff;
    html.dark & {
      background: #222;
    }
    font-weight: 500;
  }
  & span {
    white-space: nowrap;
    overflow: hidden;
  }
  & img {
    margin-right: 8px;
  }
`

const Heading = styled(SidebarLink)`
  margin-left: 8px;
  height: auto;
  font-weight: bolder;
  color: #666;
  background: initial !important;
  & img {
    display: none;
  }
`

function PageIcon ({ src }) {
  if (!src?.includes('http')) {
    src = `${process.env.PUBLIC_URL}/icons-dataset/${src}.svg`
  }
  return <img src={src} className='h-4 w-4 dark:invert' />
}

const DragIcon = styled(Icons.Drag)`
  position: absolute;
  top: 12px;
  left: 5px;
  cursor: grab;
  fill: #aaa;
  opacity: 0;
  transition: opacity 300ms;
  ${SidebarItemWrapper}:hover & {
    opacity: 1;
  }
  [data-page-type='heading'] & {
    top: 2px;
  }
`

const MyButton = styled(Button)`
  opacity: 0;
  transition: opacity 300ms;
  &:focus,
  ${SidebarItemWrapper}:hover & {
    opacity: 1;
  }
`
