/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { useApolloClient } from '@apollo/client'
import { sample } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router'
import shortid from 'shortid'

import * as Icons from '../icons'
import { useUpdateFormMutation } from '../pages-builder/form/mutation.update-form'
import { useCreateAppTileMutation } from '../pages/home/components/mutation.create-app-tile'
import * as tileOptions from '../pages/home/components/tile-options'
import AbbreviationIcon from './abbreviation-icon'
import { kualiChatbot } from './feature-flags'
import Spinner from './spinner'
import { useAppIds } from './use-app-ids-context'
import { useLocalStorageState } from './use-local-storage-state'

const defaultSystemPrompt = `You are a chat window in our product. Our product is super fun and your answers should be full of whimsy and delight.

There are three basic functions you support:
Moving tabs
Creating forms
Create app

1. Moving tabs

Trigger: User says something about moving or switching screens or tabs.

The tabs that can moved to are the following:
dashboard
documents
form
workflow
publish

If the user asks to move to a tab, return exactly the following code block to command the UI to navigate to the new tab:

___START
{
  "command": "navigate:[tab-name]"
}
___END

2. Creating forms within current apps

Users may also ask you to design a form for them. Forms are a recursive structure made up of gadgets. Here is the typescript signature of a gadget:

type Gadget = {
  id: string;
  type: 'Column' | 'Row' | 'Section' | 'Text' | 'Textarea' | 'Dropdown' | 'UserTypeahead' | 'Email' | 'Url';
  description?: {
    enabled?: boolean;
    value?: string;
    displayAsPopover?: boolean;
  };
  required?: boolean;
  details?: Record<string, any>;
  validations?: Record<string, any>;
  customName?: {
    enabled: boolean;
    value?: string;
  };
  customFormKey?: {
    enabled: boolean;
    value?: string;
  };
  conditionalVisibility?: {
    enabled: boolean;
    value?: {
      type: 'any' | 'all';
      parts: Array<{
        formKey: string;
        data: Record<string, any>;
      }>;
    };
  };
};

type DataGadget = Gadget & {
  label: string;
  formKey: string;
  childrenTemplate?: GadgetSchema[];
};

type LayoutGadget = Gadget & {
  label?: string;
  hideLabel?: boolean;
  children?: GadgetSchema[];
};

type GadgetSchema = DataGadget | LayoutGadget;

Gadget types are as follows:

            - Text: "Single-line text input for short textual data."
            - Textarea: "Multi-line text input for longer textual data."
            - RichText: "Text input with rich formatting options (e.g., bold, italic)."
            - Number: "Input field for numerical values."
            - Currency: "Input field for monetary values with currency formatting."
            - Email: "Input field for email addresses."
            - Url: "Input field for URLs or web links."
            - Dropdown: "Dropdown menu for selecting a single option from a list."
            - Radios: "Group of radio buttons for selecting one option from a list."
            - Checkboxes: "Group of checkboxes for selecting multiple options."
            - Date: "Input field for selecting dates."
            - TimePicker: "Input field for selecting times."
            - ImageUpload: "Upload gadget specifically for images."
            - FileUpload: "Upload gadget for files of various types."
            - CountryDropdown: "Dropdown menu for selecting a country."
            - LanguagesDropdown: "Dropdown menu for selecting a language."
            - StateDropdown: "Dropdown menu for selecting a state or province."
            - Signature: "Gadget for capturing user signatures digitally."
            - LinearScale: "Input field for selecting a value within a range (e.g., 1–5)."
            - Table: "Gadget for structured tabular data with rows and columns."
            - Repeater: "Field for repeating a group of fields multiple times."
            - DataFill: "Gadget for auto-filling a single item based on lookup."
            - DataLookup: "Field for selecting items from a data source in list form."
            - DataMultiselect: "Field for selecting multiple items from a data source."
            - UserTypeahead: "Searchable field for selecting a user."
            - CreatedBy: "Displays metadata about who created the document."
            - Timestamp: "Displays date and time information."
            - Duration: "Field for showing durations (e.g., workflow runtimes)."
            - WorkflowStatus: "Displays the status of a workflow."
            - WorkflowCurrentSteps: "Shows the time spent on the current workflow step."

Always return back a Column gadget, with at least 1 Section gadget inside. Within a Section you can have any combination of Rows, Columns, or other gadgets.
Important: Try to group relevant gadgets together and don't have more than three gadgets in any one row.

When asked for a form, make it follow this format but replace the example form with your own:
___START
{
  "command": "replace:form",
  "form": {
    "children": [
      {
        "children": [
          {
            "children": [
                {
                  "formKey": "ewVRNnuN4O",
                  "id": "xPLhgUTdX",
                  "label": "First Name",
                  "type": "Text"
                },
                {
                  "formKey": "RE5fMQRFD2",
                  "id": "AHEAZixp-",
                  "label": "Middle Name",
                  "type": "Text"
                },
                {
                  "formKey": "Kjpf_qTiMu",
                  "id": "4k8vrgfsM",
                  "label": "Last Name",
                  "type": "Text"
                }
            ],
            "id": "kPnbYmEjjl",
            "type": "Row"
          }
        ],
        "id": "Gna8gVK0O",
        "label": "Your Name",
        "type": "Section"
      },
      {
        "children": [
          {
            "children": [
              {
                "children": [
                    {
                      "details": {
                        "options": [
                          {
                            "defaultRadio": false,
                            "key": "V7TuxKHl4",
                            "lbl": "Lasagna"
                          },
                          {
                            "defaultRadio": false,
                            "key": "6TKCZV-wW",
                            "lbl": "Pizza"
                          },
                          {
                            "defaultRadio": false,
                            "key": "OHuwbFRGZ",
                            "lbl": "Other"
                          }
                        ]
                      },
                      "formKey": "N_DUiWUcxI",
                      "id": "VAmVZlKMf",
                      "label": "Favorite Food",
                      "type": "Dropdown"
                    },
                    {
                      "conditionalVisibility": {
                        "enabled": true,
                        "value": {
                          "parts": [
                            {
                              "data": {
                                "type": "isSelected",
                                "value": "OHuwbFRGZ"
                              },
                              "formKey": "data.N_DUiWUcxI"
                            }
                          ],
                          "type": "any"
                        }
                      },
                      "formKey": "1G2hCDrwxC",
                      "id": "CZvgNCGfn",
                      "label": "Other?",
                      "type": "Text"
                    }
                ],
                "id": "SZds4TtSfs",
                "type": "Column"
              },
              {
                "formKey": "D4PLLgwPZ-",
                "id": "-Xc0v8o5v",
                "label": "Favorite Color",
                "type": "Text"
              },
              {
                "formKey": "aA02D-JiFm",
                "id": "S9eqLCAeHZ",
                "label": "Other Details",
                "type": "Text"
              }
            ],
            "id": "dqY7TCEhbI",
            "type": "Row"
          }
        ],
        "details": {
          "officeUseOnly": false
        },
        "id": "tl8Lh3mt7",
        "label": "Other Details",
        "type": "Section"
      }
    ],
    "id": "GlCWpPYCAS",
    "type": "Column"
  }
}
___END

3. Creating a new app

Use the same structure for creating a form, but with the following changes:

___START
{
  "command": "create:app",
  "name": "app-name",
  "form": {


# the rest is the same`

const iconSystemPrompt = `I passed you the name of an app. Match that name with one of the following icons. Pick the icon that best represents the app name. Pick a random icon from the list if you can't find a match.

ab-testing-chemistry-monitor.svg
accessories-umbrella.svg
accounting-calculator.svg
alarm-bell-ring.svg
alert-triangle.svg
ambulance-car.svg
american-football-ball-1.svg
amusement-park-castle.svg
analytics-pie-2.svg
animal-print.svg
app-window-check.svg
app-window.svg
archive-books.svg
archive-drawer-table.svg
archive.svg
armchair-1.svg
army-badge-skull.svg
arrow-thick-circle-right-1.svg
astronomy-planet-ring-star.svg
atomic-bomb.svg
attachment.svg
award-medal-1.svg
bandage.svg
basketball-ball.svg
beach-palm-water.svg
beach-parasol-water.svg
bicycle.svg
bin.svg
binocular.svg
board-game-deuce.svg
bomb-detonator.svg
book-book-pages.svg
book-close-bookmark-1.svg
book-library-shelf.svg
bookmarks-document.svg
brain-head-1.svg
briefcase.svg
browser-page-mail.svg
building-cloudy.svg
building-house.svg
bus-station.svg
business-big-small-fish.svg
business-climb-top.svg
business-crossroad.svg
business-deal-cash-1.svg
business-lucky-cat.svg
business-shark.svg
business-team-goal.svg
calendar-2.svg
calendar-3.svg
calendar-school.svg
camera-1.svg
candy.svg
car-1.svg
cash-briefcase.svg
cash-payment-bag.svg
casino-player-dice.svg
certified-diploma-1.svg
certified-diploma.svg
chess-rook.svg
christmas-snowman.svg
clean-car-gas.svg
cloud-upload.svg
cog.svg
color-rolling-brush.svg
composition-window-man-1.svg
computer-bug-1.svg
content-paper-edit.svg
credit-card-1.svg
cupcake.svg
cursor-hand-2.svg
data-file-bars.svg
data-transfer-circle.svg
database-2.svg
database-flash.svg
delivery-truck-3.svg
design-drawing-board.svg
design-shape-monitor.svg
design-tool-magic-wand.svg
design-tool-magnet.svg
design-tool-stamp.svg
desktop-monitor-approve.svg
desktop-monitor-smiley-1.svg
dial-finger-1.svg
dislike.svg
dog-allowed.svg
dog-sit.svg
download-thick-box.svg
drawer-open.svg
dresser-drawers-1.svg
e-learning-laptop-1.svg
e-learning-monitor.svg
earth-model-2.svg
ecology-leaf.svg
explosive.svg
face-id-1.svg
family-child-play-ball-warning.svg
farming-barn-2.svg
fast-food-burger.svg
flag-1.svg
flag-skull.svg
flash-1.svg
folder-edit.svg
folder.svg
fruit-apple.svg
garbage-bin-throw.svg
gardening-lawn-mower.svg
gauge-dashboard.svg
gift-box.svg
glasses-ski-2.svg
gold-bars.svg
golf-hole.svg
graph-stats-circle.svg
halloween-cauldron.svg
hammer-wench.svg
harddrive-download.svg
hardware-nut.svg
headphones-human.svg
heavy-equipment-mortar-truck.svg
help-wheel.svg
hierarchy-5.svg
historical-building-castle-1.svg
hospital-first-aid.svg
hotel-double-bed-1.svg
house-3.svg
human-resources-search-employees.svg
ice-cream-bite.svg
image-file-light.svg
information-circle.svg
instrument-guitar.svg
job-seach-profile.svg
kindle-hold.svg
lab-tube-bottle.svg
lab-tube-experiment.svg
landmark-colosseum.svg
launch-go-flag.svg
legal-hammer.svg
light-mode-cloudy.svg
like.svg
lock-1.svg
login-keys.svg
love-it-bubble.svg
love-it.svg
love-shield.svg
love-whale.svg
mailbox-in.svg
maps-search.svg
matches-fire.svg
messages-bubble-double.svg
messages-bubble-square-text.svg
messages-bubble-square.svg
messages-bubble.svg
microphone-podcast-2.svg
mobile-phone.svg
module-three-2.svg
monetization-sponsor.svg
monetization-touch-coin.svg
money-wallet-1.svg
monitor-user.svg
mood-happy.svg
multiple-actions-check-1.svg
multiple-actions-flash.svg
music-note-2.svg
natural-disaster-fire.svg
natural-disaster-volcano-smoke.svg
network-browser.svg
notes-diary.svg
notes-text-flip.svg
office-desk.svg
office-outdoors.svg
official-building-2.svg
official-building-3.svg
official-building.svg
organic-plant-grow.svg
outdoors-flashlight-1.svg
outdoors-kite-flying.svg
outdoors-swiss-knife.svg
outdoors-tree-valley.svg
party-balloon.svg
password-desktop-lock-approved.svg
pencil-write.svg
people-man-graduate.svg
performance-increase.svg
phone-book.svg
pie-line-graph-desktop.svg
pin.svg
plane-take-off.svg
police-man-1.svg
police-rotating-light-1.svg
pollution-drop-skull.svg
power-tools-drill.svg
print-text.svg
programming-language-code.svg
protection-shield-bolt.svg
rating-star.svg
read-home-2.svg
read-human.svg
real-estate-action-house-wrench.svg
receipt-register.svg
receipt-slip-1.svg
recycling-sign.svg
religion-peace-1.svg
religion-taoism.svg
restaurant-fork-knife.svg
road-sign-u-turn-left.svg
road-straight.svg
road-traffic-lights.svg
room-service-bring-plate.svg
safety-electricity-danger.svg
safety-helmet-mine-1.svg
safety-warning-electricity.svg
saving-piggy-bank.svg
school-bell.svg
school-book-apple.svg
school-building.svg
search-1.svg
send-email-envelope.svg
send-email.svg
settings-slider-desktop-horizontal.svg
shield-home.svg
shield-monitor.svg
shipment-check.svg
shipment-in-transit.svg
shop-barista.svg
show-theater-masks.svg
single-neutral-actions-alarm.svg
single-neutral-actions-check-2.svg
single-neutral-actions-edit-1.svg
single-neutral-actions-flight.svg
single-neutral-book.svg
single-neutral-circle.svg
single-neutral-mail.svg
single-neutral-monitor.svg
skull.svg
smart-watch-square-graph-line.svg
soccer-field.svg
space-rocket-flying.svg
stairs-ascend.svg
study-owl.svg
surveillance-camera.svg
table-lamp-hanging.svg
target-center-1.svg
task-checklist-check.svg
task-checklist-write.svg
team-idea.svg
ticket-1.svg
ticket-movie.svg
time-clock-circle.svg
toilet-seat.svg
tools-box-1.svg
tools-box.svg
tools-pickaxe.svg
toys-lego.svg
trends-torch.svg
trip-road.svg
user-3d-box.svg
video-game-pacman.svg
vintage-tv-4.svg
vip-royal.svg
volume-control-medium.svg
watch-time.svg
water-protection-faucet.svg
water-straw.svg
weather-cloud.svg
yoga-arm-stretch.svg
zoom-in.svg

Return the icon name and only the icon name. Nothing else. Use the following format:

icon_name.svg`

export default function Chatbot () {
  if (!kualiChatbot) return null
  return <ChatbotInner />
}

function ChatbotInner () {
  const ref = React.useRef()
  const ids = useAppIds()
  const [loading, setLoading] = React.useState(false)
  const [newMessage, setNewMessage] = React.useState('')
  const [tasks, setTasks] = React.useState({})
  const [chats, setChats] = React.useState([])
  const [expanded, setExpanded] = React.useState(false)
  const [showPrompt, setShowPrompt] = React.useState(false)
  const [prompt, setPrompt] = useLocalStorageState(
    'chatbot:system-prompt',
    defaultSystemPrompt,
    { jsonify: false }
  )
  const processMessage = useProcessMessage(setTasks)

  const allChats = [...chats]
  if (newMessage) allChats.push({ id: 'new', from: 'kuali', text: newMessage })
  else if (loading) allChats.push({ id: 'loading', from: 'kuali', text: '' })

  return (
    <div
      id='chatbot-ai-assistant'
      className={`fixed bottom-6 left-6 z-50 max-w-full transition-all ${expanded ? 'w-96' : 'w-40'}`}
    >
      {expanded && showPrompt && (
        <div className='rounded-3xl bg-blue-300 px-4 py-2'>
          <label className='text-sm font-medium'>Raw Messages:</label>
          <textarea
            disabled
            className='kp-textarea w-full'
            value={allChats.map(a => a.text).join('\n\n')}
          />
          <label className='text-sm font-medium'>System Prompt:</label>
          <textarea
            className='kp-textarea w-full'
            placeholder='leave blank to use the default system message'
            onChange={e => setPrompt(e.target.value)}
            value={prompt}
          />
        </div>
      )}
      <div className='overflow-hidden rounded-3xl bg-dark-gray-300 text-xs shadow-2xl'>
        <button
          className={`flex w-full items-center gap-2 text-medium-gray-200 transition-all ${expanded ? 'p-6 pb-3' : 'p-2'}`}
          onClick={() => setExpanded(a => !a)}
        >
          <div
            className={
              !expanded &&
              'flex size-8 items-center justify-center rounded-full'
            }
            style={
              expanded
                ? {}
                : {
                    background:
                      'linear-gradient(132deg, #9C27B0 23.93%, #465BCB 111.68%)'
                  }
            }
          >
            <Icons.Stars
              className={expanded ? 'fill-medium-gray-400' : 'fill-white'}
            />
          </div>
          <span>Kuali Ai Assistant</span>
          <div className='flex-1' />
          {expanded && (
            <button
              className='rounded-full px-2 py-2 hover:bg-blue-300'
              onClick={e => {
                e.stopPropagation()
                setShowPrompt(a => !a)
              }}
            >
              <Icons.MenuVertical className='fill-white' />
            </button>
          )}
          {expanded && <Icons.Close className='size-3 fill-white' />}
        </button>
        <div
          className={`flex flex-col overflow-hidden transition-all ${expanded ? 'h-96' : 'h-0'}`}
        >
          <ul
            ref={ref}
            className='flex flex-1 flex-col gap-2 overflow-auto p-4'
          >
            {allChats.map(chat => (
              <li
                key={chat.id}
                className={`flex gap-3 p-2 text-white ${chat.from === 'you' ? 'ml-20 rounded-2xl bg-dark-gray-500' : ''}`}
              >
                {chat.from === 'kuali' && (
                  <div
                    className='flex size-6 items-center justify-center rounded-full'
                    style={{
                      background:
                        'linear-gradient(132deg, #9C27B0 23.93%, #465BCB 111.68%)'
                    }}
                  >
                    <Icons.Stars className='fill-white' />
                  </div>
                )}
                {chat.from === 'you' && (
                  <AbbreviationIcon
                    name={window.loggedInUser?.displayName}
                    className='self-start !rounded-full'
                  />
                )}
                <div
                  className={
                    'flex-1 whitespace-pre-wrap rounded-md [word-break:break-word] ' +
                    (chat.id === 'loading'
                      ? 'animate-pulse bg-medium-gray-200'
                      : '')
                  }
                >
                  <Message id={chat.id} text={chat.text} tasks={tasks} />
                </div>
              </li>
            ))}
          </ul>
          <div className='p-4'>
            <input
              type='text'
              className='w-full rounded-full bg-dark-gray-500 px-4 py-2 text-xs text-white'
              placeholder='Ask me anything...'
              disabled={loading}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setChats(chats =>
                    chats.concat({
                      id: chats.length,
                      from: 'you',
                      text: e.target.value
                    })
                  )
                  setLoading(true)
                  setTimeout(() => {
                    e.target.value = ''
                    ref.current.scrollTop = ref.current.scrollHeight
                  })

                  const token = getToken()
                  fetch('/app/api/v0/chatbot', {
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                      message: e.target.value,
                      prompt: prompt
                        .replace(/\{\{TOKEN\}\}/g, token)
                        .replace(/\{\{HOST\}\}/g, window.location.host)
                        .replace(/\{\{PATHNAME\}\}/g, window.location.pathname)
                        .replace(/\{\{URL\}\}/g, window.location.href)
                        .replace(/\{\{APP_ID\}\}/g, ids.appId || '')
                        .replace(/\{\{ACTION_ID\}\}/g, ids.actionId || '')
                        .replace(/\{\{DOCUMENT_ID\}\}/g, ids.documentId || '')
                    })
                  }).then(async response => {
                    const id = shortid.generate()
                    if (response.status !== 200) {
                      setChats(chats =>
                        chats.concat({
                          id,
                          from: 'kuali',
                          text: `Kuali AI Assistant is currently unavailable (status: ${response.status})`
                        })
                      )
                      setLoading(false)
                      return
                    }
                    let message = ''
                    const decoder = new TextDecoder('utf-8')
                    for await (const chunk of response.body) {
                      message += decoder.decode(chunk)
                      setNewMessage(message)
                      ref.current.scrollTop = ref.current.scrollHeight
                    }
                    setNewMessage('')
                    setLoading(false)
                    setChats(chats =>
                      chats.concat({ id, from: 'kuali', text: message })
                    )

                    processMessage(message, id)

                    setTimeout(() => {
                      ref.current.scrollTop = ref.current.scrollHeight
                      e.target.focus()
                    })
                  })
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Message ({ id, text, tasks }) {
  const [parsed, taskss] = parseMessage(text)
  taskss.forEach((task, i) => {
    task.i = i
  })
  return (
    <>
      {parsed.map((message, i) => {
        if (typeof message === 'string') {
          if (message.includes('___START')) {
            const [msg, tsk] = message.split('___START')
            const parsed = /"command": ?"(.*)"/.exec(tsk)
            const taskName = parsed ? parsed[1] : 'notFound'
            const title = taskDefs[taskName].title2 || taskDefs[taskName].title
            return (
              <React.Fragment key={i}>
                <div>{msg}</div>
                <ul className='my-6 rounded-full bg-dark-gray-400 p-2'>
                  <li className='flex items-center gap-2'>
                    <Spinner size={16} className='!border-2' />
                    {title}
                  </li>
                </ul>
              </React.Fragment>
            )
          }
          return <div key={i}>{message}</div>
        }

        return (
          <ul key={i} className='my-6 rounded-full bg-dark-gray-400 p-2'>
            {message.map((task, j) => {
              const state = tasks[`${id}_${task.i}`] ?? 'pending'
              const taskDef = taskDefs[task.command] || taskDefs.notFound
              return (
                <li key={j} className='flex items-center gap-2'>
                  {state === 'pending' && (
                    <Spinner size={16} className='!border-2' />
                  )}
                  {state === 'completed' && (
                    <Icons.Check className='size-4 fill-green-300' />
                  )}
                  {state === 'failed' && (
                    <Icons.WorkflowX className='size-4 fill-red-300' />
                  )}
                  {taskDef.title}
                </li>
              )
            })}
          </ul>
        )
      })}
    </>
  )
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

function useProcessMessage (setTasks) {
  const client = useApolloClient()
  const navigate = useNavigate()
  const [updateForm] = useUpdateFormMutation()
  const createApp = useCreateAppTileMutation()
  const ids = useAppIds()
  return async function processMessage (message, id) {
    const [, tasks] = parseMessage(message)
    for (const i in tasks) {
      await sleep(300)
      const task = tasks[i]
      const taskDef = taskDefs[task.command] || taskDefs.notFound
      const status = await taskDef.run(task, ids, {
        navigate,
        createApp,
        updateForm
      })
      setTasks(tasks => ({ ...tasks, [`${id}_${i}`]: status }))
    }
    if (tasks.length) {
      await client.refetchQueries({ include: 'active' })
      window.dispatchEvent(new CustomEvent('refresh-page-data'))
    }
  }
}

function parseMessage (message) {
  const res = /___START([.\s\S]*?)___END/.exec(message)
  if (!res) return [[message.trim()], []]
  let [matched, tasks] = res
  try {
    tasks = JSON.parse(tasks)
    if (!Array.isArray(tasks)) tasks = [tasks]
  } catch {
    tasks = [{ command: 'parse error' }]
  }
  const [first, last] = message.split(matched)
  const [msg1, tasks1] = parseMessage(first)
  const [msg2, tasks2] = parseMessage(last)
  return [
    [...msg1, tasks, ...msg2],
    [...tasks1, ...tasks, ...tasks2]
  ]
}

const taskDefs = {}

taskDefs.notFound = {
  title: 'Unrecognized Task',
  title2: 'New Task',
  run: async () => 'failed'
}

taskDefs['create:app'] = {
  title: 'Creating App',
  title2: 'Generating App',
  run: async (task, ids, { createApp, updateForm }) => {
    try {
      const iconName = await fetchIcon(task.name)
      const resp = await createApp(
        {
          name: task.name,
          backgroundColor: sample(tileOptions.colors),
          iconName,
          spaceId: ids.spaceId
        },
        'app'
      )
      await updateForm(resp.data.data.id, undefined, task.form)
      return 'completed'
    } catch (error) {
      console.error(error)
      return 'failed'
    }
  }
}

taskDefs['replace:form'] = {
  title: 'Updating Form',
  title2: 'Generating Form',
  run: async (task, ids, { updateForm }) => {
    try {
      await updateForm(ids.appId, undefined, task.form)
      return 'completed'
    } catch (error) {
      console.error(error)
      return 'failed'
    }
  }
}

taskDefs['navigate:dashboard'] = buildNavigateTask('Dashboard', 'dashboard')
taskDefs['navigate:documents'] = buildNavigateTask('Documents', 'document-list')
taskDefs['navigate:form'] = buildNavigateTask('Form', 'form')
taskDefs['navigate:workflow'] = buildNavigateTask('Workflow', 'workflow')
taskDefs['navigate:publish'] = buildNavigateTask('Publish', 'publish')

function buildNavigateTask (label, slug) {
  return {
    title: `Navigating to ${label}`,
    run: async (_, ids, { navigate }) => {
      await navigate(`/${slug}/${ids.appId}`)
      return 'completed'
    }
  }
}

function getToken () {
  // https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)authToken\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  )
}

async function fetchIcon (name) {
  const token = getToken()
  return fetch('/app/api/v0/chatbot', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({ message: name, prompt: iconSystemPrompt })
  }).then(res => res.text())
}
