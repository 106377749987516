/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { Link, useLocation, useParams } from 'react-router'

import AppMultiselect from '../../components/app-multiselect'
import AppsOrProducts from '../../components/apps-or-products'
import Loading from '../../components/loading'
import { ModalPage } from '../../components/modal-page'
import { GraphQLError as Error } from '../../components/system-error'
import { useQuery } from '../../components/use-query'
import Radios from '../../ui/radios'
import { useSetDataSharingMutation } from './components/mutation.set-data-sharing'
import { useSetConditionallyShared } from './components/mutation.set-shared-conditionally'

export default function Sharing () {
  return (
    <ModalPage title={i18n._('data.sharing')}>
      <SharingInner />
    </ModalPage>
  )
}

function SharingInner () {
  const { appId, pageId } = useParams()
  const query = getSharingPageQuery(appId)
  const { data, error, loading } = useQuery(query)
  const location = useLocation()
  const setDataSharing = useSetDataSharingMutation(appId)
  const setConditionalSharing = useSetConditionallyShared(appId, query)
  if (loading || !data) return <Loading />
  if (error) return <Error error={error} />
  const sharing = data.app.sharedWithOthers
  const conditionallyShared = sharing?.conditionallyShared ?? false
  return (
    <div className='flex flex-col items-center text-sm'>
      <div className='w-96 py-4'>
        <label className='relative block pb-1 font-medium'>
          <Trans
            id='pagesbuilder.sharing.with.others'
            values={{ appsOrProducts: <AppsOrProducts lowercase /> }}
          />
        </label>
        <Radios
          name='sharing-level'
          options={[
            {
              id: 'NONE',
              htmlId: 'none',
              label: `${i18n._('pagesbuilder.sharing.not.share')}`
            },
            {
              id: 'ALL',
              htmlId: 'allApps',
              ariaLabel: i18n._('pagesbuilder.sharing.share.all', {
                appsOrProducts: <AppsOrProducts lowercase />
              }),
              label: (
                <span>
                  <Trans
                    id='pagesbuilder.sharing.bolded'
                    components={{ bold: <b /> }}
                    values={{ appsOrProducts: <AppsOrProducts lowercase /> }}
                  />
                </span>
              )
            },
            {
              id: 'SPECIFIC',
              htmlId: 'specificApps',
              ariaLabel: i18n._('pagesbuilder.sharing.share.specific', {
                appsOrProducts: <AppsOrProducts lowercase />
              }),
              label: (
                <span>
                  <Trans
                    id='pagesbuilder.sharing.bolded.specific'
                    components={{ bold: <b /> }}
                    values={{ appsOrProducts: <AppsOrProducts lowercase /> }}
                  />
                </span>
              )
            }
          ]}
          value={sharing.type}
          onChange={type =>
            setDataSharing(type, sharing.apps, conditionallyShared)
          }
        />
        {sharing.type === 'SPECIFIC' && (
          <AppMultiselect
            className='mt-4'
            value={sharing.apps}
            onChange={apps =>
              setDataSharing(sharing.type, apps, conditionallyShared)
            }
          />
        )}
        {['SPECIFIC', 'ALL'].includes(sharing.type) && (
          <div className='flex gap-1 pt-2'>
            <input
              type='checkbox'
              className='kp-checkbox'
              checked={conditionallyShared}
              onChange={() => setConditionalSharing(!conditionallyShared)}
            />
            <label>
              <Trans
                id='pagesbuilder.restrict.access.with.link'
                components={{
                  link: (
                    <Link
                      className='text-text-link underline'
                      to={
                        pageId
                          ? `../../../app/${appId}/page/${pageId}/form/settings/conditional-permissions`
                          : `../../form/${appId}/settings/conditional-permissions`
                      }
                      state={{ back: location.pathname }}
                    />
                  )
                }}
              />
            </label>
          </div>
        )}
      </div>
    </div>
  )
}

const getSharingPageQuery = appId => ({
  variables: { appId },
  query: gql`
    query SharingPage($appId: ID!) {
      app(id: $appId) {
        id
        sharedWithOthers {
          type
          conditionallyShared
          apps {
            id
            name
            type
            tileOptions {
              backgroundColor
              iconName
            }
          }
        }
      }
    }
  `
})
