/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Button from '../../../ui/button'
import SimulatedEmail from './simulated-email'
import { addImagesToBody } from './utils'

export default function ActionEmail ({
  actionType,
  appName,
  branding,
  buttonText,
  currentUser,
  customNotification,
  defaultSubject,
  imageCache,
  instructions,
  onClick,
  simulationState,
  stepName
}) {
  const customBody = customNotification?.body
    ? addImagesToBody(customNotification.body, imageCache)
    : null
  const body = (
    <div className='my-10'>
      <p className='mb-3'>
        <Trans
          id='hello.name'
          values={{ name: currentUser.firstName || currentUser.displayName }}
        />
      </p>
      {customBody && (
        <div
          className='prose prose-sm prose-ol:list-inside'
          dangerouslySetInnerHTML={{ __html: customBody }}
        />
      )}
      {!customBody && (
        <p className='mb-3'>
          <Trans id='your.attention.required' />
        </p>
      )}
      <br />
      <p className='mb-3'>
        <Trans id='form.name.colon' />
        <b>{appName.toUpperCase()}</b>
      </p>
      <p className='mb-3'>
        <Trans id='step.name.colon' />
        <b>{stepName.toUpperCase()}</b>
      </p>
      <p className='mb-3'>
        <Trans id='your.action.colon' /> <b>{actionType.toUpperCase()}</b>
      </p>
      {instructions && (
        <p className='mb-3'>
          <Trans id='instructions.colon' />{' '}
          <span>
            <b>{instructions}</b>
          </span>
        </p>
      )}
      <p className='mb-3'>
        <ActionButton
          brandColor={branding.color}
          disabled={simulationState.processing}
          id='review-btn'
          onClick={onClick}
          mt={3}
        >
          {buttonText || <Trans id='begin.review' />}
        </ActionButton>
      </p>
    </div>
  )
  return (
    <SimulatedEmail
      branding={branding}
      toEmail={currentUser.email}
      subject={
        customNotification?.subject ||
        defaultSubject ||
        `Your ${actionType.toUpperCase()} is requested: ${appName} - ${stepName}`
      }
      body={body}
    />
  )
}

const ActionButton = styled(Button)`
  width: 114px;
  height: 42px;
`
