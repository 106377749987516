/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import * as diff from 'diff'
import React from 'react'

import { TextView } from '../../gadget-helpers'

export default function ({ value, gridded, condensed, context }) {
  if (context?.prev) {
    const changes = diff.diffWordsWithSpace(
      context.prev.value || '',
      value || ''
    )
    value = changes.map((part, i) => (
      <span
        key={i}
        className={cx({
          'rounded pr-1 font-bold before:mr-1 before:rounded-l before:text-[white]':
            part.added || part.removed,
          'bg-green-200 before:bg-green-400 before:px-0.5 before:content-["＋"]':
            part.added,
          'bg-red-200 before:bg-red-400 before:px-1 before:content-["✕"]':
            part.removed
        })}
      >
        {part.value}
      </span>
    ))
  }
  return <TextView {...{ condensed, gridded }}>{value}</TextView>
}
