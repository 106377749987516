/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { last, partition, sortBy } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { printDocumentHistory } from '../../components/feature-flags'
import { useTransitionRef } from '../../components/use-transition-ref'
import * as Icons from '../../icons'
import { useAlerts } from '../../ui/alerts'
import { Popover2 } from '../../ui/popover'
import { DocumentQueryContext } from '../action/workflow-tracker'
import RefreshButton from '../action/workflow-tracker/refresh-button'
import { useRefreshSimulation } from '../action/workflow-tracker/use-refresh-simulation'
import { useWorkflowOverview } from '../action/workflow-tracker/utils'
import HistoryList from './components/history-list'

export const Button = ({ onClick, trackDocumentEdits }) => {
  return (
    <button
      className='kp-button-outline max-lg:w-full'
      data-testid='history-button'
      onClick={onClick}
    >
      {trackDocumentEdits ? (
        <Trans id='document.history' message='Document History' />
      ) : (
        <Trans id='pagesrunner.workflow.status' message='Workflow Status' />
      )}
    </button>
  )
}

export const View = ({
  documentHistory,
  documentQuery,
  formTemplate,
  hide,
  isVisible,
  trackDocumentEdits,
  width
}) => {
  const ref = useTransitionRef(isVisible)
  const closeButtonRef = useRef(null)
  useEffect(() => {
    if (closeButtonRef.current) {
      setTimeout(() => closeButtonRef.current?.focus())
    }
  }, [isVisible])
  const {
    appId,
    documentId,
    hasVersions,
    hasWFTroubleshootPerms,
    history = [],
    simulation,
    versions = []
  } = documentHistory
  const [workflowOverview, updateWorkflowOverview] =
    useWorkflowOverview(simulation)
  const [refreshSimulation, refreshError] =
    useRefreshSimulation(DocumentQueryContext)
  const [refreshing, setRefreshing] = React.useState(false)
  const alerts = useAlerts()
  React.useEffect(() => {
    if (simulation) {
      updateWorkflowOverview({ simulation })
    }
  }, [simulation])
  if (refreshError) {
    alerts.type3(refreshError, 'error')
  }
  const isComplete = ['Complete', 'Withdrawn', 'Denied'].includes(
    workflowOverview?.status
  )
  const [selectedOption, setSelectedOption] = React.useState('all')
  const [showAllVersions, setShowAllVersions] = React.useState(false)
  const [showFutureSteps, setShowFutureSteps] = React.useState(true)
  const [expandOrCollapseAll, setExpandOrCollapseAll] = React.useState('none')
  const panelWidth = width <= 1024 ? 'w-full' : 'w-[440px]'
  const topStyle =
    width <= 1024 ? 'top-0 z-[10] h-full' : 'z-[2] h-[calc(100%-64px)]'
  const SyncWorkflowButton =
    hasWFTroubleshootPerms && !isComplete ? (
      <div className='rounded border border-light-gray-400'>
        <RefreshButton
          label={
            <Trans id='pagesrunner.workflow.sync' message='Sync Workflow' />
          }
          refreshing={refreshing}
          refreshSimulation={async () => {
            try {
              setRefreshing(true)
              await refreshSimulation(appId, documentId, updateWorkflowOverview)
              alerts.type3(
                i18n._({
                  id: 'pagesrunner.workflow.updated',
                  message: 'Workflow Status Updated'
                }),
                'info'
              )
            } catch (error) {
              alerts.type3(error, 'error')
            } finally {
              setRefreshing(false)
            }
          }}
        />
      </div>
    ) : null
  let _workflowOverview = sortCompleteSteps(workflowOverview)
  _workflowOverview = isComplete
    ? addWorkflowCompleteStep(_workflowOverview)
    : _workflowOverview
  let historyMaybeWithVersions = history
  if (showAllVersions && versions.length > 1) {
    let simulationSteps = workflowOverview?.steps
    const sortedVersions = sortBy(versions, v => v.meta?.versionNumber)
    historyMaybeWithVersions = []
    simulationSteps = sortedVersions.reduce((acc, v) => {
      if (v.history?.length > 0) {
        historyMaybeWithVersions = historyMaybeWithVersions.concat(
          v.history.map(h => ({ ...h, versionNumber: v.meta?.versionNumber }))
        )
      }
      if (v.workflow?.simulation?.steps) {
        const stepsToAdd =
          v.id === documentId
            ? workflowOverview.steps
            : addWorkflowCompleteStep(v.workflow.simulation).steps

        return acc.concat(
          stepsToAdd.map(s => ({
            ...s,
            versionNumber: v.meta?.versionNumber
          }))
        )
      }
      return acc
    }, [])
    _workflowOverview = {
      ...workflowOverview,
      stepsWithAllVersions: simulationSteps
    }
  }
  const [notifications, documentEdits] = partition(
    historyMaybeWithVersions,
    event => event.type === 'notification'
  )
  const includeNotifcations = notifications.length > 0
  const MainElement = (
    <TransitionGroup component={null}>
      <CSSTransition key={isVisible} timeout={500} nodeRef={ref}>
        {isVisible && documentHistory?.appId ? (
          <div
            id='history-panel'
            ref={ref}
            className={`kp-slide-in-from-right absolute right-0 print:hidden ${topStyle} ${panelWidth} overflow-auto bg-white transition-[transform] duration-500`}
          >
            <div className='sticky top-0 z-10 flex flex-col items-start gap-2.5 self-stretch border-light-gray-300 bg-light-gray-200 p-4'>
              <div className='flex w-full flex-row items-center justify-between'>
                <div className='mr-4 text-base font-medium text-black'>
                  {trackDocumentEdits ? (
                    <Trans id='document.history' message='Document History' />
                  ) : (
                    <Trans
                      id='pagesrunner.workflow.status'
                      message='Workflow Status'
                    />
                  )}
                </div>
                <div className='flex flex-1 justify-end print:hidden'>
                  <button
                    className='kp-button-transparent kp-button-icon kp-button-lg m-0 h-4 p-0 text-medium-gray-500 dark:text-medium-gray-300'
                    onClick={hide}
                    aria-label={
                      trackDocumentEdits
                        ? i18n._({
                            id: 'close.document.history',
                            message: 'Close document history view'
                          })
                        : i18n._({
                            id: 'close.workflow.status',
                            message: 'Close workflow status view'
                          })
                    }
                    ref={closeButtonRef}
                  >
                    <Icons.Close className='h-4 w-4' />
                  </button>
                </div>
              </div>

              <div className='flex w-full flex-row-reverse items-center justify-between'>
                <OptionMenu
                  hasVersions={hasVersions}
                  setExpandOrCollapseAll={setExpandOrCollapseAll}
                  setShowAllVersions={setShowAllVersions}
                  setShowFutureSteps={setShowFutureSteps}
                  showAllVersions={showAllVersions}
                  showFutureSteps={showFutureSteps}
                />
                {SyncWorkflowButton}
              </div>
              {(trackDocumentEdits || includeNotifcations) && (
                <div className='flew-row flex items-start gap-4 text-base text-medium-gray-500'>
                  <button
                    className={
                      selectedOption === 'all'
                        ? 'border-b-2 border-blue-500 text-black'
                        : ''
                    }
                    onClick={() => setSelectedOption('all')}
                  >
                    <Trans id='all.activity' message='All Activity' />
                  </button>
                  {trackDocumentEdits && documentEdits?.length > 0 && (
                    <button
                      className={
                        selectedOption === 'edits'
                          ? 'border-b-2 border-blue-500 text-black'
                          : ''
                      }
                      onClick={() => setSelectedOption('edits')}
                    >
                      <Trans id='edits' message='Edits' />
                    </button>
                  )}
                  <button
                    className={
                      selectedOption === 'workflow'
                        ? 'border-b-2 border-blue-500 text-black'
                        : ''
                    }
                    onClick={() => setSelectedOption('workflow')}
                  >
                    <Trans id='workflow' message='Workflow' />
                  </button>
                  {includeNotifcations && (
                    <button
                      className={
                        selectedOption === 'email'
                          ? 'border-b-2 border-blue-500 text-black'
                          : ''
                      }
                      onClick={() => setSelectedOption('email')}
                    >
                      <Trans id='email' message='Email' />
                    </button>
                  )}
                </div>
              )}
            </div>
            <div>
              <HistoryList
                documentEdits={documentEdits}
                documentHistory={documentHistory}
                expandOrCollapseAll={expandOrCollapseAll}
                formTemplate={formTemplate}
                notifications={notifications}
                selectedOption={selectedOption}
                showFutureSteps={showFutureSteps}
                updateWorkflowOverview={updateWorkflowOverview}
                workflowOverview={_workflowOverview}
              />
            </div>
          </div>
        ) : (
          <span />
        )}
      </CSSTransition>
    </TransitionGroup>
  )

  return documentQuery ? (
    <DocumentQueryContext.Provider value={{ query: documentQuery }}>
      {MainElement}
    </DocumentQueryContext.Provider>
  ) : (
    MainElement
  )
}

function sortCompleteSteps (workflowOverview) {
  const steps = workflowOverview.steps
  // use lodash partition to separate complete steps from incomplete steps
  const [completeSteps, incompleteSteps] = partition(
    steps,
    step => step.completedAt
  )
  const sortedCompleteSteps = sortBy(completeSteps, step => step.completedAt)
  return {
    ...workflowOverview,
    steps: [...sortedCompleteSteps, ...incompleteSteps]
  }
}
function addWorkflowCompleteStep (workflowOverview) {
  // if workflowOverview has a status of Complete or Rejected, add a step to the end of the steps array
  const msg = `Workflow ${workflowOverview.status}`
  const completeStep = {
    completedAt: last(workflowOverview.steps)?.completedAt,
    msg,
    status: msg,
    type: 'workflowComplete',
    versionNumber: workflowOverview.versionNumber
  }
  return {
    ...workflowOverview,
    steps: [...workflowOverview.steps, completeStep]
  }
}

function OptionMenu ({
  hasVersions,
  setExpandOrCollapseAll,
  setShowAllVersions,
  setShowFutureSteps,
  showAllVersions,
  showFutureSteps
}) {
  return (
    <div className='rounded border border-light-gray-400'>
      <Popover2
        role='menu'
        trigger={
          <button className='kp-button-transparent'>
            <Icons.Settings className='mr-2' />
            <Trans id='pagesrunner.history.view' message='View' />
          </button>
        }
        top='36'
        right='4'
      >
        {close => (
          <ul className='p-2 dark:bg-dark-gray-400'>
            {hasVersions && (
              <li>
                <button
                  aria-label={
                    showAllVersions
                      ? i18n._({
                          id: 'show.all.versions.selected',
                          message: 'Show all versions - selected'
                        })
                      : i18n._({
                          id: 'show.all.versions.unselected',
                          message: 'Show all versions - unselected'
                        })
                  }
                  aria-live='assertive'
                  className='kp-button-transparent kp-button-icon w-full justify-start gap-4 text-medium-gray-500'
                  onClick={() => {
                    setShowAllVersions(!showAllVersions)
                  }}
                >
                  <Icons.History className='dark:fill-medium-gray-300' />
                  <span>
                    <Trans id='show.all.versions' message='Show All Versions' />
                  </span>
                  <span className='flex-grow' />
                  {showAllVersions && <Icons.Check />}
                </button>
              </li>
            )}
            <li>
              <button
                aria-label={
                  showFutureSteps
                    ? i18n._({
                        id: 'show.future.steps.selected',
                        message: 'Show future workflow steps - selected'
                      })
                    : i18n._({
                        id: 'show.future.steps.unselected',
                        message: 'Show future workflow steps - unselected'
                      })
                }
                aria-live='assertive'
                className='kp-button-transparent kp-button-icon gap-4 text-medium-gray-500'
                onClick={() => {
                  setShowFutureSteps(!showFutureSteps)
                }}
              >
                <Icons.Hourglass className='dark:fill-medium-gray-300' />
                <span>
                  <Trans
                    id='show.future.steps'
                    message='Show Future Workflow Steps'
                  />
                </span>
                <span className='flex-grow' />
                {showFutureSteps ? <Icons.Check /> : <span className='w-4' />}
              </button>
            </li>
            <li>
              <button
                className='kp-button-transparent kp-button-icon w-full text-medium-gray-500'
                onClick={() => {
                  setExpandOrCollapseAll('expand')
                }}
              >
                <span className='flex w-full justify-start gap-4'>
                  <Icons.SelectDownArrow className='dark:fill-medium-gray-300' />
                  <span>
                    <Trans id='expand.all' message='Expand All' />
                  </span>
                </span>
              </button>
            </li>
            <li>
              <button
                className='kp-button-transparent kp-button-icon w-full text-medium-gray-500'
                onClick={() => {
                  setExpandOrCollapseAll('collapse')
                }}
              >
                <span className='flex w-full justify-start gap-4'>
                  <Icons.SelectUpArrow className='dark:fill-medium-gray-300' />
                  <span>
                    <Trans id='collapse.all' message='Collapse All' />
                  </span>
                </span>
              </button>
            </li>
            {printDocumentHistory && (
              <li>
                <div className='border-t border-light-gray-300 dark:border-dark-gray-300' />
                <button
                  className='kp-button-transparent kp-button-icon gap-4 text-medium-gray-500'
                  onClick={() => close()}
                >
                  <Icons.Print className='dark:fill-medium-gray-300' />
                  <span>
                    <Trans id='print' message='Print' />
                  </span>
                </button>
              </li>
            )}
          </ul>
        )}
      </Popover2>
    </div>
  )
}
