/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import React from 'react'
import { useOutletContext } from 'react-router'

import AppMultiselect from '../../components/app-multiselect'
import AppsOrProducts from '../../components/apps-or-products'
import { Wrapper } from '../../ui/layout'
import Radios from '../../ui/radios'
import { useSetDataSharingMutation } from './components/mutation.set-data-sharing'

export default function Sharing () {
  const { integration } = useOutletContext()
  const setDataSharing = useSetDataSharingMutation(integration.id)
  const sharing = integration.sharedWithOthers
  return (
    <div className='flex flex-col items-center text-sm'>
      <div className='w-96 py-4'>
        <Wrapper as='label' className='block pb-1 font-medium'>
          <Trans
            id='data.sharing.other.apps'
            values={{ appsOrProducts: <AppsOrProducts lowercase /> }}
          />
        </Wrapper>
        <Radios
          name='sharing-level'
          options={[
            {
              id: 'NONE',
              htmlId: 'none',
              label: <Trans id='do.not.share.data' />
            },
            {
              id: 'ALL',
              htmlId: 'allApps',
              ariaLabel: (
                <Trans
                  id='share.with.all.apps'
                  values={{ appsOrProducts: <AppsOrProducts lowercase /> }}
                />
              ),
              label: (
                <span>
                  <Trans
                    id='share.with.all.apps.bold'
                    components={{ bold: <b /> }}
                    values={{ appsOrProducts: <AppsOrProducts lowercase /> }}
                  />
                </span>
              )
            },
            {
              id: 'SPECIFIC',
              htmlId: 'specificApps',
              ariaLabel: (
                <Trans
                  id='share.with.specific.apps'
                  values={{ appsOrProducts: <AppsOrProducts lowercase /> }}
                />
              ),
              label: (
                <span>
                  <Trans
                    id='share.with.specific.apps.bold'
                    components={{ bold: <b /> }}
                    values={{ appsOrProducts: <AppsOrProducts lowercase /> }}
                  />
                </span>
              )
            }
          ]}
          value={sharing.type}
          onChange={type => setDataSharing(type, sharing.apps)}
        />
        {sharing.type === 'SPECIFIC' && (
          <AppMultiselect
            className='mt-4'
            value={sharing.apps}
            onChange={apps => setDataSharing(sharing.type, apps)}
          />
        )}
      </div>
    </div>
  )
}
