/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { Trans } from '@lingui/react'
import React from 'react'

import { AppIcon, AppSquare } from '../../../components/app-icon'
import { useTenantFeaturesContext } from '../../../components/tenant-features-context'
import { formsQuery } from '../../data-chooser'
import { ReactComponent as ImgInternal } from '../../source-kuali.svg.jsx'
import { ReactComponent as Illustration } from '../data-lookup/illustration-integration.svg.jsx'
import AssociationsChooser from './associations-chooser'
import VersionConfig from './version-config'

export function RequiredConfig ({ value, ...props }) {
  const hasChosenDataset = value?.source?.dataset?.id

  return hasChosenDataset ? (
    <RequiredConfigWithDataset {...props} value={value} />
  ) : (
    <RequiredConfigWithoutDataset {...props} />
  )
}

export function RequiredConfigWithoutDataset ({
  Gadgets,
  updateDataLookupSource
}) {
  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <AssociationsChooser
        onSelect={updateDataLookupSource}
        options={ASSOCIATIONS_OPTIONS}
      />
      <div className='mt-9 flex items-center justify-center'>
        <div className='flex h-24 w-36 items-center justify-center rounded-xl bg-light-gray-100 px-5 text-center text-base text-dark-gray-200 dark:bg-light-gray-300'>
          <Trans id='choose.source.to.begin' />
        </div>
        <Illustration />
      </div>
    </Gadgets.Padded>
  )
}

export function RequiredConfigWithDataset ({
  Gadgets,
  onChange,
  value,
  updateDataLookupSource,
  formKey
}) {
  const { data } = useQuery(datasetQuery, {
    variables: {
      appId: value?.source?.product?.id || value?.source.dataset?.id,
      datasetId: value?.source?.product?.id ? value?.source.dataset?.id : null
    }
  })
  const tenantFeatures = useTenantFeaturesContext()
  const tenantVersionSettings = tenantFeatures?.versions ?? false

  const showVersionConfig = !!(
    tenantVersionSettings &&
    (value?.source?.dataset?.details?.allowNewVersions ||
      data?.app?.dataset?.allowNewVersions) &&
    value?.source?.gadget
  )

  const combinedLabel = value?.source?.gadget
    ? `${value?.source?.dataset?.label} - ${value?.source?.gadget?.label}`
    : value?.source?.dataset?.label
  return (
    <Gadgets.Padded>
      <Gadgets.Label>
        <Trans id='data.source.colon' />
      </Gadgets.Label>
      <AssociationsChooser
        value={value}
        onSelect={updateDataLookupSource}
        id={value?.source?.dataset?.id}
        label={combinedLabel}
        tileOptions={value?.source?.dataset?.details?.tileOptions}
        options={ASSOCIATIONS_OPTIONS}
      />
      {showVersionConfig && (
        <VersionConfig id={formKey} Gadgets={Gadgets} className='mt-4' />
      )}
    </Gadgets.Padded>
  )
}

export const ASSOCIATIONS_OPTIONS = [
  {
    id: 'kuali',
    title: 'Kuali Data',
    Icon: ImgInternal,
    fetchOptions: async (apolloClient, params) => [
      ...(await fetchAppsAndProducts(apolloClient, params))
    ]
  }
]

async function fetchAppsAndProducts (apolloClient, { appId }) {
  const { data } = await apolloClient.query({
    query: formsQuery,
    variables: { appId }
  })

  const appDatasets = []
  const products = []

  data?.app?.sharedWithMe?.apps.forEach(app => {
    if (app.type === 'app') {
      appDatasets.push({
        type: 'app',
        id: app.id,
        label: app.name,
        Icon: () => (
          <AppSquare
            backgroundColor={app.tileOptions?.backgroundColor}
            className='mr-2 w-8'
          >
            <AppIcon iconName={app.tileOptions?.iconName} />
          </AppSquare>
        ),
        details: {
          id: app.id,
          label: app.name,
          tileOptions: app.tileOptions,
          allowNewVersions: app.datasets[0]?.allowNewVersions
        }
      })
    } else if (app.type === 'product') {
      products.push({
        id: app.id,
        label: app.name,
        Icon: () => (
          <AppSquare
            backgroundColor={app.tileOptions?.backgroundColor}
            className='mr-2 w-8'
          >
            <AppIcon iconName={app.tileOptions?.iconName} isProduct />
          </AppSquare>
        ),
        type: 'product',
        datasets: app.datasets.map(a => {
          return {
            ...a,
            appId: app.id,
            Icon: () => (
              <AppSquare backgroundColor='transparent' className='mr-2 w-8'>
                <AppIcon iconName={a.icon} isDataset />
              </AppSquare>
            )
          }
        }),
        details: {
          id: app.id,
          label: app.name,
          tileOptions: app.tileOptions
        }
      })
    }
  })

  return [...products, ...appDatasets]
}

const datasetQuery = gql`
  query getDataset($appId: ID!, $datasetId: ID) {
    app(id: $appId) {
      id
      dataset(id: $datasetId) {
        id
        allowNewVersions
      }
    }
  }
`
