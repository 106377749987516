import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { produce } from 'immer'
import React from 'react'
import { Link, useLocation, useParams } from 'react-router'

import { useAcknowledgeDanger } from '../../../components/mutation.acknowledge-danger'
import { Tooltip, TooltipTrigger } from '../../../components/new-tooltip'
import { useTenantFeaturesContext } from '../../../components/tenant-features-context'
import * as Icons from '../../../icons'
import { ReactComponent as Stop } from '../../../illustrations/stop.svg.jsx'
import Checkbox from '../../../ui/checkbox'
import Popup from '../../../ui/pop-up'
import { useNewModifyPolicyMutation } from './mutation.modify-policy'

export default function NewPermissions ({ data }) {
  const tenantFeatures = useTenantFeaturesContext()
  const tenantVersionSettings = tenantFeatures?.versions ?? false
  const mutateAcknowledged = useAcknowledgeDanger()
  const [activePerm, setActivePerm] = React.useState('')
  const [acknowledged, setAcknowledged] = React.useState(false)
  const [understood, setUnderstood] = React.useState(
    !!data.app?.acknowledgedDanger?.dateDismissed
  )
  const anyDatasetHasVersions =
    data?.app?.pages?.filter(page => page.details.allowNewVersions).length > 0

  const permissions = getPermissions(
    tenantVersionSettings && anyDatasetHasVersions
  )
  const policyGroups = data.app.listPolicyGroups

  const modifyPolicy = useNewModifyPolicyMutation()

  const isKualiMade = (name, i) => {
    return (
      [
        'Administrators',
        'All Anonymous Users',
        'All Authenticated Users'
      ].includes(name) && i < 4
    )
  }

  const isChangeable = (policyGroup, i, perm) => {
    return !(
      isKualiMade(policyGroup.name, i) &&
      (policyGroup.name === 'Administrators' ||
        (policyGroup.name === 'All Anonymous Users' &&
          perm !== 'apps:createDocuments'))
    )
  }

  const getTitleIcon = (policyGroup, i) => {
    if (isKualiMade(policyGroup.name, i)) return <Icons.KualiK />
    const hasUsers = policyGroup.identities.some(i => i.type === 'USER')
    const hasGroups = policyGroup.identities.some(i => i.type === 'GROUP')
    const hasRoles = policyGroup.identities.some(i => i.type === 'ROLE')
    const numOfTypes = [hasUsers, hasGroups, hasRoles].filter(Boolean).length
    if (numOfTypes > 1) return <Icons.PersonFolder />
    if (hasUsers) return <Icons.User />
    if (hasGroups) return <Icons.Users />
    if (hasRoles) return <Icons.CowboyHat />

    // Default (if they don't have any users/groups/roles)
    return <Icons.Folder fill='var(--light-gray-400)' />
  }

  const handleTogglePermission = (policyGroup, action, currentlyEnabled) => {
    if (
      !currentlyEnabled &&
      policyGroup.name === 'All Authenticated Users' &&
      !['apps:createDocuments', 'apps:readDocumentsConditionally'].includes(
        action
      ) &&
      !understood
    ) {
      setActivePerm({ policyGroup, action, currentlyEnabled })
    } else {
      doMutation(policyGroup, action, currentlyEnabled)
    }
  }

  function doMutation (policyGroup, action, currentlyEnabled) {
    const policy = policyGroup.policies[0]
    const newStatements = produce(policy.statements, draft => {
      const statement = draft[0]
      if (currentlyEnabled) {
        // Remove the action
        statement.action = statement.action.filter(a => a !== action)
      } else {
        // Add the action
        statement.action = [...statement.action, action]
      }
    })

    // Call the mutation with all required parameters
    return modifyPolicy(policyGroup.id, policy.id, {
      statements: newStatements,
      version: policy.version
    })
  }

  const handleModalSubmission = decision => {
    if (decision) {
      if (acknowledged) mutateAcknowledged(data.app.id)
      const { policyGroup, action, currentlyEnabled } = activePerm
      doMutation(policyGroup, action, currentlyEnabled)
    }
    setActivePerm('')
    setAcknowledged(false)
  }

  return (
    <div className='mt-40 p-8 text-sm'>
      <Popup
        showModal={activePerm}
        title={i18n._('pagesbuilder.pmanager.danger')}
        subtitle={i18n._('pagesbuilder.pmanager.danger.subtitle')}
        Img={Stop}
        width='550'
      >
        <>
          <Checkbox
            checked={acknowledged}
            label={i18n._('do.not.show.again.for.app')}
            onChange={() => setAcknowledged(!acknowledged)}
            value={acknowledged}
          />
          <button
            className='kp-button-outline mr-2 mt-4'
            onClick={() => handleModalSubmission(false)}
          >
            <Trans id='cancel' />
          </button>
          <button
            className='kp-button-solid'
            onClick={() => {
              handleModalSubmission(true)
              setUnderstood(true)
            }}
          >
            <Trans id='pagesbuilder.pmanager.continue' />
          </button>
        </>
      </Popup>
      <table className='w-full'>
        <thead>
          <tr>
            {/* Empty cell for the left column header */}
            <th className='w-80' scope='col'>
              <span className='sr-only'>Permissions</span>
            </th>
            {/* Policy group headers */}
            {policyGroups.map((policyGroup, i) => (
              <th key={policyGroup.id} className='w-12' scope='col'>
                <div className='block w-10 pl-6'>
                  <Link
                    className={cx(
                      'flex w-fit max-w-[150px] origin-left -rotate-45 items-center gap-1 whitespace-nowrap rounded-md border border-light-gray-400 p-1 font-normal',
                      {
                        'bg-white shadow-md': !isKualiMade(policyGroup.name, i)
                      }
                    )}
                    to={policyGroup.id}
                  >
                    {getTitleIcon(policyGroup, i)}
                    <span
                      className='overflow-hidden text-ellipsis'
                      title={policyGroup.name}
                    >
                      {policyGroup.name}
                    </span>
                    {!(
                      isKualiMade(policyGroup.name, i) &&
                      [
                        'All Anonymous Users',
                        'All Authenticated Users'
                      ].includes(policyGroup.name)
                    ) && `(${policyGroup.identities.length})`}
                  </Link>
                </div>
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {permissions.map(({ label, Tooltip: PermissionTooltip, action }) => (
            <tr key={label}>
              <th
                scope='row'
                className='w-80 border-b border-b-light-gray-400 bg-light-gray-200 p-3 text-left font-normal'
              >
                <div className='flex items-center'>
                  {label}
                  {PermissionTooltip && (
                    <TooltipTrigger
                      id={`${label}-tooltip`}
                      className='relative ml-2 inline-block'
                    >
                      <Icons.AlertHelp className='fill-blue-500' />
                      <Tooltip position='bottom' className='w-60'>
                        <PermissionTooltip />
                      </Tooltip>
                    </TooltipTrigger>
                  )}
                </div>
              </th>
              {policyGroups.map((policyGroup, i) => {
                const isEnabled =
                  policyGroup.policies[0].statements[0].action?.includes(action)
                const canChange = isChangeable(policyGroup, i, action)

                return (
                  <td
                    key={`${policyGroup.id}-${label}`}
                    className='border-b border-b-light-gray-400 bg-white pl-5'
                  >
                    <div
                      className={cx(
                        'flex items-center justify-center',
                        canChange && 'cursor-pointer'
                      )}
                      onClick={() =>
                        canChange &&
                        handleTogglePermission(policyGroup, action, isEnabled)
                      }
                      role={canChange ? 'button' : undefined}
                      tabIndex={canChange ? 0 : undefined}
                    >
                      {isEnabled ? (
                        <Icons.WorkflowCheck
                          className={cx(
                            canChange ? 'fill-green-400' : 'fill-green-200'
                          )}
                          width='20'
                          height='20'
                        />
                      ) : (
                        <div
                          className={cx(
                            'h-5 w-5 rounded-full border',
                            canChange
                              ? 'border-medium-gray-200'
                              : 'border-light-gray-300'
                          )}
                        />
                      )}
                    </div>
                  </td>
                )
              })}
              <td className='border-b border-b-light-gray-400 bg-white' />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const getPermissions = shouldShowVersionSettings =>
  [
    {
      label: `${i18n._('pagesbuilder.pmanager.administer')}`,
      action: 'apps:administer'
    },
    {
      label: `${i18n._('pagesbuilder.pmanager.create')}`,
      action: 'apps:createDocuments'
    },
    {
      label: `${i18n._('pagesbuilder.pmanager.read')}`,
      action: 'apps:readDocuments'
    },
    {
      label: `${i18n._('pagesbuilder.pmanager.access')}`,
      action: 'apps:readDocumentsConditionally',
      Tooltip: function ConditionalPermissionsTooltip () {
        const location = useLocation()
        const { appId } = useParams()
        return (
          <div>
            <p>
              <Trans
                id='pagesbuilder.pmanager.link'
                components={{
                  link: (
                    <Link
                      state={{ back: location.pathname }}
                      to={{
                        pathname: `../../form/${appId}/settings/conditional-permissions`
                      }}
                      className='text-text-link underline'
                    />
                  )
                }}
              />
            </p>
            <br />
            <p>
              <Trans id='pagesbuilder.pmanager.empty.list' />
            </p>
          </div>
        )
      }
    },
    {
      label: `${
        shouldShowVersionSettings
          ? i18n._('pagesbuilder.pmanager.edit')
          : i18n._('pagesbuilder.pmanager.update')
      } ${i18n._('pagesbuilder.pmanager.docs.in')}`,
      action: 'apps:updateDocuments'
    },
    {
      label: `${i18n._('pagesbuilder.pmanager.delete.docs')}`,
      action: 'apps:deleteDocuments'
    },
    shouldShowVersionSettings && {
      label: `${i18n._('pagesbuilder.pmanager.create.versions')}`,
      action: 'apps:createDocumentVersions'
    }
  ].filter(Boolean)
