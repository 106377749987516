/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'

export const Options = purpose => [
  { id: 'IS_EMPTY', label: i18n._({ id: 'is.empty', message: 'is empty' }) },
  {
    id: 'IS_NOT_EMPTY',
    label: i18n._({ id: 'is.not.empty', message: 'is not empty' })
  },
  {
    id: 'IS_BEFORE',
    label: i18n._({ id: 'is.before', message: 'is before' }),
    exposes: 'date'
  },
  {
    id: 'IS_ON_OR_BEFORE',
    label: i18n._({ id: 'is.on.or.before', message: 'is on or before' }),
    exposes: 'date'
  },
  {
    id: 'IS_AFTER',
    label: i18n._({ id: 'is.after', message: 'is after' }),
    exposes: 'date'
  },
  {
    id: 'IS_ON_OR_AFTER',
    label: i18n._({ id: 'is.on.or.after', message: 'is on or after' }),
    exposes: 'date'
  },
  { id: 'IS', label: i18n._('is'), exposes: 'date' },
  ...(!purpose || purpose === 'doc-list'
    ? [
        {
          id: 'RELATIVE',
          label: i18n._({ id: 'is.(relative)', message: 'is (relative)' }),
          exposes: 'single-select',
          options: [
            { id: 'today', label: i18n._({ id: 'today', message: 'Today' }) },
            {
              id: 'this.week',
              label: i18n._({ id: 'this.week', message: 'This Week' })
            },
            {
              id: 'this.month',
              label: i18n._({ id: 'this.month', message: 'This Month' })
            },
            {
              id: 'this.year',
              label: i18n._({ id: 'this.year', message: 'This Year' })
            },
            {
              id: 'this.fiscal.year',
              label: i18n._({
                id: 'this.fiscal.year',
                message: 'This fiscal year'
              })
            },
            {
              id: 'in.the.next',
              label: i18n._({ id: 'in.the.next', message: 'In the next...' }),
              extraKey: 'days',
              extraKeyDefault: 1,
              exposes: (value, update) => (
                <input
                  type='number'
                  min='0'
                  className='kp-input w-full'
                  value={value || 0}
                  onChange={e => update(e.target.valueAsNumber)}
                />
              )
            },
            {
              id: 'in.the.last',
              label: i18n._({ id: 'in.the.last', message: 'In the last...' }),
              extraKey: 'days',
              extraKeyDefault: 1,
              exposes: (value, update) => (
                <input
                  type='number'
                  min='0'
                  className='kp-input w-full'
                  value={value || 0}
                  onChange={e => update(e.target.valueAsNumber)}
                />
              )
            }
          ]
        }
      ]
    : [])
]
