/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { Navigate as RRNavigate, useSearchParams } from 'react-router'

import AppLayout from './components/app-layout'
import { productBuilder, removeHomeRedirects } from './components/feature-flags'
import {
  RedirectToSuiteSpace,
  SuiteHomeLayout,
  SuiteRedirect
} from './components/home-layout'
import { ExplicitError } from './components/system-error'
import AnonymousForm from './pages-anonymous'
import AnonymousFormRedirect from './pages-anonymous/redirect'
import Dashboard from './pages-builder/dashboard'
import DeleteApp from './pages-builder/delete-app'
import DocumentList from './pages-builder/document-list'
import DataSettings from './pages-builder/document-list/components/data-settings'
import DocAction from './pages-builder/document-list/components/doc-action'
import DocEdit from './pages-builder/document-list/components/doc-edit'
import DocView from './pages-builder/document-list/components/doc-view'
import ModalPageOutlet from './pages-builder/document-list/components/modal-page-outlet'
import DocViewPortal from './pages-builder/document-list/doc-view-portal'
import DocumentListRedirect from './pages-builder/document-list/redirect'
import External from './pages-builder/external'
import Form from './pages-builder/form'
import ConditionalPermissions from './pages-builder/form/conditional-permissions'
import FormSettings from './pages-builder/form/form-settings'
import MaintenanceTables from './pages-builder/maintenance-tables'
import Permissions from './pages-builder/permissions'
import { PolicyGroupView } from './pages-builder/permissions/components/policy-group-view'
import Publish from './pages-builder/publish'
import Settings from './pages-builder/settings'
import AppSharing from './pages-builder/sharing'
import Workflow from './pages-builder/workflow'
import WorkflowSettings from './pages-builder/workflow/workflow-settings'
import Action from './pages-runner/action'
import ViewEmail from './pages-runner/document-history/components/view-email'
import FormEdit from './pages-runner/edit-form'
import Run from './pages-runner/run'
import RunRedirect from './pages-runner/run/redirect'
import FormViewRedirect from './pages-runner/view-form/redirect'
import SystemIntegrations from './pages/api-integrations'
import IntegrationDetails from './pages/api-integrations/:id'
import EditIntegration from './pages/api-integrations/edit'
import LassoNew from './pages/api-integrations/lasso-new'
import NewIntegration from './pages/api-integrations/new'
import Sharing from './pages/api-integrations/sharing'
import UsedBy from './pages/api-integrations/used-by'
import SystemLogs from './pages/audit-log'
import Forbidden from './pages/forbidden'
import Health from './pages/health'
import HomeSpaces from './pages/home/index-spaces'
import Identity from './pages/identity'
import Blueprints from './pages/identity/blueprints'
import {
  BlueprintDetailsBasicInfo,
  BlueprintsDetails
} from './pages/identity/blueprints/blueprint-details'
import BlueprintFields from './pages/identity/blueprints/blueprint-fields'
import { BlueprintsGroupList } from './pages/identity/blueprints/blueprint-group-list'
import BlueprintHistory from './pages/identity/blueprints/blueprint-history'
import BlueprintRoles from './pages/identity/blueprints/blueprint-roles'
import Groups from './pages/identity/groups'
import GroupDetail from './pages/identity/groups/:id'
import RoleView from './pages/identity/groups/:id/components/role-view'
import GroupFields from './pages/identity/groups/:id/fields'
import GroupHistory from './pages/identity/groups/:id/history'
import GroupRoles from './pages/identity/groups/:id/roles'
import GroupView from './pages/identity/groups/:id/view'
import Users from './pages/identity/users'
import UserDetail from './pages/identity/users/:id'
import UserApiKeys from './pages/identity/users/:id/api-keys'
import UserEdit from './pages/identity/users/:id/edit'
import UserHistory from './pages/identity/users/:id/history'
import UserView from './pages/identity/users/:id/view'
import UserNew from './pages/identity/users/new'
import Imports from './pages/imports'
import NotFound from './pages/not-found'
import OldActionList from './pages/old-actions'
import PageNumRedirect from './pages/page-num-redirect'
import SystemPermissions from './pages/permissions'
import { Protected } from './pages/protected-route'
import RedirectOldHomeRoutes from './pages/redirects/old-home-routes'
import SystemSettings, {
  RedirectToFirstSpace,
  SettingsRedirect
} from './pages/settings'
import Spaces from './pages/spaces'
import { EditLink, NewLink } from './pages/spaces/components/link-forms'
import AppsPane from './pages/spaces/components/pane-apps'
import BrandingPane from './pages/spaces/components/pane-branding'
import GeneralPane from './pages/spaces/components/pane-general'
import IntegrationsPane from './pages/spaces/components/pane-integrations'
import PermissionsPane from './pages/spaces/components/pane-permissions'
import SuitePane from './pages/spaces/components/pane-suite'
import SubmitterControls from './pages/submitter-controls'
import NewSubmittedDocument from './pages/submitter-controls/components/doc-submission'
import Drafts from './pages/submitter-controls/components/drafts'
import Submissions from './pages/submitter-controls/components/submissions'
import Usage from './pages/usage'
import UsageApps from './pages/usage/apps'
import UsageGeneral from './pages/usage/general'
import RootLayout from './root-layout'

function Navigate ({ to, ...props }) {
  const search = useSearchParams()[0].toString()
  return <RRNavigate to={search ? `${to}?${search}` : to} {...props} />
}

const integrationRoutes = [
  { path: 'new', element: <NewIntegration /> },
  { path: 'lasso/new', element: <LassoNew /> },
  {
    path: ':integrationId',
    element: <IntegrationDetails />,
    children: [
      { path: 'edit', element: <EditIntegration /> },
      { path: 'sharing', element: <Sharing /> },
      { path: 'used-by', element: <UsedBy /> }
    ]
  }
]

const documentChildren = [
  {
    path: 'edit',
    element: <DocEdit />
  },
  {
    path: 'view',
    element: <DocView />
  },
  {
    path: 'versions/:versionId',
    children: [
      {
        path: 'edit',
        element: <DocEdit />
      },
      {
        path: 'view',
        element: <DocView />
      }
    ]
  }
]

const actionChildren = [
  {
    index: true,
    element: <Navigate to='view' replace />
  },
  {
    path: 'view',
    element: <DocAction />
  },
  {
    path: 'versions/:versionId',
    children: [
      {
        path: 'edit',
        element: <DocEdit />
      },
      {
        path: 'view',
        element: <DocView />
      }
    ]
  }
]

const homeRoutes = [
  {
    element: <ModalPageOutlet title='Action List' onClose='.' />,
    children: [
      {
        path: 'actions',
        element: <OldActionList />,
        children: [
          {
            path: ':actionId',
            element: <ModalPageOutlet title='Action' />,
            children: actionChildren
          }
        ]
      }
    ]
  },
  {
    element: <ModalPageOutlet title='My Documents' onClose='.' />,
    children: [
      {
        path: 'my',
        element: <SubmitterControls />,
        children: [
          {
            path: 'drafts',
            element: <Drafts />,
            children: [
              {
                path: ':actionId',
                element: <ModalPageOutlet />,
                children: actionChildren
              }
            ]
          },
          {
            path: 'submissions',
            element: <Submissions />,
            children: [
              {
                path: ':documentId',
                element: <NewSubmittedDocument />
              }
            ]
          },
          {
            index: true,
            element: <Navigate to='submissions' replace />
          }
        ]
      }
    ]
  },
  { path: 'audit-log', element: <SystemLogs /> },
  { path: 'permissions', element: <SystemPermissions /> },
  {
    path: 'api-integrations',
    element: <SystemIntegrations />,
    children: integrationRoutes
  },
  {
    path: 'identity',
    element: <Identity />,
    children: [
      {
        path: 'blueprints',
        element: <Blueprints />,
        children: [
          {
            path: ':categoryId/details',
            element: <BlueprintsDetails />,
            children: [
              {
                path: 'info',
                element: <BlueprintDetailsBasicInfo />,
                children: [
                  { path: 'group-list', element: <BlueprintsGroupList /> }
                ]
              },
              { path: 'roles', element: <BlueprintRoles /> },
              { path: 'fields', element: <BlueprintFields /> },
              { path: 'history', element: <BlueprintHistory /> }
            ]
          },
          { path: ':categoryId/group-list', element: <BlueprintsGroupList /> }
        ]
      },
      {
        path: 'groups',
        element: <Groups />,
        children: [
          {
            path: ':id',
            element: <GroupDetail />,
            children: [
              { path: 'view', element: <GroupView /> },
              {
                path: 'roles',
                element: <GroupRoles />,
                children: [{ path: ':roleId/role', element: <RoleView /> }]
              },
              { path: 'fields', element: <GroupFields /> },
              { path: 'history', element: <GroupHistory /> }
            ]
          }
        ]
      },
      {
        path: 'people',
        element: <Users />,
        children: [
          { path: 'new', element: <UserNew /> },
          {
            path: ':id',
            element: <UserDetail />,
            children: [
              { path: 'edit', element: <UserEdit /> },
              { path: 'view', element: <UserView /> },
              { path: 'api-keys', element: <UserApiKeys /> },
              { path: 'history', element: <UserHistory /> }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'account',
    element: <UserDetail forCurrentUser />,
    children: [
      { path: 'edit', element: <UserEdit /> },
      { path: 'view', element: <UserView /> },
      { path: 'api-keys', element: <UserApiKeys /> },
      { path: 'history', element: <UserHistory /> }
    ]
  },
  productBuilder && { path: 'imports', element: <Imports /> },
  {
    path: 'usage',
    element: <Usage />,
    children: [
      { path: 'general', element: <UsageGeneral /> },
      { path: 'apps', element: <UsageApps /> }
    ]
  }
].filter(Boolean)

const appRoutes = [
  {
    path: 'permissions',
    element: <Permissions />,
    children: [
      {
        path: ':policyGroupId',
        element: <PolicyGroupView />
      }
    ]
  },
  {
    path: 'general-data',
    element: <MaintenanceTables />,
    children: [
      {
        path: ':tableId',
        children: [
          {
            path: 'document-list',
            element: <DocumentList isTable />,
            children: [
              { path: 'settings', element: <DataSettings /> },
              {
                path: ':documentId',
                element: <ModalPageOutlet />,
                children: [
                  ...documentChildren,
                  {
                    path: ':documentId/edit/:pageNum',
                    element: <PageNumRedirect />
                  }
                ]
              },
              {
                path: ':actionId/action',
                element: <ModalPageOutlet />,
                children: actionChildren
              },
              {
                path: ':actionId/action/:pageNum',
                element: <PageNumRedirect />
              }
            ]
          },
          {
            path: 'form',
            element: <Form isProduct isTable />,
            children: [
              { path: 'settings', element: <FormSettings /> },
              {
                path: 'settings/conditional-permissions',
                element: <ConditionalPermissions />
              }
            ].filter(Boolean)
          },
          {
            path: 'workflow',
            element: <Workflow isProduct isTable />,
            children: [{ path: 'settings', element: <WorkflowSettings /> }]
          },
          { path: 'publish', element: <Publish isTable /> },
          { path: 'external', element: <External isTable /> },
          { path: 'dashboard', element: <Dashboard isTable /> },
          { path: 'setting', element: <Settings isTable /> }
        ]
      }
    ]
  },
  { path: 'sharing', element: <AppSharing /> },
  { path: 'delete', element: <DeleteApp /> }
]

export default [
  {
    element: <RootLayout />,
    errorElement: <ExplicitError />,
    children: [
      { index: true, element: <Navigate to='/space' replace /> },
      { path: 'home/*', element: <Navigate to='/space' replace /> },
      { path: ':suite/home/*', element: <RedirectToSuiteSpace /> },
      { path: 'space/*', element: <SuiteRedirect /> },
      { path: 'actions/*', element: <SuiteRedirect /> },
      { path: 'my/*', element: <SuiteRedirect /> },
      { path: 'view/:documentId', element: <DocViewPortal /> },
      {
        element: <SuiteHomeLayout />,
        children: [
          {
            path: ':suite/space',
            children: [
              {
                path: ':spaceId',
                element: <HomeSpaces />,
                children: [
                  ...homeRoutes,
                  {
                    path: 'action/:actionId',
                    element: <ModalPageOutlet />,
                    children: actionChildren
                  }
                ]
              },
              { index: true, element: <HomeSpaces /> },
              {
                path: 'account',
                element: <UserDetail forCurrentUser />,
                children: [
                  { path: 'edit', element: <UserEdit /> },
                  { path: 'view', element: <UserView /> },
                  { path: 'api-keys', element: <UserApiKeys /> },
                  { path: 'history', element: <UserHistory /> }
                ]
              }
            ]
          },
          !removeHomeRedirects && {
            path: ':suite/my/*',
            element: <RedirectOldHomeRoutes />
          },
          !removeHomeRedirects && {
            path: ':suite/actions/*',
            element: <RedirectOldHomeRoutes />
          }
        ].filter(Boolean)
      },
      {
        path: 'audit-log',
        element: <Navigate to='/space/favorites/audit-log' replace />
      },
      {
        path: 'system-settings',
        element: <SystemSettings />,
        children: [
          { index: true, element: <SettingsRedirect /> },
          { path: 'spaces', element: <RedirectToFirstSpace /> },
          {
            path: 'spaces/:id',
            element: <Spaces />,
            children: [
              { index: true, element: <GeneralPane /> },
              {
                path: 'apps',
                element: <AppsPane />,
                children: [
                  { path: 'links/new', element: <NewLink /> },
                  { path: 'links/:linkId/edit', element: <EditLink /> }
                ]
              },
              {
                path: 'integrations',
                element: <IntegrationsPane />,
                children: integrationRoutes
              },
              { path: 'branding', element: <BrandingPane /> },
              productBuilder && { path: 'suite', element: <SuitePane /> }
            ].filter(Boolean)
          },
          {
            path: 'integrations',
            element: <IntegrationsPane />,
            children: integrationRoutes
          },
          { path: 'branding', element: <BrandingPane /> },
          { path: 'permissions', element: <PermissionsPane /> }
        ]
      },
      {
        element: <AppLayout />,
        children: [
          {
            path: 'dashboard/:appId',
            element: (
              <Protected isPage permission='canViewDocuments'>
                <Dashboard />
              </Protected>
            ),
            children: appRoutes
          },
          {
            path: 'document-list/:appId',
            element: (
              <Protected isPage permission='canViewDocuments'>
                <DocumentList />
              </Protected>
            ),
            children: [
              ...appRoutes,
              { path: 'settings', element: <DataSettings /> },
              {
                path: ':documentId',
                element: <ModalPageOutlet />,
                children: documentChildren
              },
              {
                path: ':actionId/action',
                element: <ModalPageOutlet />,
                children: actionChildren
              }
            ]
          },
          {
            path: 'app/:appId/page/:pageId/document-list',
            element: (
              <Protected isPage permission='canViewDocuments'>
                <DocumentList />
              </Protected>
            ),
            children: [
              ...appRoutes,
              { path: 'settings', element: <DataSettings /> },
              {
                path: ':documentId',
                element: <ModalPageOutlet />,
                children: [
                  ...documentChildren,
                  {
                    path: 'edit/:pageNum',
                    element: <PageNumRedirect />
                  }
                ]
              },
              {
                path: ':actionId/action',
                element: <ModalPageOutlet />,
                children: actionChildren
              },
              {
                path: ':actionId/action/:pageNum',
                element: <PageNumRedirect />
              }
            ]
          },
          {
            path: 'form/:appId',
            element: (
              <Protected isPage permission='canManage'>
                <Form />
              </Protected>
            ),
            children: [
              ...appRoutes,
              { path: 'settings', element: <FormSettings /> },
              {
                path: 'settings/conditional-permissions',
                element: <ConditionalPermissions />
              }
            ].filter(Boolean)
          },
          {
            path: 'app/:appId/page/:pageId/form',
            element: (
              <Protected isPage permission='canManage'>
                <Form isProduct />
              </Protected>
            ),
            children: [
              ...appRoutes,
              { path: 'settings', element: <FormSettings /> },
              {
                path: 'settings/conditional-permissions',
                element: <ConditionalPermissions />
              }
            ].filter(Boolean)
          },
          {
            path: 'workflow/:appId',
            element: (
              <Protected isPage permission='canManage'>
                <Workflow />
              </Protected>
            ),
            children: [
              ...appRoutes,
              { path: 'settings', element: <WorkflowSettings /> }
            ]
          },
          {
            path: 'app/:appId/page/:pageId/workflow',
            element: (
              <Protected isPage permission='canManage'>
                <Workflow isProduct />
              </Protected>
            ),
            children: [
              ...appRoutes,
              { path: 'settings', element: <WorkflowSettings /> }
            ]
          },
          {
            path: 'publish/:appId',
            element: (
              <Protected isPage permission='canManage'>
                <Publish />
              </Protected>
            ),
            children: appRoutes
          },
          {
            path: 'app/:appId/page/:pageId/publish',
            element: (
              <Protected isPage permission='canManage'>
                <Publish />
              </Protected>
            ),
            children: appRoutes
          },
          {
            path: 'app/:appId/page/:pageId/external',
            element: (
              <Protected isPage permission='canViewDocuments'>
                <External />
              </Protected>
            ),
            children: appRoutes
          },
          {
            path: 'app/:appId/page/:pageId/dashboard',
            element: (
              <Protected isPage permission='canViewDocuments'>
                <Dashboard />
              </Protected>
            ),
            children: appRoutes
          },
          {
            path: 'app/:appId/page/:pageId/setting',
            element: (
              <Protected isPage permission='canManage'>
                <Settings />
              </Protected>
            ),
            children: appRoutes
          }
        ].filter(Boolean)
      },
      {
        path: 'run/:publishUrlPath',
        element: (
          <Protected permission='canSubmitDocuments'>
            <RunRedirect />
          </Protected>
        )
      },
      {
        path: 'app/:appId/run',
        element: (
          <Protected permission='canSubmitDocuments'>
            <Run />
          </Protected>
        )
      },
      {
        path: 'app/:appId/page/:pageId/run',
        element: (
          <Protected permission='canSubmitDocuments'>
            <Run />
          </Protected>
        )
      },
      {
        path: 'list/:publishUrlPath',
        element: (
          <Protected permission='canViewDocuments'>
            <DocumentListRedirect />
          </Protected>
        )
      },
      {
        path: 'app/:publishUrlPath/document/:documentId',
        element: (
          <Protected permission='canViewDocuments'>
            <FormViewRedirect />
          </Protected>
        )
      },
      {
        path: 'app/:appId/document/:documentId/view',
        element: (
          <Protected permission='canViewDocuments'>
            <FormViewRedirect />
          </Protected>
        )
      },
      { path: 'app/:appId/document/:documentId/edit', element: <FormEdit /> },
      {
        path: 'app/:appId/document/:documentId/edit/:pageNum',
        element: <PageNumRedirect />
      },
      { path: 'app/:appId/action/:actionId', element: <Action /> },
      {
        path: 'app/:appId/action/:actionId/:pageNum',
        element: <PageNumRedirect />
      },
      { path: 'action/:actionId', element: <Action /> },
      { path: 'action/:actionId/:pageNum', element: <PageNumRedirect /> },
      { path: 'start/:publishUrlPath', element: <AnonymousFormRedirect /> },
      { path: 'app/:appId/start', element: <AnonymousForm /> },
      { path: 'app/:appId/start/:pageNum', element: <PageNumRedirect /> },
      { path: 'app/:appId/page/:pageId/start', element: <AnonymousForm /> },
      {
        path: 'app/:appId/page/:pageId/start/:pageNum',
        element: <PageNumRedirect />
      },
      { path: 'health', element: <Health /> },
      { path: '403', element: <Forbidden /> },
      { path: '*', element: <NotFound /> },
      {
        path: 'view-email/:documentId/:historyId',
        element: <ViewEmail />
      }
    ].filter(Boolean)
  }
]
