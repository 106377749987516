/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import {
  useEvent,
  useModuleCommunication
} from '../../../components/use-module-communication'
import { useTransitionRef } from '../../../components/use-transition-ref'
import { ModalCentered, ModalFull } from './modals'

function buildUrl (url = '', documentId, actionId, serialNumber, slug = '') {
  const host = window.location.host.replace(/:\d*$/, '')
  const subdomain = host.split('.').slice(0, -2).join('.')
  const theme = document.documentElement.classList.contains('dark')
    ? 'dark'
    : 'light'
  return url
    .replace(/\{\{HOST\}\}/g, host)
    .replace(/\{\{SUBDOMAIN\}\}/g, subdomain)
    .replace(/\{\{ID\}\}/g, documentId || '')
    .replace(/\{\{ACTION_ID\}\}/g, actionId || '')
    .replace(/\{\{SLUG\}\}/g, slug)
    .replace(/\{\{SERIAL_NUMBER\}\}/g, serialNumber)
    .replace(/\{\{THEME\}\}/g, theme)
}

export default function IframeEdit (props) {
  const [height, setHeight] = React.useState(0)
  const [modalHeight, setModalHeight] = React.useState(0)
  const [modalDetails, setModalDetails] = React.useState()
  const ref = useTransitionRef(modalDetails?.type)

  const { documentId, actionId } = props.context
  const serial = props.context.documentMeta.serialNumber
  const url = buildUrl(props.details.url, documentId, actionId, serial)
  const modalUrl = buildUrl(
    props.details.modalUrl,
    documentId,
    actionId,
    serial,
    modalDetails?.id
  )

  const [gadgetRef, sendMessage] = useModuleCommunication((event, data) => {
    if (event === 'height') setHeight(data)
    if (event === 'navigate-home') setModalDetails(null)
    if (event === 'navigate-full') setModalDetails({ type: 'full', id: data })
    if (event === 'navigate-center') {
      setModalHeight(null)
      setModalDetails({ type: 'center', id: data })
    }
  })

  const [modalRef, sendMessage2] = useModuleCommunication((event, data) => {
    if (event === 'height') setModalHeight(data)
    if (event === 'navigate-home') setModalDetails(null)
    if (event === 'navigate-full') setModalDetails({ type: 'full', id: data })
    if (event === 'navigate-center') {
      setModalHeight(null)
      setModalDetails({ type: 'center', id: data })
    }
  })

  useEvent(window, 'theme-toggled', e => {
    sendMessage('theme', e.detail)
    sendMessage2('theme', e.detail)
  })

  return (
    <>
      <iframe
        ref={gadgetRef}
        className='block w-full'
        style={{ height }}
        id={props.id}
        src={url}
      />
      <TransitionGroup component={null}>
        <CSSTransition key={modalDetails?.type} timeout={450} nodeRef={ref}>
          {modalDetails?.type === 'full' ? (
            <ModalFull onClose={() => setModalDetails(null)} ref={ref}>
              <iframe
                ref={(...args) => modalRef(...args)}
                className='block h-full w-full'
                id={props.id + '-fullmodal'}
                src={modalUrl}
              />
            </ModalFull>
          ) : modalDetails?.type === 'center' ? (
            <ModalCentered onClose={() => setModalDetails(null)} ref={ref}>
              <iframe
                ref={(...args) => modalRef(...args)}
                className='kp-fade-up relative top-0 block h-0 max-h-screen w-1/2'
                style={{ height: modalHeight }}
                id={props.id + '-centermodal'}
                src={modalUrl}
              />
            </ModalCentered>
          ) : (
            <span />
          )}
        </CSSTransition>
      </TransitionGroup>
    </>
  )
}
