/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router'

import { ModalPage } from '../../../components/modal-page'
import {
  AffiliationManager,
  GroupManager,
  RoleManager,
  UserManager
} from './member-manager'
import { useRemovePolicyGroupMutation } from './mutation.remove-policy-group'

export function PolicyGroupView () {
  const { policyGroups } = useOutletContext()
  const { policyGroupId } = useParams()
  const navigate = useNavigate()
  const policyGroup = policyGroups.find(pg => pg.id === policyGroupId)
  const index = policyGroups.findIndex(pg => pg.id === policyGroupId)
  const deleteRole = useRemovePolicyGroupMutation(policyGroup.id)
  const kualiMade =
    [
      'Administrators',
      'All Authenticated Users',
      'All Anonymous Users'
    ].includes(policyGroup.name) && index < 3

  return (
    <ModalPage darkTop side title={policyGroup?.name} width='375px'>
      <div className='flex h-full flex-col p-2 text-sm'>
        <div className='m-2 whitespace-pre-wrap break-words'>
          {kualiMade ? (
            policyGroup.name === 'All Authenticated Users' ? (
              <Trans id='pagesbuilder.permissions.all.authenticated' />
            ) : (
              policyGroup.name === 'All Anonymous Users' && (
                <Trans id='pagesbuilder.permissions.all.anonymous' />
              )
            )
          ) : (
            policyGroup.description
          )}
        </div>
        {!(
          kualiMade &&
          ['All Anonymous Users', 'All Authenticated Users'].includes(
            policyGroup.name
          )
        ) && (
          <div className='m-2 flex min-w-[350px] flex-1 flex-col gap-3'>
            <UserManager
              label={i18n._('pagesbuilder.permissions.users')}
              id={`${policyGroup.id}-users`}
              group={policyGroup}
              leaveLast={policyGroup.name === 'Administrators'}
            />
            <GroupManager
              label={i18n._('pagesbuilder.permissions.groups')}
              id={`${policyGroup.id}-groups`}
              group={policyGroup}
            />
            <RoleManager
              label={i18n._('pagesbuilder.permissions.roles')}
              id={`${policyGroup.id}-roles`}
              group={policyGroup}
            />
            <AffiliationManager
              label={i18n._('pagesbuilder.permissions.affiliations')}
              id={`${policyGroup.id}-affiliations`}
              group={policyGroup}
            />
          </div>
        )}
        {!kualiMade && (
          <button
            className='kp-button-danger'
            onClick={() => {
              const msg = i18n._('pagesbuilder.permissions.confirm.delete')
              if (window.confirm(msg)) {
                deleteRole()
                navigate('..')
              }
            }}
          >
            <Trans id='delete' />
          </button>
        )}
      </div>
    </ModalPage>
  )
}
