/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { get, isEmpty } from 'lodash'
import React from 'react'

import * as Icons from '../../icons'
import {
  SimulateButtons,
  SimulateHeader,
  SimulateWrapper
} from '../components/simulator-parts'
import SimulationContext from '../components/simulator-parts/context'
import SimulationError from '../components/simulator-parts/error-page'
import SimulationFormEditor from '../components/simulator-parts/form-editor'
import SendbackEmail from '../components/simulator-parts/sb-email'

const Node = ({ details }) => (
  <div className='relative px-3 py-2'>{details.formName}</div>
)

const Simulate = ({
  appName,
  branding,
  currentUser,
  form,
  imageCache,
  simulateAction,
  simulationState,
  updateImageCache,
  value = {}
}) => {
  const { isSendback } = value
  const {
    doc,
    handleChange,
    metaFields,
    template,
    updateDoc,
    usedFields,
    validations
  } = React.useContext(SimulationContext)
  const submitter = get(
    doc,
    'meta.submittedBy',
    get(doc, 'meta.createdBy', get(value, 'submitted'))
  )
  const [selectedUser, setSelectedUser] = React.useState({
    ...submitter,
    user: submitter
  })
  const changeSubmitter = newSubmitter => {
    setSelectedUser(newSubmitter)
    updateDoc(draft => {
      draft.meta.createdBy = newSubmitter.user
      draft.meta.submittedBy = newSubmitter.user
    })
  }

  if (value.status === 'Notified' && value.isSendback) {
    if (isEmpty(submitter)) {
      return (
        <SimulationError
          form={form}
          simulateAction={simulateAction}
          simulationState={simulationState}
          value={{ errorMsg: <Trans id='unable.send.back.anonymous' /> }}
        />
      )
    }
    return (
      <SimulateWrapper
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <SimulateHeader
          onCurrentUserChange={() => {}}
          selectedUser={selectedUser}
        />
        <SendbackEmail
          appName={appName}
          branding={branding}
          currentUser={selectedUser}
          customNotification={value.customNotification}
          imageCache={imageCache}
          onClick={() => simulateAction('next', simulationState)}
          simulationState={simulationState}
        />
      </SimulateWrapper>
    )
  }

  const buttons =
    value.status === 'In Progress' ? (
      <SimulateButtons>
        <div>
          {simulationState.states.length > 1 && (
            <button
              className='kp-button-outline'
              disabled={simulationState.processing}
              id='back-btn'
              onClick={() => simulateAction('back', simulationState)}
            >
              <Trans id='back' />
            </button>
          )}
        </div>
        <div className='flex'>
          <button
            className='kp-button-outline mr-2'
            disabled={simulationState.processing}
            id='default-btn'
            onClick={() => simulateAction('discard', simulationState)}
          >
            <Trans id='discard' />
          </button>
          <button
            className='kp-button-solid'
            id='submit-btn'
            disabled={!isEmpty(validations) || simulationState.processing}
            onClick={() => {
              simulationState.meta.document = {
                ...doc,
                meta: {
                  ...doc.meta,
                  submittedBy: selectedUser.user,
                  link: '/',
                  submittedAt: new Date()
                }
              }
              return simulateAction('submit', simulationState)
            }}
          >
            <Trans id='submit' />
          </button>
        </div>
      </SimulateButtons>
    ) : null
  return (
    <SimulateWrapper
      buttons={buttons}
      simulateAction={simulateAction}
      simulationState={simulationState}
    >
      <SimulateHeader
        currentUser={currentUser}
        mode={isSendback ? 'n/a' : 'submitter'}
        onCurrentUserChange={changeSubmitter}
        selectedUser={selectedUser}
        value={value}
      >
        <div className='ml-4 flex min-h-[32px] items-center bg-yellow-50 px-4 py-1 text-black'>
          <Trans id='only.highlighted.fields.affect.test' />
        </div>
      </SimulateHeader>
      <SimulationFormEditor
        currentUser={currentUser}
        doc={doc}
        handleChange={handleChange}
        hideOfficeUse
        imageCache={imageCache}
        metaFields={metaFields}
        template={template}
        labelSize={form.labelSize}
        updateImageCache={updateImageCache}
        usedFields={usedFields}
        validations={validations}
      />
    </SimulateWrapper>
  )
}

export default {
  name: 'Form Submission',
  hidden: true,
  Icon: Icons.Clipboard,
  color: '#558dd8',
  defaultTemplate: () => ({}),
  Node,
  Simulate
}
