/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { produce } from 'immer'

export const useUpdateFormMutation = q => {
  const [updateForm, mutationResult] = useMutation(mutation)
  return [
    (appId, pageId, template) =>
      updateForm(getParams(q, appId, pageId, template)),
    mutationResult
  ]
}

const mutation = gql`
  mutation UpdateForm($appId: ID!, $pageId: ID, $template: JSON) {
    updateForm(args: { appId: $appId, pageId: $pageId, template: $template }) {
      id
    }
  }
`

const getParams = (query, appId, pageId, template) => ({
  variables: {
    appId,
    pageId,
    template
  },
  update: (store, { data: { updateForm } }) => {
    if (!query) return
    const oldData = store.readQuery({ ...query })
    const data = produce(oldData, draft => {
      if (draft.app.dataset) {
        draft.app.hasDraft = true
        draft.app.dataset.form.id = updateForm.id
        draft.app.dataset.form.template = template
      } else if (draft.app.settings) {
        draft.app.settings.template = template
      }
    })
    store.writeQuery({ ...query, data })
  }
})
