/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import cx from 'clsx'
import { get } from 'lodash'
import React from 'react'

import { Body2 } from '../../../ui/typography'

export default ({
  label,
  config,
  desc,
  displayDescInline,
  gadget,
  template,
  className,
  gridded
}) => {
  const displayLabel = !template.hideLabel
  const depth = get(template, 'sectionDepth', 1)
  const Heading = `h${Math.min(6, Math.max(1, depth) + 1)}`
  const isNested = Heading !== 'h2' || !template.root
  return (
    <div
      id={`gadget-${template.id}`}
      className={cx(
        'formbot-section-wrapper flex-1',
        {
          'break-inside-avoid': depth === 1,
          'border-b border-r border-light-gray-300 p-4': gridded && isNested
        },
        className
      )}
    >
      <div
        className={cx(
          'formbot-section relative flex flex-1 flex-col bg-white shadow-none print:h-auto print:min-h-[auto]',
          isNested ? 'm-0' : 'mx-0 my-3',
          {
            labelless: !displayLabel,
            'border-l border-t border-light-gray-300': displayLabel
          }
        )}
      >
        {!displayLabel && (config || desc) && (
          <header className='contents'>
            {config && (
              <Body2
                className='inline-block self-start bg-blue-500 px-2 py-1 uppercase text-white'
                color=''
              >
                {template.label || 'Section'}
              </Body2>
            )}
            {desc && <div className='!min-h-0 px-4 py-2'>{desc}</div>}
          </header>
        )}
        {displayLabel ? (
          <header
            className={cx(
              'm-0 flex border-b border-r border-light-gray-300 bg-light-gray-100 p-4 leading-[inherit] text-dark-gray-500 dark:bg-light-gray-300',
              {
                'flex-row items-center': displayDescInline,
                'flex-col items-start': !displayDescInline
              }
            )}
          >
            <Heading className='[&_>_span]:block'>{label}</Heading>
            {desc}
          </header>
        ) : null}
        {gadget}
      </div>
    </div>
  )
}
