/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { startOfDay, sub } from 'date-fns'
import { find, map } from 'lodash'
import React from 'react'

import { DatePicker } from '../../../components/temporal-pickers'
import { VisuallyHidden } from '../../../ui/a11y'
import { Option, Select } from '../../../ui/select'
import { Options } from '../../date-utils'
import { getDateObject } from './util'

const getDate = fn => +fn(startOfDay(new Date()))

const timeFilters = [
  { key: 'all.time', label: <Trans id='all.time' />, value: '' },
  { key: 'today', label: <Trans id='today' />, value: getDate(d => d) },
  {
    key: 'last.week',
    label: <Trans id='last.week' />,
    value: getDate(d => sub(d, { weeks: 1 }))
  },
  {
    key: 'last.month',
    label: <Trans id='last.month' />,
    value: getDate(d => sub(d, { months: 1 }))
  },
  {
    key: '3.months.ago',
    label: <Trans id='3.months.ago' />,
    value: getDate(d => sub(d, { months: 3 }))
  },
  {
    key: '6.months.ago',
    label: <Trans id='6.months.ago' />,
    value: getDate(d => sub(d, { months: 6 }))
  },
  { key: 'custom.range', label: <Trans id='custom.range' />, value: 'custom' }
]

const getSelected = ({ custom, min }) => {
  const actual = find(timeFilters, { value: min })
  if (actual && !custom) return '' + min
  if (!custom && !min) return ''
  return 'custom'
}

function DateInput ({ value, onChange }) {
  const handleChange = dateObject => onChange(dateObject.getTime())
  return <DatePicker onChange={handleChange} value={getDateObject(value)} />
}

export const UI = ({ gadget, onChange, value }) => {
  const selected = getSelected(value || {})
  return (
    <div>
      <Select
        value={selected}
        aria-label={i18n._('choose.date.range')}
        onChange={value => {
          if (!value) return onChange(null)
          const newFilter = { field: gadget.formKey, type: 'Date' }
          if (value === 'custom') newFilter.custom = true
          else newFilter.min = +value
          onChange(newFilter)
        }}
      >
        {map(timeFilters, time => (
          <Option key={time.key} value={time.value || ''}>
            {time.label}
          </Option>
        ))}
      </Select>
      {selected === 'custom' && (
        <>
          <div aria-hidden>
            <Trans id='from' />
          </div>
          <VisuallyHidden>
            <Trans id='select.start.date' />
          </VisuallyHidden>
          <DateInput
            value={value.min}
            onChange={date => onChange({ ...value, min: date })}
          />
          <div aria-hidden>
            {' '}
            <Trans id='to' />
          </div>
          <VisuallyHidden>
            <Trans id='select.end.date' />
          </VisuallyHidden>
          <DateInput
            value={value.max}
            onChange={date => onChange({ ...value, max: date })}
          />
        </>
      )}
    </div>
  )
}

const format = date =>
  getDateObject(+date).toLocaleDateString({
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })
const getPillValue = ({ custom, min, max }) => {
  const actual = find(timeFilters, { value: min })
  if (actual && !custom) return actual.label
  if (!custom && !min) return <Trans id='all.time' />
  if (min && max) return `${format(min)} - ${format(max)}`
  if (min) return `From ${format(min)}`
  if (max) return `To ${format(max)}`
  return <Trans id='custom.range' />
}

export const Pill = ({ label, filter, gadget }) => (
  <span>
    {label}: {getPillValue(filter || {})}
  </span>
)

const toString = val => (val ? '' + val : val)
export const toGraphQL = ({ field, min, max }) =>
  min || max
    ? [{ field, type: 'RANGE', min: toString(min), max: toString(max) }]
    : []

const toInt = val => (val ? +val : val)
export const fromGraphQL = ([gqlFilter]) => ({
  field: gqlFilter.field,
  type: 'Date',
  min: toInt(gqlFilter.min),
  max: toInt(gqlFilter.max)
})

export const newFromGraphQL = gqlFilters => {
  if (!(gqlFilters.min || gqlFilters.max)) return gqlFilters
  return {
    type: 'AND',
    operators: [
      gqlFilters.min && {
        field: gqlFilters.field,
        type: 'IS_ON_OR_AFTER',
        gadget: 'Date',
        value: gqlFilters.min
      },
      gqlFilters.max && {
        field: gqlFilters.field,
        type: 'IS_ON_OR_BEFORE',
        gadget: 'Date',
        value: gqlFilters.max
      }
    ].filter(Boolean)
  }
}

export const options = Options
