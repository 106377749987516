/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'

import { useCreatePolicyGroupMutation } from './mutation.create-policy-group'

export default function NewPolicyPopup ({ appId, onCancel }) {
  const createNewPolicyGroup = useCreatePolicyGroupMutation(appId)
  const [newPolicyName, setNewPolicyName] = React.useState('')
  const [description, setDescription] = React.useState('')

  return (
    <div className='w-96 p-4'>
      <h1 className='pb-4'>
        <Trans id='add.policy' message='Add Policy' />
      </h1>
      <label className='required-field block pb-2 text-sm'>
        <Trans id='policy.name' message='Policy Name' />
      </label>
      <input
        type='text'
        className='kp-input w-full'
        placeholder={i18n._({ id: 'policy.name', message: 'Policy Name' })}
        onChange={e => setNewPolicyName(e.target.value)}
        value={newPolicyName}
      />

      <label className='mt-4 block pb-2 text-sm'>
        <Trans id='description' message='Description' />
      </label>
      <textarea
        className='kp-textarea w-full'
        placeholder={i18n._({
          id: 'description.placeholder',
          message: 'Add a description...'
        })}
        onChange={e => setDescription(e.target.value)}
        value={description}
      />
      <div className='mt-3 flex justify-end'>
        <button className='kp-button-transparent mr-2' onClick={onCancel}>
          <Trans id='cancel' />
        </button>
        <button
          className='kp-button-solid'
          disabled={newPolicyName.length === 0}
          onClick={() => {
            createNewPolicyGroup(newPolicyName, description)
            setNewPolicyName('')
            setDescription('')
            onCancel()
          }}
        >
          <Trans id='save' message='Save' />
        </button>
      </div>
    </div>
  )
}
