/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'

export function useEvent (window, event, cb) {
  React.useEffect(() => {
    if (!window) return
    window.addEventListener(event, cb)
    return () => window.removeEventListener(event, cb)
  }, [window, cb])
}

export function useModuleCommunication (onMessage) {
  const [iframe, setIframe] = React.useState()
  const window = iframe?.contentWindow
  const sendMessage = React.useCallback(
    (event, data) => {
      window.dispatchEvent(
        new CustomEvent('platform-msg', { detail: { event, data } })
      )
    },
    [window]
  )
  useEvent(window, 'module-msg', event =>
    onMessage(event.detail.event, event.detail.data)
  )
  return [setIframe, sendMessage]
}
