/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import styled from 'styled-components'

import Button from '../../../ui/button'
import SimulatedEmail from './simulated-email'
import { addImagesToBody } from './utils'

export default function SendbackEmail ({
  appName,
  branding,
  currentUser,
  customNotification,
  imageCache,
  onClick,
  simulationState
}) {
  const customBody = customNotification?.body
    ? addImagesToBody(customNotification.body, imageCache)
    : null
  const body = (
    <div className='my-10'>
      <p className='mb-3'>
        <Trans
          id='hello.displayname'
          values={{
            name: currentUser?.firstName || currentUser?.displayName
          }}
        />
        ,
      </p>
      <p className='mb-3'>
        <Trans id='item.sent.back.in.following' />
      </p>
      <p className='mb-3'>
        <b>{appName}</b>
      </p>
      {customBody && (
        <div
          className='prose prose-sm prose-ol:list-inside'
          dangerouslySetInnerHTML={{ __html: customBody }}
        />
      )}
      <p className='mb-3'>
        <ActionButton
          brandColor={branding.color}
          disabled={simulationState.processing}
          id='review-btn'
          onClick={onClick}
          mt={3}
        >
          <Trans id='view.item' />
        </ActionButton>
      </p>
    </div>
  )
  return (
    <SimulatedEmail
      branding={branding}
      toEmail={currentUser?.email}
      subject={customNotification?.subject || i18n._('item.sent.back.to.you')}
      body={body}
    />
  )
}

const ActionButton = styled(Button)`
  width: 114px;
  height: 42px;
`
