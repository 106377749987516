/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { isString, unescape } from 'lodash'
import React from 'react'

import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import { Attachment } from '../../../icons'

export default function SimulatedEmail ({
  attachment,
  branding,
  hideFooter,
  toEmail,
  subject,
  body,
  exportOptions
}) {
  const from = useEmailFrom()
  return (
    <div className='h-full min-h-[340px] overflow-auto bg-white p-4'>
      <HeaderRow>
        <strong>
          <Trans id='from.colon' />
        </strong>
        {from}
      </HeaderRow>
      <HeaderRow>
        <strong>
          <Trans id='to.colon' />
        </strong>
        {toEmail}
      </HeaderRow>
      <HeaderRow>
        <strong>
          <Trans id='subject.colon' />
        </strong>
        {unescape(subject)}{' '}
        {exportOptions?.length > 0 && (
          <>
            <Tooltip className='w-[300px]' id='attachmentInfo' place='top'>
              <div>
                <Trans id='email.has.exported.document.attached' />
              </div>
            </Tooltip>
            <TooltipTrigger
              as={Attachment}
              label={i18n._('attachment')}
              tooltipId='attachmentInfo'
            />
          </>
        )}
        {attachment && (
          <a href={attachment}>
            <Attachment />
          </a>
        )}
      </HeaderRow>
      <div className='m-4'>
        {branding && (
          <img
            style={{ marginTop: '15px' }}
            alt={branding.alt}
            height={branding.height}
            width={branding.width}
            src={branding.emailLogo || branding.logo}
          />
        )}
        {isString(body) && (
          <div
            className='prose prose-sm my-10 max-w-full prose-ol:list-inside'
            dangerouslySetInnerHTML={{ __html: body }}
          />
        )}
        {!isString(body) && <div>{body}</div>}
        {!hideFooter && (
          <div className='text-medium-gray-500'>
            <p className='mb-3'>
              <Trans id='email.automatically.generated' />
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

const emailFromSettingsQuery = gql`
  query EmailSettingsQuery {
    tenant {
      id
      features {
        emailSettings {
          sendFrom
          enabled
        }
      }
    }
  }
`

function HeaderRow ({ children }) {
  return (
    <div className='flex gap-2 border-b border-light-gray-300 p-4 text-sm'>
      {children}
    </div>
  )
}

function useEmailFrom (defaultFrom = 'no-reply@mail.kualibuild.com') {
  const { data, loading } = useQuery(emailFromSettingsQuery)
  const { enabled, sendFrom } = data?.tenant?.features?.emailSettings ?? {}
  if (loading) return ''
  return enabled ? sendFrom : defaultFrom
}
