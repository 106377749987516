/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

import AnimatedOutlet from '../../components/animated-outlet'
import PopoverButton from '../../components/data-table/popover-button'
import { newPermissionsUI } from '../../components/feature-flags'
import Loading from '../../components/loading'
import { ModalPage } from '../../components/modal-page'
import { GraphQLError as Error } from '../../components/system-error'
import { useQuery } from '../../components/use-query'
import * as Icons from '../../icons'
import NewPermissionsInner from './components/new-permissions'
import NewPolicyGroup from './components/new-policy-group'
import NewPolicyPopup from './components/new-policy-popup'
import PolicyGroup from './components/policy-group'
import { QueryContextProvider } from './components/use-query-context'

export default function Permissions () {
  const navigate = useNavigate()
  const location = useLocation()
  const back = location?.state?.back
  const Component = newPermissionsUI ? NewPermissions : PermissionsInner

  return (
    <ModalPage
      onClose={() => (back ? navigate(back) : navigate('..'))}
      title={i18n._('permissions')}
    >
      <Component />
    </ModalPage>
  )
}

function PermissionsInner () {
  const { appId } = useParams()
  const query = getPermissionsPageQuery(appId)
  const { data, error, loading } = useQuery(query)
  if (loading) return <Loading />
  if (error) return <Error error={error} />
  return (
    <QueryContextProvider query={query}>
      <div className='p-8 text-sm'>
        <p className='pb-3'>
          <Trans id='pagesbuilder.permissions.configure.roles' />
        </p>
        <h2 className='mb-3 text-2xl'>
          <Trans id='pagesbuilder.permissions.roles' />
        </h2>
        {data.app.listPolicyGroups.map(policyGroup => (
          <PolicyGroup key={policyGroup.id} group={policyGroup} />
        ))}
        <NewPolicyGroup appId={appId} />
      </div>
    </QueryContextProvider>
  )
}

const getPermissionsPageQuery = appId => ({
  variables: { appId },
  query: gql`
    query PermissionsPage($appId: ID!) {
      viewer {
        id
        user {
          id
          displayName
          canManageSettings
        }
      }
      app(id: $appId) {
        id
        name
        hasDangerousPermissions
        acknowledgedDanger {
          dateDismissed
        }
        pages {
          id
          details
        }
        listPolicyGroups {
          id
          name
          description
          removable
          policies {
            id
            version
            statements {
              action
              resource
              effect
            }
          }
          identities {
            type
            id
            label
          }
        }
      }
    }
  `
})

function NewPermissions () {
  const { appId } = useParams()
  const query = getPermissionsPageQuery(appId)
  const { data, error, loading } = useQuery(query)
  if (loading) return <Loading />
  if (error) return <Error error={error} />
  return (
    <QueryContextProvider query={query}>
      <AnimatedOutlet context={{ policyGroups: data.app.listPolicyGroups }} />
      <div className='flex flex-col'>
        <div className='flex justify-end px-4 pt-4'>
          <PopoverButton
            hideArrow
            buttonProps={{ transparent: false }}
            label={
              <>
                <Icons.Add fill='var(--white)' mr={2} />
                <Trans id='add.policy' message='Add Policy' />
              </>
            }
            right={1}
          >
            {hide => (
              <div style={{ width: '100%' }}>
                <NewPolicyPopup onCancel={hide} appId={appId} />
              </div>
            )}
          </PopoverButton>
        </div>
        <NewPermissionsInner data={data} />
      </div>
    </QueryContextProvider>
  )
}
