/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import cx from 'clsx'
import React from 'react'
import styled from 'styled-components'

import Loading from '../loading'
import PagesNav from '../pages-nav'
import CompareToggle from './compare-toggle'
import HelpLink from './help-link'
import Wrapper from './page-wrapper'

export default function FormbotContainer ({
  animate,
  branding,
  loading,
  actionButtons,
  paginationButtons,
  formbot,
  pages,
  helpLink,
  compareToggle,
  header,
  hideSidebars,
  className,
  width
}) {
  if (loading) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    )
  }
  // For view and edit forms, as well as initial submit form and approvals
  return (
    <Wrapper className={cx(className, 'text-sm')}>
      <div className='flex flex-col text-sm xl:flex-row'>
        {/* Review/Status Toggle and Pages Navigation */}
        {!hideSidebars || width <= 1024 ? (
          <PagesContainer>
            {helpLink && <HelpLink helpLink={helpLink} />}
            {compareToggle && <CompareToggle {...compareToggle} />}
            {pages && (
              <PagesNav
                pages={pages}
                brandColor={branding?.color}
                paginationButtons={paginationButtons}
              />
            )}
          </PagesContainer>
        ) : (
          <PagesContainer />
        )}

        <div className={cx(className, 'flex w-full flex-col lg:flex-row')}>
          {/* Form */}
          <div
            className={`mx-10 grow ${
              animate
                ? hideSidebars
                  ? 'kp-dh-animate-move-left'
                  : 'kp-dh-animate-move-right'
                : hideSidebars
                  ? '-translate-x-[220px] transform'
                  : ''
            }`}
          >
            {header}
            <FormContent>{formbot}</FormContent>
          </div>

          <div className='p-4 lg:w-[220px] lg:pb-5 lg:pl-0 lg:pr-5 lg:pt-2 print:hidden'>
            {!hideSidebars && (
              <div className='sticky top-0'>
                {actionButtons && (
                  <ActionsHeading>
                    <Trans id='actions' />
                  </ActionsHeading>
                )}
                <div className='flex max-w-[300px] flex-col'>
                  {actionButtons}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const PagesContainer = styled.div.attrs({
  className:
    'print:hidden w-full pt-0 px-4 pb-6 empty:0 lg:w-[220px] md:max-w-[300px] lg:pt-2 lg:pr-0 lg:pb-5 lg:pl-5'
})``

const ActionsHeading = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #555555;
  padding-top: 9px;
  margin-bottom: 7px;
`

const FormContent = styled.div`
  & > :first-child {
    background: var(--white);
    padding: 12px 24px;
    min-height: 600px;
  }

  @media print {
    & > :first-child {
      box-shadow: none;
      padding: 0;
      min-height: auto;
      height: auto;
    }
  }
`
